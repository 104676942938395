import { TimeFromNow } from '../../../helper/timeFromNow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
//import { useTheme } from "@material-ui/core/styles";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DeliveredByComponent from './Fragments/DeliveredByComponent';
import LastUpdatedComponent from './Fragments/LastUpdatedComponent';
import RemarkComponent from './Fragments/RemarkComponent';
import VerifiedIconComponent from './Fragments/VerifiedIconComponent';
import BusinessFunctionOfferingComponent from './Fragments/BusinessFunctionOfferingComponent';
import { useTheme } from '@material-ui/core/styles';

function AppProviderItem(props) {
  const [company] = useState(props.company);
  const [meta] = useState(props.meta);
  const [businessFunction] = useState(props.businessFunction);
  const [clearname] = useState(props.clearname);
  const [area] = useState(props.area);
  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      minHeight: '100%'
    }
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Paper className={classes.paper}>
        <Box key="functionTitle">
          <Grid
            container
            alignItems="center"
            spacing={1}
            key="accessContainer1"
          >
            <Grid item key="accessIcon">
              <Typography
                align="left"
                component="span"
                variant="h6"
                key="functionArea"
              >
                {area} -
              </Typography>
            </Grid>
            <Grid item key="typo">
              <Typography
                align="left"
                component="span"
                variant="h6"
                key="functionName"
              >
                {clearname}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {meta.prohibitedFields.includes(businessFunction) === true ? (
          <Grid
            container
            alignItems="center"
            spacing={1}
            key="accessContainer1"
          >
            <Grid item key="accessIcon">
              <HighlightOffIcon color="secondary" />
            </Grid>
            <Grid item key="accessText">
              <Typography align="left" component="span" variant="body1">
                Sie haben keine Berechtigung, dieses Feld anzuzeigen.
              </Typography>
            </Grid>
          </Grid>
        ) : company.servicePortfolio !== undefined &&
          company.servicePortfolio !== null &&
          company.servicePortfolio !== [] &&
          company.servicePortfolio[businessFunction] !== undefined &&
          company.servicePortfolio[businessFunction] !== null &&
          company.servicePortfolio[businessFunction] !== [] &&
          company.servicePortfolio[businessFunction].liveValue !== null &&
          company.servicePortfolio[businessFunction].liveValue !== undefined ? (
          <Box
            margin={1}
            border={1}
            borderRadius={5}
            key={company.servicePortfolio[businessFunction]._id}
          >
            <Box p={1} key={'2343'}>
              <Grid container alignItems="center" spacing={1} key="1">
                <Grid item key="11">
                  <BusinessFunctionOfferingComponent
                    isOffered={
                      company.servicePortfolio[businessFunction].liveValue
                    }
                  />
                </Grid>
                <Grid item key="12">
                  <VerifiedIconComponent />
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={1} key="hiu2">
                <Grid item key="23">
                  <LastUpdatedComponent
                    lastUpdated={TimeFromNow(
                      company.servicePortfolio[businessFunction].metadata
                        .liveValueSetOn
                    )}
                  />
                </Grid>
                <Grid item key="25">
                  <DeliveredByComponent
                    by={
                      company.servicePortfolio[businessFunction].metadata
                        .liveValueDeliveredBy === 'indivio'
                        ? 'Community'
                        : 'Unternehmen'
                    }
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={1} key="30">
                <Grid item key="31">
                  <RemarkComponent
                    remark={company.servicePortfolio[businessFunction].remark}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box m={1} p={1} border={1} borderRadius={5} key="kk">
            <Grid
              container
              alignItems="center"
              spacing={1}
              key="noProviderData"
            >
              <Grid item key="icon">
                <HighlightOffIcon style={{ color: theme.palette.info.main }} />
              </Grid>
              <Grid item key="text">
                <Typography
                  align="left"
                  component="span"
                  variant="body1"
                  key="1"
                >
                  Für diese Business-Funktion liegen noch keine Informationen
                  vor.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </Grid>
  );
}

export default AppProviderItem;
