import GeneralDataEditComponent from '../GeneralEditFieldComponents/GeneralDataEditComponent';
import React from 'react';
export default function CustomerCountNonCHFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Anzahl Kunden ausserhalb der Schweiz"
      type="text"
      company={props.company}
      area="providerMetadata"
      field="customerCountNonCH"
      validationRegex={new RegExp(/^\d+$/)}
      validRangeErrorMessage="Bitte nur Ganzzahlen eingeben."
    />
  );
}
