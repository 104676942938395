import React, { useState, useEffect } from 'react';
import styles from './companyAdministration.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import LocationEditComponent from './EditComponents/LocationEditComponent';
import LanguageFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/LanguageFieldComponent';
import WebsiteUrlFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/WebsiteUrlFieldComponent';
import JurisdictionalFormFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/JurisdictionalFormFieldComponent';
import EmployeeCountFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/EmployeeCountFieldComponent';
import FteCountFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/FteCountFieldComponent';
import FoundationYearFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/FoundationYearFieldComponent';
import HeaderText from '../../../commonComponents/HeaderText';
import { Card } from '@material-ui/core';

function BankDeepdive(props) {
  const [loading, setLoading] = useState(true);
  const [company] = useState(props.company);
  const [companiesList, setCompaniesList] = useState([]);
  const [applicationsList, setApplicationsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;

    if (props.authenticated) {
      axios
        .all([
          axios.get(`${props.url}/api/company/list`),
          axios.get(`${props.url}/api/application/list`)
        ])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            if (!isUnloaded) {
              setCompaniesList(firstResponse.data.companies);
              setApplicationsList(secondResponse.data.applications);
              setLoading(false);
            }
          })
        );
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <div>
          <Box mt={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={3} mt={1}>
                  <HeaderText text={`${company.name} (Softwarehersteller)`} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid container spacing={3} key="2">
                  <Grid item xs={12}>
                    <Typography component="div" align="left" variant="h5">
                      Generelle Informationen
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <EmployeeCountFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FteCountFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FoundationYearFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <JurisdictionalFormFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <WebsiteUrlFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LanguageFieldComponent company={company} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid container spacing={2} key="3">
                  <Grid item xs={12}>
                    <Typography component="div" align="left" variant="h5">
                      Standorte
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <LocationEditComponent company={company} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDeepdive);
