import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import { Paper } from '@material-ui/core';
import ApprovalItemComponent from './ApprovalItemComponent';
import ApprovalItemComponentForServicePortfolioChange from './ApprovalItemComponentForServicePortfolioChange';
import LoadingContainer from '../../../commonComponents/LoadingContainer';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function ApprovalWidget(props) {
  const [loading, setLoading] = useState(false);
  const [companyname, setCompanyname] = useState('');
  const [approvalDatasets, setApprovalDatasets] = useState([]);
  let isUnloaded = false;

  const classes = useStyles();

  const updateApprovalDataset = () => {
    if (props.authenticated) {
      axios
        .get(`${props.url}/api/platformadmin/approvalDatasets`, {})
        .then(data => {
          if (isUnloaded === false) {
            setApprovalDatasets(data.data.datasets);
            setLoading(false);
          }
        })
        .catch(() => {
          props.notification({
            type: 'error',
            title: 'Fehler!',
            message: 'Fehler beim Abruf der Approval-Datasets.'
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    isUnloaded = false;
    updateApprovalDataset();
    return () => {
      isUnloaded = true;
    };
  }, []);

  return (
    <>
      <Paper>
        <Box m={2} className={classes.adminWidgetContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminWidgetHeading text="Freigabeprozess" />
            </Grid>
            <LoadingContainer loading={loading}>
              <Grid item container xs={12}>
                <Grid item xs={12} md={1}>
                  <Typography variant="h6">Unternehmen</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="h6">Art des Vorschlags</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="h6">Business-Funktion</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6">Vorgeschlagene Werte</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6">Weitere Felder</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="h6">Aktionen</Typography>
                </Grid>
              </Grid>
              {approvalDatasets.map((d, index) => {
                if (d.proposalType === 'providerPortfolio') {
                  return (
                    <ApprovalItemComponentForServicePortfolioChange
                      key={d.entryId}
                      apps={props.apps}
                      companies={props.companies}
                      entry={d}
                      refreshCallback={updateApprovalDataset}
                    />
                  );
                } else if (d.proposalType === 'provider') {
                  return (
                    <ApprovalItemComponent
                      key={d.entryId}
                      apps={props.apps}
                      companies={props.companies}
                      entry={d}
                      refreshCallback={updateApprovalDataset}
                    />
                  );
                } else if (d.proposalType === 'application') {
                  return (
                    <ApprovalItemComponent
                      key={d.entryId}
                      apps={props.apps}
                      companies={props.companies}
                      entry={d}
                      refreshCallback={updateApprovalDataset}
                    />
                  );
                } else {
                  return false;
                }
              })}
            </LoadingContainer>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalWidget);
