import { TimeFromNow } from '../../../../../../../helper/timeFromNow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@mui/material/Button';
import DeliveredByComponent from '../../../../../../pageComponents/Deepdive/Fragments/DeliveredByComponent';
import LastUpdatedComponent from '../../../../../../pageComponents/Deepdive/Fragments/LastUpdatedComponent';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@mui/material/FormLabel';

function ServicePortfolioEditComponent(props) {
  const [company, setCompany] = useState(props.company);
  const [businessFunction] = useState(props.businessFunction);
  const [area] = useState(props.area);
  const [clearname] = useState(props.clearname);
  const [liveValue, setLiveValue] = useState('');
  const [currentRemark, setCurrentRemark] = useState('');
  const [proposedRemark, setProposedRemark] = useState('');
  const [proposedValue, setProposedValue] = useState('');

  const setIt = () => {
    if (
      props.company.servicePortfolio[businessFunction] &&
      props.company.servicePortfolio[businessFunction].liveValue !== undefined
    ) {
      setLiveValue(company.servicePortfolio[businessFunction].liveValue);
    }

    if (
      props.company.servicePortfolio[businessFunction] &&
      props.company.servicePortfolio[businessFunction].proposedValue !==
        undefined
    )
      setProposedValue(
        company.servicePortfolio[businessFunction].proposedValue
      );
    if (
      props.company.servicePortfolio[businessFunction] &&
      props.company.servicePortfolio[businessFunction].remark !== undefined
    ) {
      setCurrentRemark(company.servicePortfolio[businessFunction].remark);
    }

    if (
      props.company.servicePortfolio[businessFunction] &&
      props.company.servicePortfolio[businessFunction].proposedRemark !==
        undefined
    )
      setProposedRemark(
        company.servicePortfolio[businessFunction].proposedRemark
      );
  };

  useEffect(() => {
    let isUnloaded = false;
    if (props.authenticated) {
      setIt();
    }
    return () => {
      isUnloaded = true;
    };
  }, []);

  useEffect(() => {
    setIt();
  }, [company.servicePortfolio]);

  const useStyles = makeStyles(theme => ({
    paper: {
      flexGrow: 1,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      textAlign: 'left'
    }
  }));

  const classes = useStyles();

  const handleChange = () => {
    setStateOfBusinessFunction();
  };

  const setStateOfBusinessFunction = () => {
    if (props.authenticated) {
      if (proposedValue === null || proposedValue === undefined) {
        props.notification({
          type: 'error',
          title: 'Kein Status ausgewählt.',
          message: 'Sie müssen entweder ja oder nein auswählen.'
        });
      } else {
        axios
          .put(`${props.url}/api/company/offeredBusinessFunctions`, {
            companyId: company._id,
            businessFunctionName: businessFunction,
            proposedState: proposedValue,
            proposedRemark: proposedRemark
          })
          .then(response => {
            if (response.data.company !== undefined) {
              setCompany(response.data.company);
              props.notification({
                type: 'success',
                title: 'Änderung im Review.',
                message:
                  'Die Abdeckung der Business Function befindet sich nun im Review und wird in Kürze freigegeben.'
              });
            }
          });
      }
    }
  };

  const exists =
    company.servicePortfolio !== undefined &&
    company.servicePortfolio !== null &&
    company.servicePortfolio !== [] &&
    company.servicePortfolio[businessFunction] !== undefined &&
    company.servicePortfolio[businessFunction] !== null &&
    company.servicePortfolio[businessFunction] !== []
      ? true
      : false;

  return (
    <Grid container item xs={12} md={12} lg={12}>
      <Box p={1} width={1}>
        <Paper className={classes.paper}>
          <Box p={1}>
            <Grid item xs={6} key="1">
              <Typography align="left" variant="h5" key="functionArea">
                {area} - {clearname}
              </Typography>
            </Grid>
          </Box>
          <Box p={1}>
            <Grid container item xs={12} md={6} lg={6}>
              <Grid item xs={12} md={12} key="23">
                <LastUpdatedComponent
                  lastUpdated={
                    exists === true
                      ? TimeFromNow(
                          company.servicePortfolio[businessFunction].metadata
                            .liveValueSetOn
                        )
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={12} md={12} key="24">
                <DeliveredByComponent
                  by={
                    exists === true
                      ? company.servicePortfolio[businessFunction].metadata
                          .liveValueDeliveredBy === 'indivio'
                        ? 'Community'
                        : 'Unternehmen'
                      : '-'
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Box p={1}>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={2} key="32">
                <Typography align="left" variant="body1" key="functionArea">
                  Aktueller Wert
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} key="33">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-labelledby="state"
                    name="state"
                    value={liveValue !== null ? liveValue : ''}
                    row
                  >
                    <FormControlLabel
                      disabled
                      value={true}
                      control={<Radio />}
                      label="im Angebot"
                    />
                    <FormControlLabel
                      disabled
                      value={false}
                      control={<Radio />}
                      label="nicht im Angebot"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} key="34">
                <TextField
                  fullWidth
                  disabled
                  label="Aktuelle Bemerkung"
                  type="text"
                  name="name"
                  value={currentRemark !== null ? currentRemark : ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
          <Box p={1}>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={2} key="32">
                <Typography align="left" variant="body1" key="functionArea">
                  Neuer Wert
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} key="36">
                <FormControl component="fieldset">
                  <RadioGroup
                    onChange={e => {
                      setProposedValue(e.target.value);
                    }}
                    aria-labelledby="newval"
                    name="state"
                    value={proposedValue !== null ? proposedValue : ''}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="im Angebot"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="nicht im Angebot"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} key="35">
                <TextField
                  fullWidth
                  label="Neue Bemerkung"
                  type="text"
                  name="name"
                  value={proposedRemark !== null ? proposedRemark : ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  onBlur={e => {}}
                  onChange={e => {
                    setProposedRemark(
                      e.target.value !== null ? e.target.value : ''
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box p={1}>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid item xs={12} md={12} key="37">
                <Button
                  onClick={() => {
                    handleChange();
                  }}
                  variant="outlined"
                >
                  Aktualisieren
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicePortfolioEditComponent);
