import React, { useEffect, useState } from 'react';
import styles from './indivioAdmin.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ListItem } from '@material-ui/core';
import CreateApplicationWidget from './application/CreateApplicationWidget';
import UpdateApplicationWidget from './application/UpdateApplicationWidget';
import AttachAppToBusinessFunctionWidget from './company/AttachAppToBusinessFunctionWidget';
import ApprovalWidget from './approval/ApprovalWidget';
import AttachUserToCompanyWidget from './company/AttachUserToCompanyWidget';
import CreateCompanyWiget from './company/CreateCompanyWidget';
import UpdateCompanyDataWidget from './company/UpdateCompanyDataWidget';
import DeleteUserWidget from './user/DeleteUserWidget';
import axios from 'axios';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { scroller } from 'react-scroll';
import HeaderText from '../../commonComponents/HeaderText';
import LoadingContainer from '../../commonComponents/LoadingContainer';
import CreateUserWidget from './user/CreateUserWidget';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '400px',
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  paper: {
    backgroundColor: '#606060',
    color: 'white'
  },
  icon: {
    fill: 'green'
  },
  root: {
    margin: '15px',
    textAlign: 'center',
    width: '100%'
  }
}));

function IndivioAdmin(props) {
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState('panel2');
  const [companiesList, setCompaniesList] = useState([]);
  const [applicationsList, setApplicationsList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const classes = useStyles();

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  let isUnloaded = false;

  useEffect(() => {
    setLoading(true);
    refetchBasicData();

    return () => {
      isUnloaded = true;
    };
  }, []);

  const refetchBasicData = () => {
    if (props.authenticated) {
      console.log('refetch triggered');
      axios
        .all([
          axios.get(`${props.url}/api/company/list`),
          axios.get(`${props.url}/api/application/list`),
          axios.get(`${props.url}/api/user/all`)
        ])
        .then(
          axios.spread((firstResponse, secondResponse, thirdResponse) => {
            if (!isUnloaded) {
              setLoading(false);
              const companiesSorted = firstResponse.data.companies.sort(
                function(a, b) {
                  if (a.name > b.name) {
                    return -1;
                  }
                  if (a.name < b.name) {
                    return 1;
                  }
                  return 0;
                }
              );

              const appsSorted = secondResponse.data.applications.sort(function(
                a,
                b
              ) {
                if (a.name > b.name) {
                  return -1;
                }
                if (a.name < b.name) {
                  return 1;
                }
                return 0;
              });

              const usersSorted = thirdResponse.data.users.sort(function(a, b) {
                if (a.name > b.name) {
                  return -1;
                }
                if (a.name < b.name) {
                  return 1;
                }
                return 0;
              });

              setCompaniesList(companiesSorted);
              setApplicationsList(appsSorted);
              setUsersList(usersSorted);
            }
          })
        );
    }
  };

  const show = no => {
    scroller.scrollTo('element-' + no, {
      duration: 400,
      delay: 0,
      smooth: true,
      offset: -60
    });
  };

  return (
    <div className={classes.root} id="cont">
      <Grid container>
        <LoadingContainer loading={loading}>
          <Grid item xs={9}>
            <Drawer
              anchor="right"
              variant="permanent"
              className={classes.drawer}
              classes={{ paper: classes.paper }}
            >
              <Box mt={8} />
              <List>
                <ListItem key="1">
                  <ListItemText primary="Unternehmen" />
                </ListItem>
                <ListItem button onClick={() => show(2)} key="2">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Unternehmen erstellen" />
                </ListItem>
                <ListItem button onClick={() => show(3)} key="3">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="App- und Provider-Beziehungen" />
                </ListItem>
                <ListItem button onClick={() => show(5)} key="4">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Nutzerzuordnungen und -rollen" />
                </ListItem>
                <ListItem button onClick={() => show(6)} key="5">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Basisdaten aktualisieren" />
                </ListItem>
                <Divider />
                <ListItem key="6">
                  <ListItemText primary="Applikationen" />
                </ListItem>
                <ListItem button onClick={() => show(7)} key="7">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Applikation erstellen" />
                </ListItem>
                <ListItem button onClick={() => show(8)} key="8">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Applikation aktualisieren" />
                </ListItem>
                <Divider />
                <ListItem key="9">
                  <ListItemText primary="User" />
                </ListItem>
                <ListItem button onClick={() => show(10)} key="10">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Nutzer erstellen" />
                </ListItem>
                <ListItem button onClick={() => show(11)} key="11">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Nutzer löschen" />
                </ListItem>
                <Divider />
                <ListItem key="12">
                  <ListItemText primary="Freigabe-Workflow" />
                </ListItem>
                <ListItem button onClick={() => show(13)} key="13">
                  <ListItemIcon>
                    <SettingsIcon style={{ fill: 'white' }}></SettingsIcon>
                  </ListItemIcon>
                  <ListItemText primary="Freigabeprozess" />
                </ListItem>
              </List>
            </Drawer>
          </Grid>
          <Grid item xs={9} id="cont">
            <Grid item container>
              <Grid item xs={12}>
                <HeaderText text="Unternehmen" />
                <div name="element-2" />
                <CreateCompanyWiget
                  refetchCallback={refetchBasicData}
                  key={1}
                />
                <div name="element-3" />
                <AttachAppToBusinessFunctionWidget
                  refetchCallback={refetchBasicData}
                  companies={companiesList}
                  apps={applicationsList}
                  users={usersList}
                  key={3}
                />
                <div name="element-5" />
                <AttachUserToCompanyWidget
                  refetchCallback={refetchBasicData}
                  companies={companiesList}
                  users={usersList}
                  key={5}
                />
                <div name="element-6" />
                <UpdateCompanyDataWidget
                  refetchCallback={refetchBasicData}
                  companies={companiesList}
                  apps={applicationsList}
                  key={6}
                />
              </Grid>
              <Grid item xs={12}>
                <HeaderText text="Applikationen" />
                <div name="element-7" />
                <CreateApplicationWidget
                  refetchCallback={refetchBasicData}
                  companies={companiesList}
                  key={7}
                />
                <div name="element-8" />
                <UpdateApplicationWidget
                  refetchCallback={refetchBasicData}
                  apps={applicationsList}
                  key={8}
                />
              </Grid>
              <Grid item xs={12}>
                <HeaderText text="Benutzer" />
                <div name="element-10" />
                <CreateUserWidget refetchCallback={refetchBasicData} key={10} />
                <div name="element-11" />
                <DeleteUserWidget
                  refetchCallback={refetchBasicData}
                  users={usersList}
                  key={11}
                />
              </Grid>
              <Grid item xs={12}>
                <HeaderText text="Freigabeprozesse" />
                <div name="element-13" />
                <ApprovalWidget
                  key={13}
                  refetchCallback={refetchBasicData}
                  companies={companiesList}
                  apps={applicationsList}
                  users={usersList}
                />
              </Grid>
            </Grid>
          </Grid>
        </LoadingContainer>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndivioAdmin);
