import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTheme } from '@material-ui/core/styles';

export default function AppFunctionOfferingComponent({ isOffered }) {
  const theme = useTheme();

  return isOffered === true ? (
    <Grid container alignItems="center" spacing={1} key="1">
      <Grid item key="accessIcon">
        <Tooltip title="Business-Funktion abgedeckt" key="tt1">
          <CheckBoxIcon style={{ color: theme.palette.success.main }} />
        </Tooltip>
      </Grid>
      <Grid item key="typo">
        <Typography align="left" component="span" variant="body1">
          Diese Funktion ist abgedeckt.
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid container alignItems="center" spacing={1} key="1">
      <Grid item key="accessIcon">
        <Tooltip title="Business-Funktion nicht abgedeckt" key="tt1">
          <NotInterestedIcon style={{ color: theme.palette.error.main }} />
        </Tooltip>
      </Grid>
      <Grid item key="typo">
        <Typography align="left" component="span" variant="body1">
          Diese Funktion ist nicht abgedeckt.
        </Typography>
      </Grid>
    </Grid>
  );
}
