import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { Typography, CircularProgress } from '@material-ui/core';
import styles from './home.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import UpdateIcon from '@material-ui/icons/Update';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    margin: 3
  },
  icon: {
    '& svg': {
      fontSize: 30
    }
  },
  notificationList: {
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  title: { marginTop: 15, marginLeft: 20 }
}));

function NotificationWidget(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (props.authenticated) {
      axios
        .get(`${props.url}/api/notification/many?maxLastCount=20&sinceTi`)
        .then(response => {
          if (isMounted === true) {
            setNotifications(response.data.notifications);
            setLoading(false);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  function formatDate(string) {
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(string).toLocaleDateString('de-DE', options);
  }

  function renderNotification(n) {
    let primary = '';
    let date = '';
    let text = '';
    let icon = null;
    let key = '';

    if (n.type === 'company_data_updated') {
      primary = n.company;
      icon = <BusinessIcon style={{ color: '#2ecc40' }} />;
    } else if (n.type === 'application_data_updated') {
      primary = n.app;
      icon = <UpdateIcon style={{ color: '#ff4136' }} />;
    } else if (n.type === 'company_added') {
      primary = n.company;
      icon = <DynamicFeedIcon style={{ color: '#f012be' }} />;
    } else if (n.type === 'application_added') {
      primary = n.app;
      icon = <LaptopMacIcon style={{ color: '#ffdc00' }} />;
    } else if (n.type === 'system_updated') {
      primary = 'Plattform-Update';
      icon = <SettingsSystemDaydreamIcon style={{ color: '#85144b' }} />;
    } else return null;

    date = formatDate(n.date);
    text = n.text;
    key = n.id; //test

    return (
      <ListItem key={key} alignItems="flex-start">
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography
              component="span"
              variant="h6"
              className={classes.inline}
              color="textPrimary"
            >
              {primary}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body1"
                className={classes.inline}
                color="textPrimary"
              >
                {date}
              </Typography>
              –{' '}
              <Typography
                component="span"
                variant="body1"
                className={classes.inline}
                color="textPrimary"
              >
                {text}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  }

  return (
    <div>
      {loading ? (
        <Typography variant="h5" component="span" gutterBottom>
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : (
        <Card className={classes.root}>
          <Typography
            className={classes.title}
            variant="h5"
            component=""
            gutterBottom
          >
            Was gibt es Neues?
          </Typography>
          <CardContent
            style={{
              maxHeight: '82vh',
              overflow: 'scroll'
            }}
          >
            <List className={classes.notificationList}>
              {notifications.map(notification =>
                renderNotification(notification)
              )}
            </List>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationWidget);
