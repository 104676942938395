import React, { useEffect } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import Home from './components/pageComponents/Home/Home';
import Login from './components/pageComponents/Login/Login';
import Logout from './components/modelComponents/user/Logout/Logout';
import Registration from './components/modelComponents/user/Registration/Registration';
import Setup from './components/modelComponents/user/RegistrationFinish/RegistrationFinish';
import ActivationHash from './components/modelComponents/user/ActivationHash/ActivationHash';
import Recovery from './components/modelComponents/user/Recovery/Recovery';
import RecoveryHash from './components/modelComponents/user/RecoveryHash/RecoveryHash';
import Error from './components/commonComponents/Error/Error';
import Map from './components/pageComponents/Map/Map';
import ChartExplorer from './components/pageComponents/ChartExplorer/ChartExplorer';
import Analytics from './components/pageComponents/Analytics/Analytics';
import CustomAnalytics from './components/pageComponents/CustomAnalytics/CustomAnalytics';
import PivotMaster from './components/pageComponents/PivotMaster/PivotMaster';
import TimeWarp from './components/pageComponents/TimeWarp/TimeWarp';
import NewsAggregator from './components/pageComponents/NewsAggregator/NewsAggregator';
import Deepdive from './components/pageComponents/Deepdive/Deepdive';
import CompanyAdministration from './components/modelComponents/company/CompanyAdministration/CompanyAdministration';
import QuarterlyReports from './components/pageComponents/QuarterlyReports/QuarterlyReports';
import MyCompanies from './components/pageComponents/MyCompanies/MyCompanies';
import AccountAdmin from './components/pageComponents/AccountAdmin/AccountAdmin';
import IndivioAdmin from './components/pageComponents/IndivioAdmin/IndivioAdmin';
import Profile from './components/modelComponents/user/Profile/Profile';
import { connect } from 'react-redux';
import {
  setUrl,
  setAuth,
  setUser,
  setNotifications,
  setPermissions
} from './redux/actions/connectionActions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import ProfileModify from './components/modelComponents/user/ProfileModify/ProfileModify';
import axios from 'axios';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PermissionedListItem from './components/pageComponents/Navigation/PermissionedListItem';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import AccountboxIcon from '@material-ui/icons/AccountBoxOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import MapIcon from '@material-ui/icons/MapOutlined';
import ExploreIcon from '@material-ui/icons/ExploreOutlined';
import PieIcon from '@material-ui/icons/PieChartOutlined';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import TablechartIcon from '@material-ui/icons/TableChartOutlined';
import FastrewindIcon from '@material-ui/icons/FastRewindOutlined';
import AnnouncementIcon from '@material-ui/icons/AnnouncementOutlined';
import DetailsIcon from '@material-ui/icons/DetailsOutlined';
import PageviewIcon from '@material-ui/icons/PageviewOutlined';
import SupervisoraccountIcon from '@material-ui/icons/SupervisorAccountOutlined';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Chatbot from './components/commonComponents/Chatbot/chatbot';
import { v4 as uuidv4 } from 'uuid';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    //necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1
    /* padding: theme.spacing(3) */
  },
  title: {
    flexGrow: 1
  },
  app: {
    marginTop: 0,
    height: '88vH'
  }
}));

toast.configure({
  autoClose: 5000,
  draggable: false
});

const PrivateRoute = ({
  authenticated,
  requiredPermission,
  userPermissions,
  children
}) => {
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  } else {
    if (
      userPermissions.includes(requiredPermission) ||
      requiredPermission === ''
    ) {
      return children;
    } else {
      return <Navigate to="/" replace />;
    }
  }
};

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export const App = ({
  setNotifications,
  setUrl,
  setAuth,
  setUser,
  setPermissions,
  authenticated,
  permissions,
  user
}) => {
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const theme = useTheme();

  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
  );

  const responseHandler = response => {
    return response;
  };

  const errorHandler = error => {
    if (error.response === undefined) return Promise.reject(error);
    if (error.response.status === 401) {
      setAuth(false);
      //window.location = '/login';
    } else {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    setNotifications(options => {
      let { type, title, message } = options;
      if (type === 'danger') {
        type = 'warning';
      }
      toast[type](
        <div>
          <b>{title}</b>
          <br />
          {message}
        </div>
      );
    });

    setUrl(
      `https://${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}`
    );

    setInterval(function() {
      const url = `https://${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}`;
      axios.post(`${url}/api/auth/check`).then(authenticated => {
        if (authenticated.data.success === false) setAuth(false);
      });
    }, 20000);
  }, []);

  useEffect(() => {
    if (user) {
      if (user.preference_sidemenu) {
        user.preference_sidemenu === 'closed' ? handleDrawerClose() : null;
        user.preference_sidemenu === 'open' ? handleDrawerOpen() : null;
      }
    }
  }, []);

  return (
    <>
      <div className={classes.root}>
        {authenticated ? (
          <>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, { [classes.hide]: open })}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component="span"
                  variant="h4"
                  noWrap
                  className={classes.title}
                >
                  Swiss Banking Map
                </Typography>
                <Typography variant="subtitle2">
                  eingeloggt als {user.firstName} {user.lastName}
                </Typography>
                <Button
                  color="inherit"
                  href={process.env.REACT_APP_LANDINGPAGE_URL}
                >
                  Home
                </Button>
                <Button color="inherit" component={Link} to={'/logout'}>
                  Logout
                </Button>
                <div className={classes.grow} />
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open
                })
              }}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>
              <List>
                <PermissionedListItem
                  isInDev={false}
                  availableFrom=""
                  needsAuth={false}
                  requiredPermission=""
                  to="/"
                  text="Home"
                  icon={<HomeIcon />}
                  onEnter={authenticated}
                ></PermissionedListItem>
                <Divider />
                <PermissionedListItem
                  isInDev={false}
                  availableFrom=""
                  needsAuth={true}
                  requiredPermission="permission-view-profile-page"
                  to="/profile"
                  text="Mein Profil"
                  icon={<AccountboxIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={false}
                  availableFrom=""
                  needsAuth={true}
                  requiredPermission="permission-view-mycompanies-page"
                  to="/my-companies"
                  text="Meine Unternehmen"
                  icon={<BusinessIcon />}
                ></PermissionedListItem>
                <Divider />
                <PermissionedListItem
                  isInDev={false}
                  availableFrom=""
                  needsAuth={true}
                  requiredPermission="permission-view-map-page"
                  to="/map"
                  text="Map"
                  icon={<MapIcon />}
                ></PermissionedListItem>
                <Divider />
                <PermissionedListItem
                  isInDev={false}
                  availableFrom=""
                  needsAuth={true}
                  requiredPermission="permission-view-chartexplorer-page"
                  to="/chart-explorer"
                  text="Chart Explorer"
                  icon={<ExploreIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={true}
                  availableFrom="Q3/22"
                  needsAuth={true}
                  requiredPermission="permission-view-pivotmaster-page"
                  to="/pivot-master"
                  text="Pivot Master"
                  icon={<TablechartIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={true}
                  availableFrom="Q3/22"
                  needsAuth={true}
                  requiredPermission="permission-view-analytics-page"
                  to="/analytics"
                  text="Analytics"
                  icon={<PieIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={true}
                  availableFrom="Q1/23"
                  needsAuth={true}
                  requiredPermission="permission-view-customanalytics-page"
                  to="/custom-analytics"
                  text="Custom Analytics"
                  icon={<AssessmentIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={true}
                  availableFrom="Q4/23"
                  needsAuth={true}
                  requiredPermission="permission-view-timewarp-page"
                  to="/time-warp"
                  text="Time Warp"
                  icon={<FastrewindIcon />}
                ></PermissionedListItem>
                <Divider />
                <PermissionedListItem
                  isInDev={true}
                  availableFrom="Q2/23"
                  needsAuth={true}
                  requiredPermission="permission-view-newsaggregator-page"
                  to="/news-aggregator"
                  text="News Aggregator"
                  icon={<AnnouncementIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={false}
                  availableFrom=""
                  needsAuth={true}
                  requiredPermission="permission-view-deepdive-page"
                  to="/deepdive"
                  text="Deepdive"
                  icon={<DetailsIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={true}
                  availableFrom="Q4/22"
                  needsAuth={true}
                  requiredPermission="permission-view-quarterlyreports-page"
                  to="/quarterly-reports"
                  text="Quarterly Reports"
                  icon={<PageviewIcon />}
                ></PermissionedListItem>
                <PermissionedListItem
                  isInDev={false}
                  availableFrom=""
                  needsAuth={true}
                  requiredPermission="permission-view-indivioadmin-page"
                  to="/indivio-admin"
                  text="indivio-Admin"
                  icon={<SupervisoraccountIcon />}
                ></PermissionedListItem>
              </List>
            </Drawer>
            <div style={{ position: 'relative', zIndex: '10000000000000' }}>
              <Chatbot></Chatbot>
            </div>
          </>
        ) : (
          <></>
          /*   <Routes>
            <Route path="/login" element={<Navigate to="/login" />} />
          </Routes> */
        )}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div id="back-to-top-anchor" />
          <div className={classes.app}>
            <Routes>
              <Route
                path="/landingpage"
                element={() => {
                  window.location.href = process.env.REACT_APP_LANDINGPAGE_URL;
                  return null;
                }}
              />
              <Route
                path={'/'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission=""
                  >
                    <Home />
                  </PrivateRoute>
                }
              />

              <Route
                path={'/dashboard'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission=""
                  >
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/login"
                element={
                  authenticated ? (
                    <Navigate
                      to={
                        user.preference_startpage
                          ? '/' + user.preference_startpage
                          : '/map'
                      }
                    />
                  ) : (
                    <Login />
                  )
                }
              />

              <Route path="/logout" element={<Logout />} />
              <Route path="/registration" element={Registration} />
              <Route path="/activation/:hash" element={ActivationHash} />
              <Route path="/registration/finish" element={Setup} />
              <Route path="/recovery" element={Recovery} />
              <Route path="/recovery/:hash" element={RecoveryHash} />
              {/* <Route path="/features" element={Features} /> */}
              <Route
                path={'/profile'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-profile-page"
                  >
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/profile/modify'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-modifyprofile-page"
                  >
                    <ProfileModify />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/map'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-map-page"
                  >
                    <Map />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/chart-explorer'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-chartexplorer-page"
                  >
                    <ChartExplorer />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/analytics'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-analytics-page"
                  >
                    <Analytics />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/custom-analytics'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-customanalytics-page"
                  >
                    <CustomAnalytics />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/pivot-master'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-pivotmaster-page"
                  >
                    <PivotMaster />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/time-warp'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-timewarp-page"
                  >
                    <TimeWarp />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/news-aggregator'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-newsaggregator-page"
                  >
                    <NewsAggregator />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/deepdive'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-deepdive-page"
                  >
                    <Deepdive />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/quarterly-reports'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-quarterlyreports-page"
                  >
                    <QuarterlyReports />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/my-companies'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-mycompanies-page"
                  >
                    <MyCompanies />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/account-admin'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-accountadmin-page"
                  >
                    <AccountAdmin />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/indivio-admin'}
                element={
                  <PrivateRoute
                    authenticated={authenticated}
                    userPermissions={permissions}
                    requiredPermission="permission-view-indivioadmin-page"
                  >
                    <IndivioAdmin />
                  </PrivateRoute>
                }
              />
              <Route path="/company/edit" element={<CompanyAdministration />} />
              <Route element={Error} />
            </Routes>
          </div>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    authenticated: state.authenticated,
    user: state.user,
    notification: state.notification,
    permissions: state.permissions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUrl: url => {
      dispatch(setUrl(url));
    },
    setAuth: authenticated => {
      dispatch(setAuth(authenticated));
    },
    setUser: user => {
      dispatch(setUser(user));
    },
    setNotifications: notifications => {
      dispatch(setNotifications(notifications));
    },
    setPermissions: permissions => {
      dispatch(setPermissions(permissions));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
