export function TimeFromNow(time) {
  // Get timestamps
  let unixTime = new Date(time).getTime();

  if (!unixTime) return;
  let now = new Date().getTime();

  // Calculate difference
  let difference = unixTime / 1000 - now / 1000;

  // Setup return object
  let tfn = {};

  // Check if time is in the past, present, or future
  tfn.when = 'jetzt';
  if (difference > 0) {
    tfn.when = 'in';
  } else if (difference < -1) {
    tfn.when = 'vor';
  }

  // Convert difference to absolute
  difference = Math.abs(difference);

  // Calculate time unit
  if (difference / (60 * 60 * 24 * 365) > 1) {
    // Years
    if (difference / (60 * 60 * 24 * 365) > 2) tfn.unitOfTime = 'Jahren';
    else tfn.unitOfTime = 'Jahr';
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 365));
  } else if (difference / (60 * 60 * 24 * 45) > 1) {
    // Months
    if (difference / (60 * 60 * 24 * 45) > 2) tfn.unitOfTime = 'Monaten';
    else tfn.unitOfTime = 'Monat';
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 45));
  } else if (difference / (60 * 60 * 24) > 1) {
    // Days
    if (difference / (60 * 60 * 24) > 2) tfn.unitOfTime = 'Tagen';
    else tfn.unitOfTime = 'Tag';
    tfn.time = Math.floor(difference / (60 * 60 * 24));
  } else if (difference / (60 * 60) > 1) {
    // Hours
    if (difference / (60 * 60) > 2) tfn.unitOfTime = 'Stunden';
    else tfn.unitOfTime = 'Stunde';
    tfn.time = Math.floor(difference / (60 * 60));
  } else {
    // Seconds
    tfn.unitOfTime = 'Sekunden';
    tfn.time = Math.floor(difference);
  }

  // Return time from now data
  if (tfn.when === 'jetzt') return tfn.when;
  else return tfn.when + ' ' + tfn.time + ' ' + tfn.unitOfTime;
}
