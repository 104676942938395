import GeneralDataEditComponent from './GeneralDataEditComponent';
import React from 'react';
export default function WebsiteUrlFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Website"
      type="text"
      company={props.company}
      area="generalMetadata"
      field="website"
      validationRegex={
        new RegExp(
          // eslint-disable-next-line no-useless-escape
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        )
      }
      validRangeErrorMessage="Die Website muss von der Form https://www.xy.ch sein."
    />
  );
}
