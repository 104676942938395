import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import LoadingContainer from '../../../commonComponents/LoadingContainer';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function CreateCompanyWidget(props) {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('provider');
  const [companyname, setCompanyname] = useState('');
  const [visible, setVisible] = useState(true);
  const [subsscription, setSubscription] = useState('subscription_basic');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;
    if (props.authenticated) {
      setLoading(false);
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;

    switch (field) {
      case 'companyname':
        setCompanyname(newValue);
        break;
      case 'companytype':
        setType(newValue);
        break;
      case 'subscriptionlevel':
        setSubscription(newValue);
        break;
      case 'visibleOnMap':
        setVisible(newValue);
        break;
      case 'street':
        setStreet(newValue);
        break;
      case 'streetnumber':
        setNumber(newValue);
        break;
      case 'zip':
        setZip(newValue);
        break;
      case 'city':
        setCity(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const clearFields = () => {
    setCompanyname('');
    setType('provider');
    setSubscription('subscription_basic');
    setVisible(true);
    setStreet('');
    setNumber('');
    setZip('');
    setCity('');
  };

  const createCompany = event => {
    axios
      .post(`${props.url}/api/company`, {
        companytype: type,
        name: companyname,
        visible: visible,
        subscriptionLevel: subsscription
      })
      .then(data => {
        clearFields();
        props.refetchCallback();

        props.notification({
          type: 'success',
          title: 'Erfolg!',
          message: `Das Unternehmen ${data.data.obj.name} wurde angelegt.`
        });

        const companyId = data.data.obj._id;
        axios
          .post(`${props.url}/api/company/locations`, {
            companyId: companyId,
            location: {
              description: null,
              zip: zip,
              country: 'CH',
              city: city,
              streetName: street,
              streetNumber: number,
              showOnMap: true
            },
            isHeadoffice: true
          })
          .then(data => {
            props.notification({
              type: 'success',
              title: 'Erfolg!',
              message: `Der Hauptsitz des Unternehmens wurde angelegt.`
            });
          })
          .catch(() => {
            props.notification({
              type: 'error',
              title: 'Fehler!',
              message:
                'Das Unternehmen wurde angelegt, aber der Hauptsitz konnte nicht angelegt werden.'
            });
          });
      })
      .catch(() => {
        props.notification({
          type: 'error',
          title: 'Fehler!',
          message: 'Das Unternehmen konnte nicht angelegt werden.'
        });
      });
  };

  return (
    <>
      <Paper>
        <Box m={2} className={classes.adminWidgetContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminWidgetHeading text="Neues Unternehmen anlegen" />
            </Grid>
            <LoadingContainer loading={loading}>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography align="left" variant="h5">
                    Basisdaten
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  error={
                    companyname.match(new RegExp(/^.*.{3,}$/)) ? false : true
                  }
                  label="Unternehmensname"
                  type="text"
                  name="companyname"
                  value={companyname}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  select
                  fullWidth
                  label="Unternehmenstyp"
                  type="text"
                  value={type}
                  name="companytype"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onBlur={handleChange}
                  onChange={e => {
                    handleChange(e);
                  }}
                >
                  <MenuItem key={'type1'} value={'bank'}>
                    Bank
                  </MenuItem>
                  <MenuItem key={'type2'} value={'provider'}>
                    Provider
                  </MenuItem>
                  <MenuItem key={'type3'} value={'softwarevendor'}>
                    Softwarehersteller
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  select
                  fullWidth
                  label="Sichtbar auf Map?"
                  type="text"
                  value={visible}
                  name="visibleOnMap"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onBlur={handleChange}
                  onChange={e => {
                    handleChange(e);
                  }}
                >
                  <MenuItem key={'visible1'} value={true}>
                    ja
                  </MenuItem>
                  <MenuItem key={'visible2'} value={false}>
                    nein
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  select
                  fullWidth
                  label="Subscription level"
                  type="text"
                  value={subsscription}
                  name="subscriptionlevel"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onBlur={handleChange}
                  onChange={e => {
                    handleChange(e);
                  }}
                >
                  <MenuItem key={'subscription1'} value={'subscription_basic'}>
                    Basic
                  </MenuItem>
                  <MenuItem
                    key={'subscription2'}
                    value={'subscription_analytic'}
                  >
                    Analytic
                  </MenuItem>
                  <MenuItem key={'subscription3'} value={'subscription_full'}>
                    Full
                  </MenuItem>
                </TextField>{' '}
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography align="left" variant="h5">
                    Adresse Hauptsitz
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  error={street.match(new RegExp(/^.*.{3,}$/)) ? false : true}
                  label="Strasse"
                  type="text"
                  name="street"
                  value={street}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  error={number.match(new RegExp(/^.*.{1,}$/)) ? false : true}
                  label="Hausnummer"
                  type="text"
                  name="streetnumber"
                  value={number}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  error={zip.match(new RegExp(/^[0-9]{4}$/)) ? false : true}
                  label="Postleitzahl"
                  type="text"
                  name="zip"
                  value={zip}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  error={city.match(new RegExp(/^.*.{3,}$/)) ? false : true}
                  label="Stadt"
                  type="text"
                  name="city"
                  value={city}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} align={'left'}>
                <Button variant="outlined" onClick={createCompany}>
                  Erstellen
                </Button>
              </Grid>
            </LoadingContainer>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCompanyWidget);

{
  /* <Select
                  native
                  value={
                    selectedCompany.id !== undefined ? selectedCompany.id : ''
                  }
                  onChange={handleCompanyChange}
                >
                  <option key={0} aria-label="None" value="" />;
                  {companiesList.map(c => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.name} (
                        {c.companytype.charAt(0).toUpperCase() +
                          c.companytype.slice(1)}
                        )
                      </option>
                    );
                  })}
                </Select> */
}
