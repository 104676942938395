import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import React from 'react';

export default function DeliveredByComponent({ by }) {
  return (
    <Box mr={1}>
      <Tooltip title="Letztes Update eingeliefert durch" key="tt2">
        <Grid container alignItems="center" spacing={1} key="290">
          <Grid item key="21">
            <SupervisedUserCircleIcon color="primary" />
          </Grid>
          <Grid item key="22">
            {by}
          </Grid>
        </Grid>
      </Tooltip>
    </Box>
  );
}
