import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import LoadingContainer from '../../../commonComponents/LoadingContainer';
import { Paper } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function DeleteUserWidget(props) {
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;
    if (props.authenticated) {
      setLoading(false);
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;

    if (props.authenticated) {
      setLoading(false);
    }
    return () => {
      isUnloaded = true;
    };
  }, [props.users]);

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;

    switch (field) {
      case 'user':
        setSelectedUser(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const clearFields = () => {
    setSelectedUser('');
  };

  const deleteUser = event => {
    axios
      .delete(`${props.url}/api/user`, {
        data: {
          id: selectedUser._id,
          email: selectedUser.email
        }
      })
      .then(data => {
        clearFields();
        props.refetchCallback();

        props.notification({
          type: 'success',
          title: 'Erfolg!',
          message: `Der Nutzer wurde entfernt.`
        });
      })
      .catch(e => {
        props.notification({
          type: 'error',
          title: 'Fehler!',
          message:
            'Der Nutzer ' +
            selectedUser.email +
            ' konnte nicht entfernt werden, Grund: ' +
            e.response.data.detail
        });
      });
  };

  return (
    <Paper>
      <Box m={2} className={classes.adminWidgetContainer}>
        <Grid container>
          <AdminWidgetHeading text="Nutzer löschen" />
        </Grid>
        <LoadingContainer loading={loading}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="us1">Nutzer</InputLabel>
                  <Select
                    labelId="us1"
                    name="user"
                    value={selectedUser}
                    id="3bdh"
                    onChange={handleChange}
                    label="Nutzer"
                  >
                    {props.users.map((c, i) => {
                      return (
                        <MenuItem key={c._id} value={c}>
                          {c.firstName +
                            ' ' +
                            c.lastName +
                            ' (' +
                            c.email +
                            ')'}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} align={'left'}>
              <Box m={2}>
                <Button variant="outlined" onClick={deleteUser}>
                  Ausführen
                </Button>
              </Box>
            </Grid>
          </Grid>
        </LoadingContainer>
      </Box>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserWidget);
