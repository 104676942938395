import React, { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

function Post(props) {
  useEffect(() => {
    axios
      .post(`${props.url}/api/communication/processBotMessage`, {
        all: props.steps,
        userid: props.user.id
      })
      .then(res => {
        console.log(res.status);
      })
      .catch(function(error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      Vielen Dank für Ihre Nachricht, {props.user.firstName}. Wir werden uns
      umgehend bei Ihnen unter {props.user.email} melden.
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
