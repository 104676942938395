import React, { useState, useEffect } from 'react';
import styles from './myCompanies.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';

function MyCompanies(props) {
  const [loading, setLoading] = useState(false);
  const [associatedCompanies, setAssociatedCompanies] = useState([]);
  const [user] = useState(props.user);

  let navigate = useNavigate();

  useEffect(() => {
    let isUnloaded = false;

    setLoading(true);
    axios.defaults.withCredentials = true;

    axios
      .get(`${props.url}/api/user/companies?userId=${user.id}`)
      .then(response => {
        if (isUnloaded === false) {
          setAssociatedCompanies(response.data.associatedCompanies);
          setLoading(false);
        }
      });

    return () => {
      // eslint-disable-next-line no-unused-vars
      isUnloaded = true;
    };
  }, []);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="left">{row.type}</TableCell>
          <TableCell align="left">{row.myrole}</TableCell>
          <TableCell align="left">
            <>
              {row.myrole === 'Admin' ? (
                <strong>
                  <IconButton
                    label="Daten aktualisieren"
                    onClick={() => {
                      navigate('/company/edit', {
                        state: {
                          companyId: row.id
                        }
                      });
                    }}
                  >
                    <EditIcon />{' '}
                  </IconButton>
                </strong>
              ) : (
                ''
              )}
            </>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="span">
                  Nutzer
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nutzer</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="left">Rolle</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.users.map(userRow => (
                      <TableRow key={userRow._id}>
                        <TableCell component="th" scope="row">
                          {userRow.firstName + ' ' + userRow.lastName}
                        </TableCell>
                        <TableCell>{userRow.email}</TableCell>
                        <TableCell align="left">
                          {roleForCompany(userRow, row.id)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function roleForCompany(user, companyId) {
    for (let c of user.associatedCompanies) {
      if (c.company === companyId) return c.isAdmin === true ? 'Admin' : 'User';
    }
    return false;
  }

  const rows = [];
  associatedCompanies.map(record => {
    rows.push({
      name: record.company.name,
      id: record.company._id,
      type:
        record.company.companytype.charAt(0).toUpperCase() +
        record.company.companytype.slice(1),
      myrole: roleForCompany(props.user, record.company._id),
      users: record.company.associatedUsers
    });
  });

  return (
    <div className={styles.tbd}>
      {loading ? (
        <Typography variant="h5" component="span" gutterBottom>
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : (
        <div style={{ height: '80vH', width: '100%' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Unternehmen</TableCell>
                  <TableCell align="left">Unternehmenstyp</TableCell>
                  <TableCell align="left">Meine Rolle</TableCell>
                  <TableCell align="left">Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCompanies);
