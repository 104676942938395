import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import Select from '@mui/material/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Paper } from '@material-ui/core';
import stringSimilarity from 'string-similarity';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function ApprovalItemComponent(props) {
  const [since, setSince] = useState('');
  const [remark, setRemark] = useState('');
  const [item, setItem] = useState('');
  const [comps, setComps] = useState([]);
  const [apps, setApps] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const type = props.entry.proposalType;
    let isUnloaded = false;
    if (props.authenticated) {
      if (type === 'provider') {
        const providers = props.companies.filter(c => {
          return c.companytype === 'provider';
        });

        const providersSorted = providers.sort(function(a, b) {
          if (
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              a.name
            ) >
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              b.name
            )
          ) {
            return -1;
          }
          if (
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              a.name
            ) <
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              b.name
            )
          ) {
            return 1;
          }
          return 0;
        });
        setComps(providersSorted);
        setItem(providersSorted[0].id);
      }
      if (type === 'application') {
        const appsSorted = props.apps.sort(function(a, b) {
          if (
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              a.name
            ) >
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              b.name
            )
          ) {
            return -1;
          }
          if (
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              a.name
            ) <
            stringSimilarity.compareTwoStrings(
              props.entry.proposedItemString,
              b.name
            )
          ) {
            return 1;
          }
          return 0;
        });
        setApps(appsSorted);
        setItem(appsSorted[0].id);
      }
    }
    return () => {
      isUnloaded = true;
    };
  }, [props.companies, props.apps]);

  useEffect(() => {
    let isUnloaded = false;
    if (props.authenticated) {
      setSince(props.entry.proposalForSince);
      setRemark(props.entry.proposalForRemark);
    }
    return () => {
      isUnloaded = true;
    };
  }, []);

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;
    switch (field) {
      case 'sel':
        setItem(newValue);
        break;
      case 'since':
        setSince(newValue);
        break;
      case 'remark':
        setRemark(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const acceptEntry = event => {
    const type = props.entry.proposalType;
    const sinceFinal = since;
    const remarkFinal = remark;
    const itemFinal = item;
    const entryId = props.entry.entryId;
    const companyId = props.entry.companyId;
    const businessFunctionName = props.entry.businessFunctionName;

    if (
      item !== '' &&
      item !== null &&
      item !== undefined &&
      (type === 'provider' || type === 'application')
    ) {
      let endpoint = '';
      let payload = {};
      if (type === 'provider') {
        endpoint = 'company/businessFunctions/sourcingProviders';
        payload = {
          entryId: entryId,
          bankId: companyId,
          sourcingProviderId: itemFinal,
          since: sinceFinal,
          remark: remarkFinal,
          businessFunctionName: businessFunctionName
        };
      } else if (type === 'application') {
        endpoint = 'company/businessFunctions/applications';
        payload = {
          entryId: entryId,
          bankId: companyId,
          applicationId: itemFinal,
          since: sinceFinal,
          remark: remarkFinal,
          businessFunctionName: businessFunctionName
        };
      }

      axios
        .post(`${props.url}/api/` + endpoint, payload)
        .then(data => {
          props.notification({
            type: 'success',
            title: 'Erfolg!',
            message: `Der Eintrag wurde verarbeitet.`
          });
          props.refreshCallback();
        })
        .catch(e => {
          props.notification({
            type: 'error',
            title: 'Fehler!',
            message: 'Der Eintrag konnte nicht verarbeitet werden (' + e + ').'
          });
        });
    } else {
      props.notification({
        type: 'error',
        title: 'Fehler!',
        message: 'Es muss eine Applikation/Provider ausgewählt sein.'
      });
    }
  };

  return (
    <>
      <Grid
        container
        key={props.entry.company + props.entry.proposedItemString}
      >
        <Grid item xs={12} md={1}>
          <Typography>{props.entry.company}</Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography>
            {props.entry.proposalType.charAt(0).toUpperCase() +
              props.entry.proposalType.slice(1)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography>{props.entry.businessFunctionName}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box m={1}>
            <TextField
              fullWidth
              disabled
              label="Vorgeschlagene App/Provider"
              type="text"
              name="companyname"
              value={props.entry.proposedItemString}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: false
              }}
              variant="outlined"
              onChange={handleChange}
            />
          </Box>
          <Box m={1}>
            <TextField
              fullWidth
              label="Vorschlag für Feld 'seit'"
              type="text"
              name="since"
              value={since}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: false
              }}
              variant="outlined"
              onChange={handleChange}
            />
          </Box>
          <Box m={1}>
            <TextField
              fullWidth
              disabled={false}
              multiline
              maxRows={2}
              name="remark"
              label="Vorschlag für Feld 'Bemerkung'"
              value={remark}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: false
              }}
              variant="outlined"
              onChange={handleChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box m={1}>
            {/* die macht den rand rechts */}
            <Select
              fullWidth
              name="sel"
              value={item}
              id="3bh"
              onChange={handleChange}
            >
              {props.entry.proposalType === 'provider'
                ? comps.map((c, i) => {
                    return (
                      <MenuItem key={i} value={c.id}>
                        {c.name +
                          ' (' +
                          c.companytype.charAt(0).toUpperCase() +
                          c.companytype.slice(1) +
                          ') (' +
                          Math.round(
                            stringSimilarity.compareTwoStrings(
                              props.entry.proposedItemString,
                              c.name
                            ) * 100
                          ) +
                          '%)'}
                      </MenuItem>
                    );
                  })
                : null}
              {props.entry.proposalType === 'application'
                ? apps.map((a, i) => {
                    return (
                      <MenuItem key={i} value={a.id}>
                        {a.name +
                          ' (App) (' +
                          Math.round(
                            stringSimilarity.compareTwoStrings(
                              props.entry.proposedItemString,
                              a.name
                            ) * 100
                          ) +
                          '%)'}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12} md={1}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={acceptEntry}
          >
            <CheckCircle style={{ color: 'grey' }} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalItemComponent);
