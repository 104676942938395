import React, { useEffect } from 'react';
import styles from './pivotMaster.module.scss';
import { connect } from 'react-redux';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
//import 'react-pivottable/pivottable.css';
import './style.css';

//import axios from 'axios'
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function PivotMaster(props) {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [tableState, setTableState] = React.useState({});

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    axios.defaults.withCredentials = true;
    axios.get(`${props.url}/api/chart/pivot`).then(res => {
      if (isMounted === true) {
        setData(res.data.data);
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={styles.tbd}>
      {loading ? (
        <Typography variant="h5" component="span" gutterBottom>
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : null}
      <PivotTableUI
        data={data}
        rows={['banktype']}
        cols={['app']}
        filters={[]}
        aggregatorName={'Sum as Fraction of Total'}
        hiddenAttributes={[]}
        hiddenFromAggregators={['app']}
        hiddenFromDragDrop={[]}
        vals={['banktype']}
        onChange={s => {
          console.log(s);
          setTableState(s);
        }}
        renderers={Object.assign({}, TableRenderers)}
        {...tableState}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PivotMaster);
