import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import Post from './Post';
import { useTheme } from '@material-ui/core/styles';

const config = {
  width: '400px',
  height: '700px',
  floating: true,
  botDelay: 1000,
  userDelay: 400,
  headerTitle: 'Swiss Banking Map - Support',
  placeholder: 'Schreiben Sie Ihre Nachricht hier...',
  recognitionLang: 'de'
};

function Chatbot(props) {
  const theme = useTheme();

  const styling = {
    background: '#f5f8fb',
    //fontFamily: "Helvetica Neue",
    headerBgColor: theme.palette.primary.main,
    headerFontColor: '#fff',
    headerFontSize: '16px',
    botBubbleColor: theme.palette.primary.main,
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a'
  };

  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  useEffect(() => {}, [props.user]);

  return props.user !== undefined && props.user !== null ? (
    <ThemeProvider theme={styling}>
      <ChatBot
        //recognitionEnable={true}
        steps={[
          {
            id: '0',
            message: `Guten Tag ${props.user.firstName} !`,
            end: false,
            trigger: '1'
          },
          {
            id: '1',
            message: 'Wie kann ich Ihnen helfen?',
            trigger: '2'
          },
          {
            id: '2',
            options: [
              { value: 'Frage', label: 'Ich habe eine Frage', trigger: '3' },
              {
                value: 'Lob/Kritik',
                label: 'Ich habe Lob oder Kritik',
                trigger: '3'
              },
              {
                value: 'Kontaktieren',
                label: 'Ich möchte kontaktiert werden',
                trigger: '3'
              },
              {
                value: 'Abos',
                label: 'Ich möchte mehr zu den Abos erfahren',
                trigger: '3'
              },
              {
                value: 'FeatureRequest',
                label: 'Ich habe einen Feature Request',
                trigger: '3'
              },
              {
                value: 'UnternehmensdatenOwn',
                label: 'Ich möchte meine Unternehmensdaten aktualisieren',
                trigger: '3'
              },
              {
                value: 'UnternehmensdatenForeign',
                label:
                  'Ich möchte ein Update für ein fremdes Unternehmen mitteilen.',
                trigger: '3'
              },
              {
                value: 'Upgrade',
                label: 'Ich möchte mein Abo upgraden',
                trigger: '3'
              },
              { value: '', label: 'Sonstiges', trigger: '3' }
            ]
          },
          {
            id: '3',
            message: 'Bitte teilen Sie uns Ihr Anliegen mit.',
            trigger: '4'
          },
          {
            id: '4',
            user: true,
            trigger: 'q-submit'
          },
          {
            id: 'q-submit',
            message: 'Möchten Sie Ihre Nachricht jetzt übermitteln?',
            trigger: 'submit'
          },
          {
            id: 'submit',
            options: [
              { value: 'y', label: 'Ja', trigger: 'send-message' },
              { value: 'n', label: 'Nein', trigger: 'no-submit' }
            ]
          },
          {
            id: 'no-submit',
            message: 'Ihre Nachricht wurde nicht übermittelt.',
            end: false,
            trigger: '1'
          },
          {
            id: 'send-message',
            component: <Post />,
            asMessage: true,
            end: false,
            trigger: '5'
          },
          {
            id: '5',
            message: 'Haben Sie noch ein Anliegen?',
            trigger: '6'
          },
          {
            id: '6',
            options: [
              { value: 'y', label: 'Ja', trigger: '1' },
              { value: 'n', label: 'Nein', trigger: '7' }
            ]
          },
          {
            id: '7',
            message: 'Bis zum nächsten Mal!',
            end: true
          }
        ]}
        {...config}
      />
    </ThemeProvider>
  ) : null;
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Chatbot);
