import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import Select from '@mui/material/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Paper } from '@material-ui/core';
import stringSimilarity from 'string-similarity';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function ApprovalItemComponentForServicePortfolioChange(props) {
  const [liveValue, setLiveValue] = useState('');
  const [remark, setRemark] = useState('');
  const [proposedValue, setProposedValue] = useState([]);
  const [proposedRemark, setProposedRemark] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    let isUnloaded = false;
    if (props.authenticated) {
      setProposedRemark(props.entry.proposedRemark);
      setRemark(props.entry.remark);
      setProposedValue(props.entry.proposedValue);
      setLiveValue(props.entry.liveValue);
    }
    return () => {
      isUnloaded = true;
    };
  }, []);

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;
    switch (field) {
      case 'sel':
        setItem(newValue);
        break;
      case 'proposedRemark':
        setProposedRemark(newValue);
        break;
      case 'proposedValue':
        setProposedValue(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const acceptEntry = event => {
    const companyId = props.entry.companyId;
    const businessFunctionName = props.entry.businessFunctionName;

    let endpoint = 'company/offeredBusinessFunctions';
    let payload = {
      companyId: companyId,
      proposedState: proposedValue,
      proposedRemark: proposedRemark,
      businessFunctionName: businessFunctionName,
      originallyDeliveredBy: 'company'
    };

    axios
      .put(`${props.url}/api/` + endpoint, payload)
      .then(data => {
        props.notification({
          type: 'success',
          title: 'Erfolg!',
          message: `Der Eintrag wurde verarbeitet.`
        });
        props.refreshCallback();
      })
      .catch(e => {
        props.notification({
          type: 'error',
          title: 'Fehler!',
          message: 'Der Eintrag konnte nicht verarbeitet werden (' + e + ').'
        });
      });
  };

  return (
    <>
      <Grid
        container
        key={props.entry.company + props.entry.proposedItemString}
      >
        <Grid item xs={12} md={1}>
          <Typography>{props.entry.company}</Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography>
            {props.entry.proposalType.charAt(0).toUpperCase() +
              props.entry.proposalType.slice(1)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography>{props.entry.businessFunctionName}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box m={1}>
            <TextField
              fullWidth
              disabled
              label="Aktueller Remark"
              type="text"
              name="remark"
              value={remark}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              onChange={handleChange}
            />
          </Box>
          <Box m={1}>
            <TextField
              fullWidth
              label="Vorgeschlagener Remark"
              type="text"
              name="proposedRemark"
              value={props.entry.proposedRemark}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: false
              }}
              variant="outlined"
              onChange={handleChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box m={1}>
            <FormControl component="fieldset">
              <RadioGroup
                onChange={e => {
                  setProposedValue(e.target.value);
                }}
                aria-label="state"
                name="state"
                value={proposedValue}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="im Angebot"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="nicht im Angebot"
                />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <RadioGroup aria-label="state" name="state" value={liveValue} row>
                <FormControlLabel
                  disabled
                  value={true}
                  control={<Radio />}
                  label="im Angebot"
                />
                <FormControlLabel
                  disabled
                  value={false}
                  control={<Radio />}
                  label="nicht im Angebot"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={1}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={acceptEntry}
          >
            <CheckCircle style={{ color: 'grey' }} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalItemComponentForServicePortfolioChange);
