import React from 'react';
import styles from './navigation.module.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

const PermissionedListItem = ({
  isInDev,
  availableFrom,
  authenticated,
  permissions,
  requiredPermission,
  needsAuth,
  text,
  to,
  icon,
  ...rest
}) =>
  isInDev ? (
    <Tooltip key="tt3" title={text}>
      <ListItem
        button
        component={Link}
        to={to}
        key={to}
        onClick={e => {
          e.preventDefault();
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          className={styles.profeature}
          primary={`${text} (${availableFrom})`}
        />
      </ListItem>
    </Tooltip>
  ) : authenticated ? (
    rest.onlyloggedout ? null : permissions.includes(requiredPermission) ||
      requiredPermission === '' ? (
      <Tooltip key="tt3" title={text}>
        <ListItem button component={Link} to={to} key={to}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText className={styles.black} primary={text} />
        </ListItem>
      </Tooltip>
    ) : requiredPermission === 'permission-view-indivioadmin-page' ? null : (
      <Tooltip key="tt3" title={text}>
        <ListItem
          button
          component={Link}
          to={to}
          key={to}
          onClick={e => {
            e.preventDefault();
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            className={styles.profeature}
            primary={`${text} (PRO)`}
          />
        </ListItem>
      </Tooltip>
    )
  ) : needsAuth ? null : (
    <Tooltip key="tt3" title={text}>
      <ListItem button component={Link} to={to} key={to}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText className={styles.black} primary={text} />
      </ListItem>
    </Tooltip>
  );

const mapStateToProps = state => {
  return {
    authenticated: state.authenticated,
    permissions: state.permissions
  };
};

export default connect(mapStateToProps)(PermissionedListItem);
