import React, { Component } from 'react';
import styles from './registration.module.scss';
import { connect } from 'react-redux';
import {
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      username: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      passwordAgain: null,
      loading: false
    };
  }

  clearFields = () => {
    this.setState({
      name: null,
      username: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      passwordAgain: null,
      loading: false
    });
  };

  register = async () => {
    const { username, name, email, password, firstName, lastName } = this.state;
    const userdata = {
      username,
      name,
      firstName,
      lastName,
      email,
      password
    };
    this.setState({
      loading: true
    });
    const response = await axios.put(
      `${this.props.url}/api/auth/registration`,
      userdata
    );
    this.setState({
      loading: false
    });
    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Erfolgreich!',
        message: 'Der Nutzer hat eine Email zur Aktivierung erhalten.!'
      });
      this.props.refetchCallback();
      this.clearFields();
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Fehler!',
        message: 'Die Emailadresse existiert bereits!'
      });
    }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  render = () => {
    const { loading } = this.state;
    return (
      <div className={styles.registration}>
        <Grid container item xs={12}>
          <ValidatorForm
            ref="form"
            onSubmit={this.register}
            className={styles.content}
          >
            <Grid item xs={12}>
              <TextValidator
                label="Username"
                onChange={this.handleChange}
                name="username"
                value={this.state.username || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                label="First name"
                onChange={this.handleChange}
                name="firstName"
                value={this.state.firstName || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                label="Last name"
                onChange={this.handleChange}
                name="lastName"
                value={this.state.lastName || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                label="Email"
                onChange={this.handleChange}
                name="email"
                value={this.state.email || ''}
                validators={[
                  'required',
                  'isEmail',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'email is not valid',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                label="Password"
                onChange={this.handleChange}
                name="password"
                type="password"
                value={this.state.password || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                label="Password again"
                onChange={this.handleChange}
                name="passwordAgain"
                type="password"
                value={this.state.passwordAgain || ''}
                validators={[
                  'required',
                  'isPasswordMatch',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'password mismatch',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <Button variant="contained" color="primary" fullWidth disabled>
                  <CircularProgress color="primary" size={24} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Nutzer anlegen
                </Button>
              )}
            </Grid>
          </ValidatorForm>
        </Grid>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification
  };
};

export default connect(mapStateToProps)(App);
