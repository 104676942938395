import React, { useState, useEffect } from 'react';
import styles from './companyAdministration.module.scss';
import { connect } from 'react-redux';
import { Typography, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import EditBankPage from './EditBankPage';
import EditProviderPage from './EditProviderPage';
import EditSoftwarevendorPage from './EditSoftwarevendorPage';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({}));

function CompanyAdministration(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({ companytype: null });
  //const [user] = useState(props.user)

  const { state } = useLocation() || {};
  const [companyId] = useState(state !== null ? state.companyId : undefined);

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;

    if (companyId !== undefined) {
      axios
        .get(`${props.url}/api/company?companyId=${companyId}`)
        .then(response => {
          if (isUnloaded === false) {
            setCompany(response.data.data.company);
            setLoading(false);
          }
        });
    } else {
      navigate('/my-companies');
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  return (
    <div className={styles.tbd}>
      {loading ? (
        <Typography variant="h5" gutterBottom component="span">
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : (
        <div style={{ height: 400, width: '100%' }}>
          <Box mt={3}>
            <Grid container spacing={2}>
              {company.companytype === 'bank' ? (
                <EditBankPage company={company} />
              ) : company.companytype === 'provider' ? (
                <EditProviderPage company={company} />
              ) : company.companytype === 'softwarevendor' ? (
                <EditSoftwarevendorPage company={company} />
              ) : null}
            </Grid>
          </Box>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => navigate('/my-companies')}
              >
                <span>Zurück zur Übersicht</span>
              </Button>
            </Grid>
          </Box>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyAdministration);
