import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import VerifiedIconComponent from './Fragments/VerifiedIconComponent';
import NoDataIconComponent from './Fragments/NoDataIconComponent';
import LastUpdatedComponent from './Fragments/LastUpdatedComponent';
import DeliveredByComponent from './Fragments/DeliveredByComponent';
import { TimeFromNow } from '../../../helper/timeFromNow';
import AppFunctionOfferingComponent from './Fragments/AppFunctionOfferingComponent';

export default function ApplicationFunctionItem(props) {
  const app = props.application;
  const area = props.area;
  const fct = props.function;
  const clearname = props.clearname;
  const [isSet, setIsSet] = useState(false);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      minHeight: '100%'
    }
  }));

  useEffect(() => {
    const val =
      app === undefined ||
      app.applicationFunctions === undefined ||
      app.applicationFunctions === null ||
      app.applicationFunctions[fct] === undefined ||
      app.applicationFunctions[fct] === null
        ? undefined
        : app.applicationFunctions[fct];
    setIsSet(val !== undefined);
  }, []);

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Paper className={classes.paper}>
        <Box key={1}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item key="accessIcon">
              <Typography
                align="left"
                variant="h6"
                component="span"
                key="functionArea"
              >
                {area} -
              </Typography>
            </Grid>
            <Grid item key="typo">
              <Typography
                align="left"
                variant="h6"
                component="span"
                key="functionName"
              >
                {clearname}
              </Typography>
            </Grid>
            {isSet === true ? (
              <Grid>
                <VerifiedIconComponent verified={isSet} />
              </Grid>
            ) : null}
          </Grid>
        </Box>

        <Box key={234} margin={1}>
          <Grid container alignItems="center" spacing={1}>
            {isSet === false ? (
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item key="1">
                    <NoDataIconComponent />
                  </Grid>
                  <Grid item key="2">
                    <Typography align="left" component="span" variant="body1">
                      Es liegen noch keine Daten vor.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <AppFunctionOfferingComponent
                isOffered={app.applicationFunctions[fct].covered}
              />
            )}
          </Grid>
        </Box>
        <Box key={87087}>
          {isSet === true ? (
            <Grid container alignItems="center" spacing={1} key="hiu2">
              <Grid item key="23">
                <LastUpdatedComponent
                  lastUpdated={TimeFromNow(
                    app.applicationFunctions[fct].metadata.liveValueSetOn
                  )}
                />
              </Grid>
              <Grid item key="25">
                <DeliveredByComponent
                  by={
                    app.applicationFunctions[fct].metadata
                      .liveValueDeliveredBy === 'indivio'
                      ? 'Community'
                      : 'Unternehmen'
                  }
                />
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Paper>
    </Grid>
  );
}
