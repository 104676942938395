import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';

export default function NoDataIconComponent() {
  const theme = useTheme();

  return (
    <Tooltip key="tt3" title="Noch keine Daten vorhanden">
      <HighlightOffIcon style={{ color: theme.palette.info.main }} />
    </Tooltip>
  );
}
