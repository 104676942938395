import GeneralDataEditComponent from '../GeneralEditFieldComponents/GeneralDataEditComponent';
import React from 'react';
export default function BankinglicenseFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Banklizenz"
      type="bool"
      company={props.company}
      area="bankMetadata"
      field="bankingLicense"
    />
  );
}
