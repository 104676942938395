import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  },
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

function AddLocationPopoverDialogue(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [company] = useState(props.company);
  const [description, setDescription] = useState('');
  const [zip, setZip] = useState('');
  const [country] = useState('CH');
  const [city, setCity] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [isHeadoffice, setIsHeadoffice] = useState(true);

  const [isErrorZip, setIsErrorZip] = React.useState(false);
  const [zipErrorText] = useState(
    'Bitte geben Sie eine vierstellige Postleitzahl ein.'
  );

  const [isErrorDescription, setIsErrorDescription] = React.useState(false);
  const [errorTextDescription] = useState(
    'Bitte geben Sie eine optionale Beschreibung ein.'
  );

  const [errorTextCountry] = useState(
    'Muss CH sein, keine Standorte im Ausland zulässig.'
  );

  const [isErrorCity, setIsErrorCity] = React.useState(false);
  const [errorTextCity] = useState('Bitte geben Sie die Stadt ein.');

  const [isErrorStreetName, setIsErrorStreetName] = React.useState(false);
  const [errorTextStreetName] = useState(
    'Bitte geben Sie den Strassennamen ein.'
  );

  const [isErrorStreetNumber, setIsErrorStreetNumber] = React.useState(false);
  const [errorTextStreetNumber] = useState(
    'Bitte geben Sie die Hausnummer ein.'
  );

  const handleLocationHeadofficeChange = event => {
    setIsHeadoffice(event.target.checked);
  };

  const addLocation = event => {
    if (props.authenticated) {
      axios
        .post(`${props.url}/api/company/locations`, {
          companyId: company._id,
          location: {
            description: description,
            zip: zip,
            country: country,
            city: city,
            streetName: streetName,
            streetNumber: streetNumber
          },
          isHeadoffice: isHeadoffice
        })
        .then(response => {
          if (response.data.company !== undefined) {
            props.callback(response.data.company);
            props.notification({
              type: 'success',
              title: 'Standort erstellt.',
              message: 'Der Standort wurde erfolgreich angelegt.'
            });
          }
        })
        .catch(error => {
          const code = error.response.data.detail.specificCode;
          let title = 'Fehler';
          let message = '';

          switch (code) {
            case 'ERR_COORDINATES_NOT_FOUND':
              message =
                'Für diese Adresse konnten keine Koordinaten ermittelt werden. Bitte prüfen Sie die Angaben nochmals.';
              break;
            default:
              message = 'Unbekannter Fehler.';
              break;
          }

          props.notification({ type: 'error', title: title, message: message });
        });
    }

    setAnchorEl(null);
  };

  const openPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container spacing={2} key="3">
      <Grid item xs={1}>
        <Grid item xs={3} key="32">
          <IconButton color="primary" onClick={openPopover}>
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid item xs={6}>
          <Popover
            PaperProps={{
              style: { width: '340px' }
            }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Box m={2} pt={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography align="left" variant="h4" component="span">
                    Neuer Standort
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    error={isErrorStreetName}
                    helperText={errorTextStreetName}
                    label={'Strasse'}
                    value={streetName}
                    onChange={e => {
                      setIsErrorStreetName(
                        !e.target.value.match(new RegExp(/^[A-Za-z0-9 ]{3,}/))
                      );
                      setStreetName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    error={isErrorStreetNumber}
                    helperText={errorTextStreetNumber}
                    label={'Hausnummer'}
                    value={streetNumber}
                    onChange={e => {
                      setIsErrorStreetNumber(
                        !e.target.value.match(new RegExp(/^[A-Za-z0-9 ]{1,}/))
                      );
                      setStreetNumber(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    error={isErrorZip}
                    helperText={zipErrorText}
                    label={'Postleitzahl'}
                    value={zip}
                    onChange={e => {
                      setIsErrorZip(
                        !e.target.value.match(
                          new RegExp(/^[1-9][0-9][0-9][0-9]$/)
                        )
                      );
                      setZip(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    error={isErrorCity}
                    helperText={errorTextCity}
                    label={'Stadt'}
                    value={city}
                    onChange={e => {
                      setIsErrorCity(
                        !e.target.value.match(new RegExp(/^[A-Za-z0-9 ]{3,}/))
                      );
                      setCity(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    error={false}
                    helperText={errorTextCountry}
                    label={'Land'}
                    value={country}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={isErrorDescription}
                    helperText={errorTextDescription}
                    label={'Beschrieb'}
                    value={description}
                    onChange={e => {
                      setIsErrorDescription(
                        !e.target.value.match(new RegExp(/.*?/))
                      );
                      setDescription(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Switch
                          required
                          checked={isHeadoffice}
                          onChange={e => {
                            handleLocationHeadofficeChange(e);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Hauptsitz?"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2} pt={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={addLocation}
                    >
                      Erstellen
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLocationPopoverDialogue);
