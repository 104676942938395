import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import Select from '@material-ui/core/Select';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import { Paper } from '@material-ui/core';
import LoadingContainer from '../../../commonComponents/LoadingContainer';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function AttachAppToBusinessFunctionWidget(props) {
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedApp, setSelectedApp] = useState('');
  const [selectedBusinessFunction, setSelectedBusinessFunction] = useState('');
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;
    if (props.authenticated) {
      setLoading(false);
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;

    switch (field) {
      case 'company':
        setSelectedCompany(newValue);
        break;
      case 'app':
        setSelectedApp(newValue);
        break;
      case 'businessfunction':
        setSelectedBusinessFunction(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const clearFields = () => {
    setSelectedCompany(null);
    setSelectedApp(null);
    setSelectedBusinessFunction(null);
  };

  const attachAppToBusinessFunction = event => {
    axios
      .post(`${props.url}/api/`, {
        tbd: ''
      })
      .then(data => {
        clearFields();
        props.refetchCallback();

        props.notification({
          type: 'success',
          title: 'Erfolg!',
          message: `tbd.`
        });
      })
      .catch(() => {
        props.notification({
          type: 'error',
          title: 'Fehler!',
          message: 'Das Unternehmen konnte nicht angelegt werden.'
        });
      });
  };

  return (
    <>
      <Paper>
        <Box m={2} className={classes.adminWidgetContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminWidgetHeading text="App- und Providerbeziehungen" />
            </Grid>

            <LoadingContainer loading={loading}>
              <Grid container item xs={12} p={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    select
                    fullWidth
                    label="Unternehmen"
                    type="text"
                    value={selectedCompany}
                    name={'company'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: false
                    }}
                    variant="outlined"
                    onChange={e => {
                      handleChange(e);
                    }}
                  >
                    {props.companies.map(c => {
                      return (
                        <MenuItem key={c.id} value={c.id}>
                          {c.name} (
                          {c.companytype.charAt(0).toUpperCase() +
                            c.companytype.slice(1)}
                          )
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} align={'left'}>
                  <Button
                    variant="outlined"
                    onClick={attachAppToBusinessFunction}
                  >
                    Anhängen
                  </Button>
                </Grid>
              </Grid>
            </LoadingContainer>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachAppToBusinessFunctionWidget);

{
  /* <Select
                  native
                  value={
                    selectedCompany.id !== undefined ? selectedCompany.id : ''
                  }
                  onChange={handleCompanyChange}
                >
                  <option key={0} aria-label="None" value="" />;
                  {companiesList.map(c => {
                    return (
                      <option key={c.id} value={c.id}>
                        {c.name} (
                        {c.companytype.charAt(0).toUpperCase() +
                          c.companytype.slice(1)}
                        )
                      </option>
                    );
                  })}
                </Select> */
}
