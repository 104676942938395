import React, { Component, useState, useEffect } from 'react';
import styles from './profile.module.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Email from '@material-ui/icons/Email';
import Phone from '@material-ui/icons/LocalPhone';
import Chat from '@material-ui/icons/Chat';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { setUser } from '../../../../redux/actions/connectionActions';

function Profile({ setUser, user, url, notification, authenticated }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    let isMounted = true;

    if (authenticated === true && user !== null) {
      if (isMounted === true) {
        setLoading(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const updateUserPreference = (newValue, fieldName) => {
    if (authenticated) {
      axios
        .put(`${url}/api/user/preferences`, {
          email: user.email,
          settings: {
            [fieldName]: newValue
          }
        })
        .then(response => {
          setUser(response.data.user);
          notification({
            type: 'success',
            title: 'Einstellungen aktualisiert.',
            message: 'Ihre Einstellungen wurden aktualisiert.'
          });
        });
    }
  };

  const handleChangeStartpage = event => {
    updateUserPreference(event.target.value, 'preference_startpage');
  };
  const handleChangeSidemenu = event => {
    updateUserPreference(event.target.value, 'preference_sidemenu');
  };

  return (
    <>
      {loading ? (
        <Typography variant="h5" component="span" gutterBottom>
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : (
        <div className={styles.profile}>
          <Grid container spacing={2}>
            <Grid container item xs={12} sm={12} md={9} lg={6} xl={3}>
              <Card className={styles.card}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="span" gutterBottom>
                          Ihre persönlichen Basisdaten
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          component="span"
                          gutterBottom
                        >
                          Diese Daten können Sie nicht ändern. Wenn etwas nicht
                          stimmt, kontaktieren Sie uns bitte über den Chatbot
                          unten rechts.{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            disabled
                            id="k"
                            label="Vorname"
                            variant="outlined"
                            value={user.firstName}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            disabled
                            id="l"
                            label="Nachname"
                            variant="outlined"
                            value={user.lastName}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            disabled
                            id="m"
                            label="Email-Adresse"
                            variant="outlined"
                            value={user.email}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid container item xs={12} sm={12} md={9} lg={6} xl={3}>
              <Card className={styles.card}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="span" gutterBottom>
                          Support - Wir sind für Sie da
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Sie haben ein Anliegen? Sie erreichen uns auf
                          verschiedenen Wegen.{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <List
                          sx={{
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper'
                          }}
                        >
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <Chat />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Per Chatbot"
                              secondary="Klicken Sie einfach unten rechts auf die Sprechblase und wir bearbeiten Ihr Anliegen schnellstmöglich."
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <Email />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Per Email"
                              secondary="Senden Sie uns Ihr Anliegen an info@bankingmap.ch."
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <Phone />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary="Per Telefon"
                              secondary="Sie erreichen uns unter 044 226 20 85."
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid container item xs={12} sm={12} md={9} lg={6} xl={3}>
              <Card className={styles.card}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="span" gutterBottom>
                          Ihre App-Präferenzen
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          component="span"
                          gutterBottom
                        >
                          Hier können Sie die Swiss Banking Map an Ihre
                          Bedürfnisse anpassen.{' '}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="3a">Bevorzugte Startseite</InputLabel>
                          <Select
                            id="3b"
                            value={
                              user.preference_startpage
                                ? user.preference_startpage
                                : 'dashboard'
                            }
                            label="Bevorzugte Startseite"
                            onChange={handleChangeStartpage}
                          >
                            <MenuItem value={'dashboard'}>Dashboard</MenuItem>
                            <MenuItem value={'map'}>Map</MenuItem>
                            <MenuItem value={'my-companies'}>
                              Meine Unternehmen
                            </MenuItem>
                            <MenuItem value={'chart-explorer'}>
                              Chart Explorer
                            </MenuItem>
                            <MenuItem value={'news-aggregator'}>
                              News Aggregator
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="4a">
                            Standardzustand Seitenleiste
                          </InputLabel>
                          <Select
                            id="4b"
                            value={
                              user.preference_sidemenu
                                ? user.preference_sidemenu
                                : 'closed'
                            }
                            label="Standardzustand Seitenleiste"
                            onChange={handleChangeSidemenu}
                          >
                            <MenuItem value={'open'}>offen</MenuItem>
                            <MenuItem value={'closed'}>geschlossen</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
