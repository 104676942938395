import React, { useState, useEffect } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { Typography, CircularProgress } from '@material-ui/core';
import styles from './map.module.scss';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FilterResultsWidget from './FilterResultsWidget';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0),
      width: theme.spacing(0),
      height: theme.spacing(0)
    }
  },
  formControl: {
    margin: theme.spacing(0.25),
    minWidth: 260
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  filterBox: { fontSize: theme.typography.pxToRem(15) }
}));

const Accordion = withStyles(theme => ({
  root: {
    width: '300px',
    border: `0px solid ${theme.palette.primary.main}`,
    //boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {}
}))(MuiAccordion);

function MapContainer(props) {
  const classes = useStyles();
  const [showingInfoWindow, setShowingInfoWindow] = useState(true);
  const [activeMarker, setActiveMarker] = useState({});
  const [activeCompany, setActiveCompany] = useState({ company: {} });
  //const [selectedPlace, setSelectedPlace] = useState({})
  const [loading, setLoading] = useState(false);
  let markers = [];
  let isMounted = true;

  const [places, setPlaces] = useState({
    banks: [],
    providers: [],
    softwarevendors: []
  });
  const [selectData, setSelectData] = useState({
    availableApplications: [],
    availableBanktypes: [],
    availableCompanytypes: [],
    availableProviders: [],
    availableCommunities: []
  });

  const onMarkerMounted = marker => {
    let markersNew = [];
    if (marker !== null) {
      const exists = markers.find(m => {
        return m.payload.companyId === marker.marker.payload.companyId;
      });

      if (!exists) {
        markersNew = markers;
        markersNew.push(marker.marker);
        //setMarkers(markersNew);
      } else {
        markersNew = markers.filter(m => {
          return m.payload.companyId !== marker.marker.payload.companyId;
        });
        markersNew.push(marker.marker);
      }
      markers = markersNew;
    }
  };

  const [filter, setFilter] = useState({
    app_sales_crm: '',
    app_banking_kr_advisory: '',
    app_banking_ws_advisory: '',
    app_banking_vs_advisory: '',
    sourcing_banking_zv_processing: '',
    sourcing_banking_ws_processing: '',
    sourcing_banking_kr_mortgageprocessing: '',
    sourcing_it_application_operation: '',
    sourcing_it_workplace: '',
    meta_community: '',
    meta_banktype: '',
    meta_coresystem: '',
    general_companytype: ''
  });

  useEffect(() => {
    const filters = {
      filters: {
        BANK_APP: {
          sales_crm: filter.app_sales_crm === '' ? [] : [filter.app_sales_crm],
          banking_kr_advisory:
            filter.app_banking_kr_advisory === ''
              ? []
              : [filter.app_banking_kr_advisory],
          banking_ws_advisory:
            filter.app_banking_ws_advisory === ''
              ? []
              : [filter.app_banking_ws_advisory],
          banking_vs_advisory:
            filter.app_banking_vs_advisory === ''
              ? []
              : [filter.app_banking_vs_advisory]
        },
        BANK_SOURCING: {
          banking_zv_processing:
            filter.sourcing_banking_zv_processing === ''
              ? []
              : [filter.sourcing_banking_zv_processing],
          banking_ws_processing:
            filter.sourcing_banking_ws_processing === ''
              ? []
              : [filter.sourcing_banking_ws_processing],
          banking_kr_mortgageprocessing:
            filter.sourcing_banking_kr_mortgageprocessing === ''
              ? []
              : [filter.sourcing_banking_kr_mortgageprocessing],
          it_application_operation:
            filter.sourcing_it_application_operation === ''
              ? []
              : [filter.sourcing_it_application_operation],
          it_workplace:
            filter.sourcing_it_workplace === ''
              ? []
              : [filter.sourcing_it_workplace]
        },
        BANK_METADATA: {
          community:
            filter.meta_community === '' ? [] : [filter.meta_community],
          banktype: filter.meta_banktype === '' ? [] : [filter.meta_banktype],
          coresystem:
            filter.meta_coresystem === '' ? [] : [filter.meta_coresystem]
        },
        PROVIDER_OFFERING: [],
        GENERAL: {
          companytype:
            filter.general_companytype === ''
              ? []
              : [filter.general_companytype]
        }
      }
    };

    axios.defaults.withCredentials = true;
    if (props.authenticated === true && props.url !== null) {
      axios
        .post(`${props.url}/api/company/mapDataset`, filters)
        .then(response => {
          if (isMounted === true) {
            setPlaces(response.data.companies.companydata);
            setSelectData(response.data.companies.metadata);
            setLoading(false);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [filter]);

  const resetFilters = () => {
    setFilter({
      app_sales_crm: '',
      app_banking_kr_advisory: '',
      app_banking_ws_advisory: '',
      app_banking_vs_advisory: '',
      sourcing_banking_zv_processing: '',
      sourcing_banking_ws_processing: '',
      sourcing_banking_kr_mortgageprocessing: '',
      sourcing_it_application_operation: '',
      sourcing_it_workplace: '',
      meta_community: '',
      meta_banktype: '',
      meta_coresystem: '',
      general_companytype: ''
    });
  };

  const handleMarkerOpenFromFilterWidget = ({ companyId }) => {
    /* console.log(
      'handleMarkerOpenFromFilterWidget in GoogleMapComponent called.'
    ); */

    let marker = markers.find(marker => {
      return marker.payload.companyId === companyId;
    });
    onMarkerClick(null, marker, null);
  };

  const onMarkerClick = (dat, marker, e) => {
    const companyId = marker.payload.companyId;
    axios
      .get(`${props.url}/api/company?companyId=${companyId}`)
      .then(response => {
        if (isMounted === true) {
          setActiveCompany(response.data.data);
          setActiveMarker(marker);
          setShowingInfoWindow(true);
        }
      });
  };

  const onMapClicked = props => {
    if (showingInfoWindow) {
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };

  const onMouseoverMarker = (props, marker, e) => {
    //onMarkerClick(props, marker, e);
  };

  const onMouseoutMarker = (props, marker, e) => {};

  const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: [
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#444444' }]
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [{ color: '#f2f2f2' }]
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ saturation: -100 }, { lightness: 45 }]
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [{ color: '#46bcec' }, { visibility: 'on' }]
        },
        {
          featureType: 'administrative.province',
          stylers: [{ visibility: 'on' }]
        },
        {
          featureType: 'administrative.province',
          elementType: 'geometry.stroke',
          stylers: [{ visibility: 'off' }, { gamma: 0.03 }, { weight: 0 }]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'on' }, { color: '#5a85bc' }]
        },
        {
          featureType: 'administrative.country',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'on' }]
        },
        {
          featureType: 'administrative.country',
          elementType: 'geometry.stroke',
          stylers: [{ visibility: 'on' }, { weight: 2 }]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'geometry.stroke',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'geometry',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'geometry.stroke',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }
      ]
    });
  };

  const map = (
    <Map
      google={props.google}
      onClick={onMapClicked}
      onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
      style={{
        position: 'relative',
        height: 'calc(100vh - 80px)',
        width: '95.5vw'
      }}
      zoom={8}
      zoomControlOptions={{
        position: google.maps.ControlPosition.RIGHT_CENTER
      }}
      minZoom={8}
      initialCenter={{
        lat: 46.801210194444,
        lng: 7.5266921944444
      }}
      draggable={true}
      scrollwheel={true}
      keyboardShortcuts={true}
      disableDoubleClickZoom={false}
      zoomControl={true}
      mapTypeControl={false}
      scaleControl={true}
      gestureHandling="cooperative"
      streetViewControl={true}
      panControl={true}
      rotateControl={false}
      fullscreenControl={true}
      yesIWantToUseGoogleMapApiInternals={true}
    >
      {places.banks.map(place => (
        <Marker
          key={place.companyId}
          ref={onMarkerMounted}
          icon={{
            path:
              'M 12 22 z M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z',
            strokeColor: 'RoyalBlue',
            fillColor: 'Gray',
            strokeWeight: 1,
            fillOpacity: 0.5,
            scale: 1.5
          }}
          onClick={onMarkerClick}
          title={place.name}
          name={place.name}
          onMouseover={onMouseoverMarker}
          onMouseout={onMouseoutMarker}
          position={{
            lat: parseFloat(place.locations.location.coordinates.latitude),
            lng: parseFloat(place.locations.location.coordinates.longitude)
          }}
          payload={{ companyId: place.companyId }}
        />
      ))}

      {places.providers.map(place => (
        <Marker
          ref={onMarkerMounted}
          key={place.companyId}
          icon={{
            path:
              'M 12 22 z M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z',
            strokeColor: 'DarkMagenta',
            fillColor: 'Gray',
            strokeWeight: 1,
            fillOpacity: 0.5,
            scale: 1.5
          }}
          onClick={onMarkerClick}
          title={place.name}
          name={place.name}
          onMouseover={onMouseoverMarker}
          onMouseout={onMouseoutMarker}
          position={{
            lat: parseFloat(place.locations.location.coordinates.latitude),
            lng: parseFloat(place.locations.location.coordinates.longitude)
          }}
          payload={{ companyId: place.companyId }}
        />
      ))}

      {places.softwarevendors.map(place => (
        <Marker
          key={place.companyId}
          ref={onMarkerMounted}
          icon={{
            path:
              'M 12 22 z M 12 2.016 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z',
            strokeColor: 'DarkOrange',
            fillColor: 'Gray',
            strokeWeight: 1,
            fillOpacity: 0.5,
            scale: 1.5
          }}
          onClick={onMarkerClick}
          title={place.name}
          name={place.name}
          onMouseover={onMouseoverMarker}
          onMouseout={onMouseoutMarker}
          position={{
            lat: parseFloat(place.locations.location.coordinates.latitude),
            lng: parseFloat(place.locations.location.coordinates.longitude)
          }}
          payload={{ companyId: place.companyId }}
        />
      ))}
      <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
        <div>
          <h2>{activeCompany.company.name}</h2>
          {activeCompany.company.generalMetadata ? (
            <>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Datenfeld</TableCell>
                      <TableCell align="right">Wert</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="1">
                      <TableCell component="th" scope="row">
                        Anzahl Mitarbeiter
                      </TableCell>
                      <TableCell align="right">
                        {activeCompany.company.generalMetadata.employeecount}
                      </TableCell>
                    </TableRow>
                    <TableRow key="2">
                      <TableCell component="th" scope="row">
                        Anzahl FTE
                      </TableCell>
                      <TableCell align="right">
                        {activeCompany.company.generalMetadata.ftecount}
                      </TableCell>
                    </TableRow>
                    <TableRow key="3">
                      <TableCell component="th" scope="row">
                        Gründungsjahr
                      </TableCell>
                      <TableCell align="right">
                        {activeCompany.company.generalMetadata.foundation}
                      </TableCell>
                    </TableRow>
                    <TableRow key="4">
                      <TableCell component="th" scope="row">
                        Rechtsform
                      </TableCell>
                      <TableCell align="right">
                        {activeCompany.company.generalMetadata.legalform}
                      </TableCell>
                    </TableRow>
                    <TableRow key="5">
                      <TableCell component="th" scope="row">
                        Website
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          target="_blank"
                          href={activeCompany.company.generalMetadata.website}
                        >
                          Link
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow key="6">
                      <TableCell component="th" scope="row">
                        Handelsregister-Auszug
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          target="_blank"
                          href={activeCompany.company.generalMetadata.hrentry}
                        >
                          Link
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow key="6">
                      <TableCell component="th" scope="row">
                        Deepdive
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          href={
                            '/deepdive?companyId=' + activeCompany.company._id
                          }
                          target="_blank"
                          edge="end"
                          aria-label="showDeepdive"
                        >
                          <ZoomInIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            ''
          )}
        </div>
      </InfoWindow>
    </Map>
  );

  if (!props.google) {
    return <div>Lädt...</div>;
  }

  return (
    <>
      {loading ? (
        <Typography variant="h5" gutterBottom>
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : (
        <>
          {map}
          <Box
            display="flex"
            justifyContent="flex-start"
            m={1}
            p={1}
            bgcolor="background.paper"
          >
            <Box p={0} position="absolute">
              <Draggable
                handle=".handle"
                defaultPosition={{ x: 500, y: 10 }}
                position={null}
                grid={[10, 10]}
                scale={1}
              >
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5" gutterBottom>
                        Filter
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <div className={classes.filterArea}>
                          <FormControl
                            key="sales_crm"
                            className={classes.formControl}
                          >
                            <InputLabel id="sales_crm">
                              Expertensystem CRM
                            </InputLabel>
                            <Select
                              labelId="sales_crm"
                              id="sales_crm"
                              value={filter.app_sales_crm}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  app_sales_crm: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableApplications.map(app => (
                                <MenuItem key={app.id} value={app.id}>
                                  {app.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="banking_kr_advisory"
                            className={classes.formControl}
                          >
                            <InputLabel id="banking_kr_advisory">
                              Expertensystem Finanzierungsberatung
                            </InputLabel>
                            <Select
                              labelId="banking_kr_advisory"
                              id="banking_kr_advisory"
                              value={filter.app_banking_kr_advisory}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  app_banking_kr_advisory: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableApplications.map(app => (
                                <MenuItem key={app.id} value={app.id}>
                                  {app.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="banking_ws_advisory"
                            className={classes.formControl}
                          >
                            <InputLabel id="banking_ws_advisory">
                              Expertensystem Anlageberatung
                            </InputLabel>
                            <Select
                              labelId="banking_ws_advisory"
                              id="banking_ws_advisory"
                              value={filter.app_banking_ws_advisory}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  app_banking_ws_advisory: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableApplications.map(app => (
                                <MenuItem key={app.id} value={app.id}>
                                  {app.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="banking_vs_advisory"
                            className={classes.formControl}
                          >
                            <InputLabel id="banking_vs_advisory">
                              Expertensystem Vorsorgeberatung
                            </InputLabel>
                            <Select
                              labelId="banking_vs_advisory"
                              id="banking_vs_advisory"
                              value={filter.app_banking_vs_advisory}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  app_banking_vs_advisory: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableApplications.map(app => (
                                <MenuItem key={app.id} value={app.id}>
                                  {app.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="banking_zv_processing"
                            className={classes.formControl}
                          >
                            <InputLabel id="banking_zv_processing">
                              Provider Zahlungsverkehrs-Abwicklung
                            </InputLabel>
                            <Select
                              labelId="banking_zv_processing"
                              id="banking_zv_processing"
                              value={filter.sourcing_banking_zv_processing}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  sourcing_banking_zv_processing: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableProviders.map(provider => (
                                <MenuItem key={provider.id} value={provider.id}>
                                  {provider.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="banking_ws_processing"
                            className={classes.formControl}
                          >
                            <InputLabel id="banking_ws_processing">
                              Provider Wertschriften-Abwicklung
                            </InputLabel>
                            <Select
                              labelId="banking_ws_processing"
                              id="banking_ws_processing"
                              value={filter.sourcing_banking_ws_processing}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  sourcing_banking_ws_processing: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableProviders.map(provider => (
                                <MenuItem key={provider.id} value={provider.id}>
                                  {provider.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="banking_kr_mortgageprocessing"
                            className={classes.formControl}
                          >
                            <InputLabel id="banking_kr_mortgageprocessing">
                              Provider Hypotheken-Abwicklung
                            </InputLabel>
                            <Select
                              labelId="banking_kr_mortgageprocessing"
                              id="banking_kr_mortgageprocessing"
                              value={
                                filter.sourcing_banking_kr_mortgageprocessing
                              }
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  sourcing_banking_kr_mortgageprocessing:
                                    e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableProviders.map(provider => (
                                <MenuItem key={provider.id} value={provider.id}>
                                  {provider.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="it_application_operation"
                            className={classes.formControl}
                          >
                            <InputLabel id="it_application_operation">
                              Provider IT Application Operation
                            </InputLabel>
                            <Select
                              labelId="it_application_operation"
                              id="it_application_operation"
                              value={filter.sourcing_it_application_operation}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  sourcing_it_application_operation:
                                    e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableProviders.map(provider => (
                                <MenuItem key={provider.id} value={provider.id}>
                                  {provider.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="it_workplace"
                            className={classes.formControl}
                          >
                            <InputLabel id="it_workplace">
                              Provider IT Workplace
                            </InputLabel>
                            <Select
                              labelId="it_workplace"
                              id="it_workplace"
                              value={filter.sourcing_it_workplace}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  sourcing_it_workplace: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableProviders.map(provider => (
                                <MenuItem key={provider.id} value={provider.id}>
                                  {provider.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="community"
                            className={classes.formControl}
                          >
                            <InputLabel id="community">
                              Banken-Community
                            </InputLabel>
                            <Select
                              labelId="community"
                              id="community"
                              value={filter.meta_community}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  meta_community: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableCommunities.map(
                                community => (
                                  <MenuItem key={community} value={community}>
                                    {community}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="banktype"
                            className={classes.formControl}
                          >
                            <InputLabel id="banktype">Banktyp</InputLabel>
                            <Select
                              labelId="banktype"
                              id="banktype"
                              value={filter.meta_banktype}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  meta_banktype: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableBanktypes.map(type => (
                                <MenuItem key={type} value={type}>
                                  {type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="coresystem"
                            className={classes.formControl}
                          >
                            <InputLabel id="coresystem">
                              Kernbankensystem
                            </InputLabel>
                            <Select
                              labelId="coresystem"
                              id="coresystem"
                              value={filter.meta_coresystem}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  meta_coresystem: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableApplications.map(app => (
                                <MenuItem key={app.id} value={app.id}>
                                  {app.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            key="companytype"
                            className={classes.formControl}
                          >
                            <InputLabel id="companytype">
                              Unternehmenstyp
                            </InputLabel>
                            <Select
                              labelId="companytype"
                              id="companytype"
                              value={filter.general_companytype}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  general_companytype: e.target.value
                                });
                              }}
                            >
                              <MenuItem key={9999} value={null}>
                                {'-'}
                              </MenuItem>
                              {selectData.availableCompanytypes.map(type => (
                                <MenuItem
                                  key={type.technical}
                                  value={type.technical}
                                >
                                  {type.clear}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* <Button type="submit" key="btnUpdate" variant="contained">Update</Button> */}
                          <Box m={1} pt={1}>
                            <Button
                              key="btnReset"
                              variant="contained"
                              onClick={() => {
                                resetFilters();
                              }}
                            >
                              Filter zurücksetzen
                            </Button>
                          </Box>
                          <DragIndicatorIcon className={'handle'} />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Draggable>
            </Box>
            <Box p={0} position="absolute">
              <FilterResultsWidget
                onItemClick={handleMarkerOpenFromFilterWidget}
                map={map}
                places={places}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GoogleApiWrapper({
    apiKey: 'AIzaSyC-SyGvb8faHFibsl0q2595xeg2o1ctie4',
    v: '3.30'
  })(MapContainer)
);
