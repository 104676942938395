import GeneralDataEditComponent from './GeneralDataEditComponent';
import React from 'react';
export default function JurisdictionalFormFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Rechtsform"
      type="select"
      choices={[
        { label: '-', value: '' },
        { label: 'GmbH', value: 'GmbH' },
        { label: 'AG', value: 'AG' },
        { label: 'KG', value: 'KG' },
        { label: 'Genossenschaft', value: 'Genossenschaft' }
      ]}
      company={props.company}
      area="generalMetadata"
      field="legalform"
    />
  );
}
