import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

export default function VerifiedIconComponent({ verified }) {
  const theme = useTheme();
  return verified === false ? (
    <Tooltip title="Nicht verifiziert" key="tt6">
      <VerifiedUserIcon />
    </Tooltip>
  ) : (
    <Tooltip title="Verifiziert" key="tt6">
      <VerifiedUserIcon style={{ color: theme.palette.success.main }} />
    </Tooltip>
  );
}
