import React, { useState, useEffect } from 'react';
import styles from './login.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAuth,
  setUser,
  setPermissions
} from '../../../redux/actions/connectionActions';
import {
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import imageLogo from '../../../logo.png';
import imageBg from '../../../bg.svg';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  log: {
    width: '80vw'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 20,
    width: '100%',
    height: 35
  },
  footerR: { textAlign: 'right', color: 'white', paddingRight: 40 },
  footerL: { textAlign: 'left', color: 'white' },
  badge: {
    zIndex: 10000000,
    position: 'absolute',
    top: '22px',
    left: '50px'
  }
}));

function Login(props) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const url = useSelector(state => state.url);
  const notification = useSelector(state => state.notification);

  const login = async () => {
    const userdata = {
      email: email,
      password: password
    };

    setLoading(true);

    const response = await axios.post(`${url}/api/auth/login/local`, userdata);

    if (response.data.success) {
      setLoading(false);
      dispatch(setAuth(true));
      dispatch(setUser(response.data.user));
      dispatch(setPermissions(response.data.permissions));

      /*   response.data.user.preference_startpage
        ? navigate('/' + response.data.user.preference_startpage)
        : navigate('/map'); */
    } else {
      setLoading(false);
      notification({
        type: 'danger',
        title: 'Login fehlgeschlagen!',
        message: 'Falsche Email-Adresse oder Passwort!'
      });
    }
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  return (
    <>
      <div
        className={styles.login}
        style={{
          backgroundImage: `url(${imageBg})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '100vH',
          marginTop: -64,
          marginBottom: -64,
          zIndex: 1,
          overflow: 'hidden'
        }}
      >
        <Grid
          container
          heightdirection="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid container justifyContent="center" item xs={12}>
            <Box
              className={classes.log}
              style={{ marginTop: '100px' }}
              m={2}
              component="img"
              src={imageLogo}
            />
          </Grid>
          <Grid item xs={10} sm={6} md={4}>
            <Paper style={{ marginTop: '20px' }}>
              <Typography className={styles.title} component="div" variant="h5">
                Einloggen
              </Typography>
              <ValidatorForm
                // useRef="form"
                onSubmit={login}
                // onError={ errors => console.log(errors) }
                className={styles.content}
              >
                <TextValidator
                  label="Email"
                  onChange={handleChange}
                  name="email"
                  value={email || ''}
                  validators={[
                    'required',
                    'isEmail',
                    'minStringLength:5',
                    'maxStringLength:100'
                  ]}
                  errorMessages={[
                    'Dies ist ein Pflichtfeld',
                    'Ungültige Emailadresse',
                    'min length 5',
                    'max length 100'
                  ]}
                  margin="normal"
                  fullWidth
                  autoComplete="new-password"
                />
                <TextValidator
                  label="Passwort"
                  onChange={handleChange}
                  name="password"
                  type="password"
                  value={password || ''}
                  validators={[
                    'required',
                    'minStringLength:5',
                    'maxStringLength:100'
                  ]}
                  errorMessages={[
                    'Dies ist ein Pflichtfeld',
                    'Mindestens 5 Zeichen',
                    'Maximal 100 Zeichen'
                  ]}
                  margin="normal"
                  fullWidth
                  autoComplete="new-password"
                />
                {/*  <FormControlLabel
                  control={<Checkbox value="remember" color="secondary" />}
                  label="Merken"
                /> */}
                {loading ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled
                  >
                    <CircularProgress color="primary" size={24} />
                  </Button>
                ) : (
                  <>
                    <Box m={1} pt={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Login
                      </Button>
                    </Box>
                    <Box m={1} pt={1}>
                      <Button
                        className={'btn_pw_forgotten'}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        href="/recovery"
                      >
                        Passwort vergessen
                      </Button>
                    </Box>
                  </>
                )}
              </ValidatorForm>
            </Paper>
          </Grid>
          <Grid item container className={classes.footer}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.footerL}>
                © 2022 indivio AG. Alle Rechte vorbehalten.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.footerR}>
                Made with <FavoriteIcon></FavoriteIcon> by indivio.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Box className={classes.badge}>
        {process.env.REACT_APP_BANKINGMAP_ENV !== 'production' ? (
          <Badge
            badgeContent={'Client env: ' + process.env.REACT_APP_BANKINGMAP_ENV}
            overlap="rectangular"
            color="secondary"
          ></Badge>
        ) : (
          ''
        )}
      </Box>
    </>
  );
}

export default Login;
