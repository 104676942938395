import React, { Component, useState, useEffect } from 'react';
import styles from './home.module.scss';
import RegistrationFinish from '../../modelComponents/user/RegistrationFinish/RegistrationFinish';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import NotificationWidget from './NotificationWidget';
import Grid from '@material-ui/core/Grid';
import AccountboxIcon from '@material-ui/icons/AccountBoxOutlined';
import BusinessIcon from '@material-ui/icons/BusinessOutlined';
import MapIcon from '@material-ui/icons/MapOutlined';
import ExploreIcon from '@material-ui/icons/ExploreOutlined';
import PieIcon from '@material-ui/icons/PieChartOutlined';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import TablechartIcon from '@material-ui/icons/TableChartOutlined';
import FastrewindIcon from '@material-ui/icons/FastRewindOutlined';
import AnnouncementIcon from '@material-ui/icons/AnnouncementOutlined';
import DetailsIcon from '@material-ui/icons/DetailsOutlined';
import PageviewIcon from '@material-ui/icons/PageviewOutlined';
import SupervisoraccountIcon from '@material-ui/icons/SupervisorAccountOutlined';
import PermissionedTile from '../Navigation/PermissionedTile';
import { Typography, CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  welcome: {
    background: 'linear-gradient(45deg, white 20%, #f5f4f0 80%)'
    /*     color: 'black'
     */
  }
}));

function Home(props) {
  const classes = useStyles();

  const { user } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    if (props.authenticated === true && props.user !== null) {
      if (isMounted === true) {
        setLoading(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [props.user]);

  return (
    <>
      {loading ? (
        <Typography variant="h5" component="span" gutterBottom>
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : (
        <div className={styles.home}>
          {user && !user.active ? (
            <RegistrationFinish></RegistrationFinish>
          ) : null}

          <Grid container spacing={2}>
            {/* <Grid item container xs={12}>
              <Grid item xs={12}>
                <Card className={classes.welcome}>
                  <CardContent>
                    <Box m={0}>
                      <Typography variant="h5" component="span">
                        Herzlich willkommen, {user.firstName}!
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid> */}
            <Grid item container xs={12} md={12} lg={8}>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={false}
                  needsAuth={true}
                  requiredPermission="permission-view-profile-page"
                  to="/profile"
                  text="Mein Profil"
                  icon={<AccountboxIcon style={{ color: '#115293' }} />}
                  description="Ihre persönlichen Profileinstellungen"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={false}
                  needsAuth={true}
                  requiredPermission="permission-view-mycompanies-page"
                  to="/my-companies"
                  text="Meine Firmen"
                  icon={<BusinessIcon style={{ color: '#115293' }} />}
                  description="Verwaltung Ihrer Unternehmen"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={false}
                  needsAuth={true}
                  requiredPermission="permission-view-map-page"
                  to="/map"
                  text="Map"
                  icon={<MapIcon style={{ color: '#115293' }} />}
                  description="Gesamtsicht auf die Banking Map"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={false}
                  needsAuth={true}
                  requiredPermission="permission-view-chartexplorer-page"
                  to="/chart-explorer"
                  text="Chart Explorer"
                  icon={<ExploreIcon style={{ color: '#115293' }} />}
                  description="Hunderte Auswertungen zu Marktstrukturen."
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={false}
                  needsAuth={true}
                  requiredPermission="permission-view-deepdive-page"
                  to="/deepdive"
                  text="Deepdive"
                  icon={<DetailsIcon style={{ color: '#115293' }} />}
                  description="Detailsicht auf Unternehmensstrukturen"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={true}
                  availableFrom="Q3/22"
                  requiredPermission="permission-view-pivotmaster-page"
                  to="/pivot-master"
                  text="Pivot Master"
                  icon={<TablechartIcon style={{ color: '#bfbfbf' }} />}
                  description="Konfigurator für Ihre eigenen Auswertungen"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={true}
                  availableFrom="Q3/22"
                  needsAuth={true}
                  requiredPermission="permission-view-analytics-page"
                  to="/analytics"
                  text="Analytics"
                  icon={<PieIcon style={{ color: '#bfbfbf' }} />}
                  description="Vorgefertigte Auswertungssammlungen"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={true}
                  availableFrom="Q1/23"
                  needsAuth={true}
                  requiredPermission="permission-view-customanalytics-page"
                  to="/custom-analytics"
                  text="Custom Analytics"
                  icon={<AssessmentIcon style={{ color: '#bfbfbf' }} />}
                  description="Auswertungen zu Ihrem Unternehmen"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={true}
                  availableFrom="Q4/23"
                  needsAuth={true}
                  requiredPermission="permission-view-timewarp-page"
                  to="/time-warp"
                  text="Time Warp"
                  icon={<FastrewindIcon style={{ color: '#bfbfbf' }} />}
                  description="Eine Zeitreise durch die Marktstrukturen"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={true}
                  availableFrom="Q2/23"
                  needsAuth={true}
                  requiredPermission="permission-view-newsaggregator-page"
                  to="/news-aggregator"
                  text="News Aggregator"
                  icon={<AnnouncementIcon style={{ color: '#bfbfbf' }} />}
                  description="News aus dem Schweizer Bankenmarkt"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={true}
                  availableFrom="Q4/22"
                  needsAuth={true}
                  requiredPermission="permission-view-quarterlyreports-page"
                  to="/quarterly-reports"
                  text="Quarterly Reports"
                  icon={<PageviewIcon style={{ color: '#bfbfbf' }} />}
                  description="Vierteljährliche Summaries"
                ></PermissionedTile>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <PermissionedTile
                  isInDev={false}
                  needsAuth={true}
                  requiredPermission="permission-view-indivioadmin-page"
                  to="/indivio-admin"
                  text="indivio-Admin"
                  icon={<SupervisoraccountIcon style={{ color: '#115293' }} />}
                  description="Administration der Swiss Banking Map"
                ></PermissionedTile>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={12} lg={4}>
              <NotificationWidget />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user
  };
};

export default connect(mapStateToProps)(Home);
