import React, { useState, useEffect } from 'react';
import styles from './deepdive.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import BankDeepdive from './BankDeepdive';
import ProviderDeepdive from './ProviderDeepdive';
import SoftwarevendorDeepdive from './SoftwarevendorDeepdive';
import ApplicationDeepdive from './ApplicationDeepdive';

import queryString from 'query-string';
// eslint-disable-next-line no-unused-vars

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  selectEmpty: {}
}));

function Deepdive(props) {
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedApplication, setSelectedApplication] = useState({});
  const [companiesList, setCompaniesList] = useState([]);
  const [applicationsList, setApplicationsList] = useState([]);
  const classes = useStyles();

  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  useEffect(() => {
    const { companyId, applicationId } = queryString.parse(location.search);
    let isUnloaded = false;

    axios.defaults.withCredentials = true;

    if (props.authenticated) {
      setLoading(true);
      axios.get(`${props.url}/api/company/list`).then(response => {
        if (!isUnloaded) {
          setCompaniesList(response.data.companies);
          //console.log(response.data.companies);
          if (companyId !== undefined) {
            const company = response.data.companies.find(c => {
              return c.id === companyId;
            });
            if (company !== undefined) setSelectedCompany(company);
          }

          setLoading(false);
        }
      });
      setLoading(true);
      axios.get(`${props.url}/api/application/list`).then(response => {
        if (!isUnloaded) {
          setApplicationsList(response.data.applications);

          if (applicationId !== undefined) {
            const application = response.data.applications.find(a => {
              return a.id === applicationId;
            });
            if (application !== undefined) setSelectedApplication(application);
          }

          setLoading(false);
        }
      });
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  const handleCompanyChange = event => {
    const companyId = event.target.value;
    let company = companiesList.find(c => {
      return c.id === companyId;
    });
    if (company !== undefined) {
      setSelectedCompany(company);
      setSelectedApplication({});
    }
  };

  const handleAppChange = event => {
    const appId = event.target.value;
    let app = applicationsList.find(a => {
      return a.id === appId;
    });

    if (app !== undefined) {
      setSelectedCompany({});
      setSelectedApplication(app);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item container>
              <Grid item xs={12} md={4}>
                <Box m={1}>
                  <Paper className={classes.paper}>
                    <InputLabel id="demo-customized-select-label">
                      Unternehmen
                    </InputLabel>
                    <Select
                      fullWidth
                      value={
                        selectedCompany.id !== undefined
                          ? selectedCompany.id
                          : ''
                      }
                      onChange={handleCompanyChange}
                    >
                      <option key={0} aria-label="None" value="" />;
                      {companiesList.map(c => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.name} (
                            {c.companytype.charAt(0).toUpperCase() +
                              c.companytype.slice(1)}
                            )
                          </option>
                        );
                      })}
                    </Select>
                  </Paper>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box m={1}>
                  <Paper className={classes.paper}>
                    <InputLabel id="demo-customized-select-label">
                      Applikation
                    </InputLabel>
                    <Select
                      fullWidth
                      value={
                        selectedApplication.id !== undefined
                          ? selectedApplication.id
                          : ''
                      }
                      onChange={handleAppChange}
                    >
                      <option key={0} aria-label="None" value="" />;
                      {applicationsList.map(c => {
                        return (
                          <option key={c.id} value={c.id}>
                            {c.vendor !== undefined && c.vendor !== null
                              ? c.name + ' (' + c.vendor.name + ')'
                              : c.name}
                          </option>
                        );
                      })}
                    </Select>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {selectedCompany.companytype === 'bank' ? (
                <BankDeepdive
                  allCompanies={companiesList}
                  allApplications={applicationsList}
                  company={selectedCompany}
                ></BankDeepdive>
              ) : selectedCompany.companytype === 'provider' ? (
                <ProviderDeepdive
                  allCompanies={companiesList}
                  allApplications={applicationsList}
                  company={selectedCompany}
                ></ProviderDeepdive>
              ) : selectedCompany.companytype === 'softwarevendor' ? (
                <SoftwarevendorDeepdive
                  allCompanies={companiesList}
                  allApplications={applicationsList}
                  company={selectedCompany}
                ></SoftwarevendorDeepdive>
              ) : selectedApplication.id !== undefined ? (
                <ApplicationDeepdive
                  /* allCompanies={companiesList} */
                  /* allApplications={applicationsList} */
                  application={selectedApplication}
                ></ApplicationDeepdive>
              ) : null}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Deepdive);
