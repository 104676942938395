import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import { Paper } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LoadingContainer from '../../../commonComponents/LoadingContainer';
import ListItemText from '@mui/material/ListItemText';
import List from '@material-ui/core/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import { ListItem } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function UpdateApplicationWidget(props) {
  const [loading, setLoading] = useState(false);
  const [selectedApp, setSelectedApp] = useState('');
  const [appBasicData, setAppBasicData] = useState({
    name: '',
    description: ''
  });
  const [appName, setAppName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [appFunctionData, setAppFunctionData] = useState([]);

  const classes = useStyles();
  let isUnloaded = false;

  const updateState = (field, e) => {
    const state = e.target.value;
    const newState =
      state === null || state === undefined || state === 'unknown'
        ? null
        : state === 'yes'
        ? true
        : false;
    axios
      .put(`${props.url}/api/application/functions`, {
        applicationId: selectedApp.id,
        functionName: field,
        covered: newState,
        coveredProposed: null,
        initiator: 'indivio'
      })
      .then(data => {
        if (isUnloaded !== true) {
          getAppData(selectedApp.id);
          props.notification({
            type: 'success',
            title: 'Erfolg!',
            message: `Die Abdeckung wurde angepasst.`
          });
        }
      })
      .catch(e => {
        props.notification({
          type: 'error',
          title: 'Fehler!',
          message: 'Die Abdeckung konnte nicht angepasst werden, Grund: ' + e
        });
      });
  };

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;

    switch (field) {
      case 'app':
        setSelectedApp(newValue);
        getAppData(newValue.id);
        break;
      case 'description':
        console.log(newValue);
        setAppDescription(newValue);

        break;
      case 'appname':
        setAppName(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const updateBasicData = () => {
    if (appDescription !== '' && appName !== '') {
      if (props.authenticated) {
        const name = appName;
        const description = appDescription;
        const appId = appBasicData._id;

        axios
          .put(`${props.url}/api/application`, {
            applicationId: appId,
            name: name,
            description: description
          })
          .then(data => {
            if (isUnloaded !== true) {
              clearFields();
              props.refetchCallback();
              props.notification({
                type: 'success',
                title: 'Erfolg!',
                message: `Die Daten wurden angepasst.`
              });
            }
          })
          .catch(e => {
            if (isUnloaded !== true) {
              props.notification({
                type: 'error',
                title: 'Fehler!',
                message: 'Die Daten konnten nicht angepasst werden, Grund: ' + e
              });
            }
          });
      }
    }
  };

  const getAppData = aid => {
    if (props.authenticated) {
      axios
        .get(`${props.url}/api/application?applicationId=${aid}`)
        .then(data => {
          if (isUnloaded === false) {
            setAppBasicData(data.data.application.application);
            setAppFunctionData(data.data.application.availableFunctionsSchema);
            setAppName(data.data.application.application.name);
            setAppDescription(data.data.application.application.description);

            //props.refetchCallback();
          }
        })
        .catch(e => {
          if (isUnloaded === false) {
            props.notification({
              type: 'error',
              title: 'Fehler!',
              message:
                'Applikationsdaten konnten nicht abgerufen werden, Grund: ' +
                e.response.data.detail
            });
          }
        });
    }
  };

  const clearFields = () => {
    setAppName(''),
      setAppDescription(''),
      setAppFunctionData([]),
      setAppBasicData({
        name: '',
        description: '',
        _id: ''
      }),
      setSelectedApp('');
  };

  return (
    <>
      <Paper>
        <Box m={2} className={classes.adminWidgetContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminWidgetHeading text="Applikation aktualisieren" />
            </Grid>
            <LoadingContainer loading={loading}>
              <Grid item xs={12} md={12}>
                <Select
                  fullWidth
                  name="app"
                  value={selectedApp}
                  id="3bh"
                  onChange={handleChange}
                >
                  {props.apps.map((a, i) => {
                    return (
                      <MenuItem key={a.id} value={a}>
                        {a.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              {selectedApp !== '' ? (
                <>
                  <Grid item xs={12}>
                    <Box mt={2} mb={2}>
                      <Typography align="left" variant="h5">
                        Basisdaten
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      error={
                        appName.match(new RegExp(/^.*.{3,}$/)) ? false : true
                      }
                      label="Name der Applikation"
                      type="text"
                      name="appname"
                      value={appName}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        readOnly: false
                      }}
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      error={
                        appDescription.match(new RegExp(/^.*.{3,}$/))
                          ? false
                          : true
                      }
                      label="Kurzbeschrieb"
                      type="text"
                      name="description"
                      value={appDescription}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        readOnly: false
                      }}
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      disabled
                      label="App id"
                      type="text"
                      name="id"
                      value={appBasicData._id}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        readOnly: false
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} align={'left'}>
                    <Button variant="outlined" onClick={updateBasicData}>
                      Aktualisieren
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={2} mb={2}>
                      <Typography align="left" variant="h5">
                        Funktionsabdeckung
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <List dense={true}>
                      {appFunctionData.map(af => {
                        return (
                          <Grid container key={af.name}>
                            <Grid item xs={12} md={6}>
                              <Typography>{af.name}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  onChange={e => updateState(af.name, e)}
                                  aria-label="state"
                                  name="state"
                                  value={
                                    af.value === null
                                      ? 'unknown'
                                      : af.value.covered === true
                                      ? 'yes'
                                      : af.value.covered === false
                                      ? 'no'
                                      : 'unknown'
                                  }
                                  row
                                >
                                  <FormControlLabel
                                    value="unknown"
                                    control={<Radio />}
                                    label="?"
                                  />
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="ja"
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="nein"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={1}></Grid>
                          </Grid>
                        );
                      })}
                    </List>
                  </Grid>
                </>
              ) : null}
            </LoadingContainer>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateApplicationWidget);
