import { TimeFromNow } from '../../../helper/timeFromNow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
//import { useTheme } from "@material-ui/core/styles";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import BusinessIcon from '@material-ui/icons/Business';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DeliveredByComponent from './Fragments/DeliveredByComponent';
import LastUpdatedComponent from './Fragments/LastUpdatedComponent';
import RemarkComponent from './Fragments/RemarkComponent';
import SinceComponent from './Fragments/SinceComponent';
import VerifiedIconComponent from './Fragments/VerifiedIconComponent';
import { useTheme } from '@material-ui/core/styles';

function AppProviderItem(props) {
  const [company] = useState(props.company);
  const [meta] = useState(props.meta);
  const [businessFunction] = useState(props.businessFunction);
  const [clearname] = useState(props.clearname);
  const [area] = useState(props.area);
  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary
    }
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Paper className={classes.paper}>
        <Box key="functionTitle">
          <Typography
            align="left"
            variant="h6"
            component="span"
            key="functionArea"
          >
            {area} -
          </Typography>
          <Typography
            align="left"
            variant="h6"
            component="span"
            key="functionName"
          >
            {clearname}
          </Typography>
        </Box>

        <Typography align="left" component="span" variant="body1">
          Sourcingprovider
        </Typography>

        {meta.prohibitedFields.includes(businessFunction) === true ? (
          <Grid
            container
            alignItems="center"
            spacing={1}
            key="accessContainer1"
          >
            <Grid item key="accessIcon">
              <HighlightOffIcon color="secondary" />
            </Grid>
            <Grid item key="accessText">
              <Typography align="left" component="span" variant="body1">
                Sie haben keine Berechtigung, dieses Feld anzuzeigen.
              </Typography>
            </Grid>
          </Grid>
        ) : company.businessFunctions !== undefined &&
          company.businessFunctions !== null &&
          company.businessFunctions !== [] &&
          company.businessFunctions[businessFunction] !== undefined &&
          company.businessFunctions[businessFunction] !== null &&
          company.businessFunctions[businessFunction] !== [] &&
          company.businessFunctions[businessFunction].sourcingProviders
            .length !== 0 ? (
          company.businessFunctions[businessFunction].sourcingProviders.map(
            obj => {
              return obj.provider !== null && obj.provider !== undefined ? (
                <Box
                  margin={1}
                  border={1}
                  borderRadius={5}
                  key={obj.provider + 'k'}
                >
                  <Box p={1} key={obj.provider}>
                    <Grid container alignItems="center" spacing={1} key="1">
                      <Grid item key="5874">
                        <BusinessIcon />
                      </Grid>
                      <Grid item key="11">
                        <Typography align="left" component="span" variant="h6">
                          {
                            props.allCompanies.find(c => {
                              return c.id === obj.provider;
                            }).name
                          }
                        </Typography>
                      </Grid>
                      <Grid item key="12">
                        <VerifiedIconComponent />
                      </Grid>
                      <Grid item key="13">
                        <Link
                          target="_blank"
                          to={`/deepdive?companyId=${obj.provider}`}
                        >
                          <OpenInNewIcon color="primary" />
                        </Link>
                      </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} key="hiu2">
                      <Grid item key="21">
                        <SinceComponent since={obj.since} />
                      </Grid>
                      <Grid item key="23">
                        <LastUpdatedComponent
                          lastUpdated={TimeFromNow(obj.metadata.liveValueSetOn)}
                        />
                      </Grid>
                      <Grid item key="25">
                        <DeliveredByComponent
                          by={
                            obj.metadata.liveValueDeliveredBy === 'indivio'
                              ? 'Community'
                              : 'Unternehmen'
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={1} key="30">
                      <Grid item key="31">
                        <RemarkComponent remark={obj.remark} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : null;
            }
          )
        ) : (
          <Box m={1} p={1} border={1} borderRadius={5} key="kk">
            <Grid
              container
              alignItems="center"
              spacing={1}
              key="noProviderData"
            >
              <Grid item key="icon">
                <HighlightOffIcon style={{ color: theme.palette.info.main }} />
              </Grid>
              <Grid item key="text">
                <Typography
                  align="left"
                  component="span"
                  variant="body1"
                  key="1"
                >
                  Für diese Business-Funktion liegen noch keine Provider-Daten
                  vor.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}

        <Typography align="left" component="span" variant="body1">
          Applikationen
        </Typography>
        {meta.prohibitedFields.includes(businessFunction) === true ? (
          <Grid
            container
            alignItems="center"
            spacing={1}
            key="accessContainer2"
          >
            <Grid item key="accessIcon">
              <HighlightOffIcon color="secondary" />
            </Grid>
            <Grid item key="accessText">
              <Typography align="left" component="span" variant="body1">
                Sie haben keine Berechtigung, dieses Feld anzuzeigen.
              </Typography>
            </Grid>
          </Grid>
        ) : company.businessFunctions !== undefined &&
          company.businessFunctions !== null &&
          company.businessFunctions !== [] &&
          company.businessFunctions[businessFunction] !== undefined &&
          company.businessFunctions[businessFunction] !== null &&
          company.businessFunctions[businessFunction] !== [] &&
          company.businessFunctions[businessFunction].usedApplications
            .length !== 0 ? (
          company.businessFunctions[businessFunction].usedApplications.map(
            obj => {
              return obj.application !== null &&
                obj.application !== undefined ? (
                <Box
                  margin={1}
                  border={1}
                  borderRadius={5}
                  key={obj.application}
                >
                  <Box p={1} key={obj.provider}>
                    <Grid container alignItems="center" spacing={1} key="1">
                      <Grid item key="5874">
                        <DesktopMacIcon />
                      </Grid>
                      <Grid item key="11">
                        <Typography align="left" component="span" variant="h6">
                          {
                            props.allApplications.find(c => {
                              return c.id === obj.application;
                            }).name
                          }
                        </Typography>
                      </Grid>
                      <Grid item key="12">
                        <VerifiedIconComponent />
                      </Grid>
                      <Grid item key="13">
                        <Link
                          target="_blank"
                          to={`/deepdive?applicationId=${obj.application}`}
                        >
                          <OpenInNewIcon color="primary" />
                        </Link>
                      </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={1} key="hiu2">
                      <Grid item key="21">
                        <SinceComponent since={obj.since} />
                      </Grid>
                      <Grid item key="23">
                        <LastUpdatedComponent
                          lastUpdated={TimeFromNow(obj.metadata.liveValueSetOn)}
                        />
                      </Grid>
                      <Grid item key="25">
                        <DeliveredByComponent
                          by={
                            obj.metadata.liveValueDeliveredBy === 'indivio'
                              ? 'Community'
                              : 'Unternehmen'
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={1} key="30">
                      <Grid item key="31">
                        <RemarkComponent remark={obj.remark} />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : null;
            }
          )
        ) : (
          <Box m={1} p={1} border={1} borderRadius={5} key="mm">
            <Grid container alignItems="center" spacing={1} key="noAppData">
              <Grid item key="icon">
                <HighlightOffIcon style={{ color: theme.palette.info.main }} />
              </Grid>
              <Grid item key="text">
                <Typography
                  align="left"
                  component="span"
                  variant="body1"
                  key="1"
                >
                  Für diese Business-Funktion liegen noch keine
                  Applikations-Daten vor.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </Grid>
  );
}

export default AppProviderItem;
