import GeneralDataEditComponent from './GeneralDataEditComponent';
import React from 'react';
export default function LanguageFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Sprachen"
      type="multi"
      choices={[
        { label: '-', value: '' },
        { label: 'deutsch', value: 'DE' },
        { label: 'französisch', value: 'FR' },
        { label: 'italienisch', value: 'IT' },
        { label: 'englisch', value: 'GB' }
      ]}
      company={props.company}
      area="generalMetadata"
      field="languages"
    />
  );
}
