import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
//import { useTheme } from '@material-ui/core/styles';
import VerifiedIconComponent from './Fragments/VerifiedIconComponent';
import IsMainLocationComponent from './Fragments/IsMainLocationComponent';
import PublicIcon from '@material-ui/icons/Public';
import Tooltip from '@material-ui/core/Tooltip';

export default function LocationItem(props) {
  const [location] = useState(props.location);

  //const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)',
      minHeight: '100%'
    }
  }));

  const classes = useStyles();

  return (
    <Grid item>
      <Paper className={classes.paper} margin={1} border={1}>
        <Box key={'1'}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item key="de">
              <Typography align="left" component="span" variant="h6">
                {location.location.streetName + ', ' + location.location.city}
              </Typography>
            </Grid>
            <Grid item key="dd">
              <IsMainLocationComponent isMain={location.isHeadoffice} />
            </Grid>
            <Grid item key="13">
              <Tooltip title="Auf Google Maps anzeigen" key="tt4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.google.com/maps/search/?api=1&query=${location.location.coordinates.latitude},${location.location.coordinates.longitude}`}
                >
                  <PublicIcon color="primary" />
                </a>
              </Tooltip>
            </Grid>
            <Grid key="df">
              <VerifiedIconComponent verified={true} />
            </Grid>
          </Grid>
        </Box>
        <Box key="2">
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography align="left" component="span" variant="body1">
                {location.location.streetName +
                  ' ' +
                  location.location.streetNumber}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box key="3">
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography align="left" component="span" variant="body1">
                {location.location.zip +
                  ' ' +
                  location.location.city +
                  ' (' +
                  location.location.country +
                  ')'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box key="4">
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography align="left" component="span" variant="body1">
                {location.location.description !== undefined
                  ? location.location.description
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
}
