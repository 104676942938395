import CommentIcon from '@material-ui/icons/Comment';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import React from 'react';

export default function RemarkComponent({ remark }) {
  const theme = useTheme();
  return (
    <Box mr={1}>
      <Tooltip title="Bemerkungen des Unternehmens" key="tt4">
        <Grid container alignItems="center" spacing={1} key="2ret">
          <Grid item key="21">
            <CommentIcon style={{ color: theme.palette.warning.main }} />
          </Grid>
          <Grid item key="22">
            {remark}
          </Grid>
        </Grid>
      </Tooltip>
    </Box>
  );
}
