//import * as React from 'react';
import styles from './newsAggregator.module.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Typography, CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { GridToolbar } from '@material-ui/data-grid';
import { TimeFromNow } from '../../../helper/timeFromNow';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const stringToColour = function(str) {
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

function NewsAggregator(props) {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios.get(`${props.url}/api/news?count=100`).then(response => {
      setNews(response.data.news);
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.tbd}>
      <h1 align="left">News-Aggregator</h1>
      {loading ? (
        <Typography variant="h5" component="span" gutterBottom>
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : (
        <div style={{ height: '75vH', width: '100%' }}>
          <DataGrid
            loading={loading}
            localeText={{
              columnMenuSortAsc: 'Aufsteigend sortieren',
              columnMenuSortDesc: 'Absteigend sortieren',
              columnMenuLabel: 'Menu',
              columnMenuShowColumns: 'Spalten einblenden',
              columnMenuFilter: 'Filter',
              columnMenuHideColumn: 'Spalten ausblenden',
              columnMenuUnsort: 'Sortierung aufheben',
              toolbarDensity: 'Abstand',
              toolbarDensityLabel: 'eng',
              toolbarDensityCompact: 'kompakt',
              toolbarDensityStandard: 'normal',
              toolbarDensityComfortable: 'weit',
              toolbarColumns: 'Spalten',
              toolbarColumnsLabel: 'Spaltenauswählen',
              toolbarFilters: 'Filter',
              toolbarFiltersLabel: 'Filter anzeigen',
              toolbarFiltersTooltipHide: 'Filter ausblenden',
              toolbarFiltersTooltipShow: 'Filter anzeigen',
              toolbarFiltersTooltipActive: count =>
                count !== 1
                  ? `${count} active filters`
                  : `${count} active filter`,
              toolbarExport: 'Export',
              toolbarExportLabel: 'Export',
              toolbarExportCSV: 'Download as CSV',
              footerRowSelected: count =>
                count !== 1
                  ? `${count.toLocaleString()} Beträge ausgewählt`
                  : `${count.toLocaleString()} Beitrag ausgewählt`,
              filterPanelAddFilter: 'Filter hinzufügen',
              filterPanelDeleteIconLabel: 'Entfernen',
              filterPanelOperators: 'Operatoren',
              filterPanelOperatorAnd: 'Und',
              filterPanelOperatorOr: 'Oder',
              filterPanelColumns: 'Spalten',
              filterPanelInputLabel: 'Wert',
              filterPanelInputPlaceholder: 'Wert filtern',
              filterOperatorContains: 'enthält',
              filterOperatorEquals: 'entspricht',
              filterOperatorStartsWith: 'beginnt mit',
              filterOperatorEndsWith: 'endet mit',
              filterOperatorIsEmpty: 'ist leer',
              filterOperatorIsNotEmpty: 'ist nicht leer',
              filterOperatorIs: 'ist',
              filterOperatorNot: 'ist nicht',
              filterOperatorAfter: 'ist von nach ',
              filterOperatorOnOrAfter: 'ist von oder nach',
              filterOperatorBefore: 'ist vor',
              filterOperatorOnOrBefore: 'is von oder vor'
            }}
            rows={news.map(newsitem => ({
              id: newsitem._id,
              ...newsitem
            }))}
            columns={[
              {
                field: ' ',
                headerName: '',
                width: 60,
                renderCell: params => (
                  <>
                    <Avatar
                      style={{
                        backgroundColor: stringToColour(params.row.source.name)
                      }}
                    >
                      {params.row.source.name.substring(0, 2)}
                    </Avatar>
                  </>
                ),
                filterable: false,
                sortable: false
              },
              {
                field: 'source',
                headerName: 'Unternehmen',
                width: 350,
                renderCell: params => <>{params.row.source.name} </>,
                type: 'string'
              },
              {
                field: 'title',
                headerName: 'Titel',
                width: 600,
                type: 'string',
                sortable: false
              },
              {
                field: 'publishedAt',
                headerName: 'Veröffentlicht',
                width: 200,
                renderCell: params => TimeFromNow(params.value),
                type: 'date',
                filterable: false,
                sortable: false
              },
              {
                field: 'url',
                headerName: 'Beitrag',
                width: 200,
                renderCell: params => (
                  <>
                    <a rel="noreferrer" target="_blank" href={params.value}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        value={params.row.id}
                      >
                        <span>Zum Beitrag</span>
                      </Button>
                    </a>
                  </>
                ),
                filterable: false,
                sortable: false
              }
            ]}
            components={{
              Toolbar: GridToolbar
            }}
            pageSize={30}
            // checkboxSelection
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsAggregator);
