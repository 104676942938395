import { createTheme } from '@material-ui/core/styles';
import { deDE } from '@material-ui/core/locale';

export const themestd = createTheme(
  {
    palette: {
      primary: {
        light: '#4791db',
        main: '#115293',
        dark: '#115293',
        contrastText: '#fff'
      }
    },
    typography: {
      fontSize: 12,
      fontFamily: [
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      h6: {
        fontWeight: 600
      }
    }
  },
  deDE
);

/* typography: {
      fontSize: 12,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      h6: {
        fontWeight: 600
      }
    } */

themestd.typography.h3 = {
  fontSize: '1.3rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem'
  },
  [themestd.breakpoints.up('md')]: {
    fontSize: '1.8rem'
  },
  fontWeight: 200
};

themestd.typography.h4 = {
  fontSize: '1.1rem',
  '@media (min-width:600px)': {
    fontSize: '1.3rem'
  },
  [themestd.breakpoints.up('md')]: {
    fontSize: '1.5rem'
  },
  fontWeight: 200
};

themestd.typography.h5 = {
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '1.0rem'
  },
  [themestd.breakpoints.up('md')]: {
    fontSize: '1.1rem'
  },
  fontWeight: 200
};

themestd.typography.h6 = {
  fontSize: '0.7rem',
  '@media (min-width:600px)': {
    fontSize: '0.8rem'
  },
  [themestd.breakpoints.up('md')]: {
    fontSize: '0.9rem'
  },
  fontWeight: 600
};

export default themestd;
