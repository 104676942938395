import UpdateIcon from '@material-ui/icons/Update';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import React from 'react';

export default function LastUpdatedComponent({ lastUpdated }) {
  return (
    <Box mr={1}>
      <Tooltip title="Daten zuletzt aktualisiert" key="tt1">
        <Grid container alignItems="center" spacing={1} key="2342">
          <Grid item key="21">
            <UpdateIcon color="primary" />
          </Grid>
          <Grid item key="22">
            {lastUpdated}
          </Grid>
        </Grid>
      </Tooltip>
    </Box>
  );
}
