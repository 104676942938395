import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import date from 'date-and-time';

function FilesUploadComponent(props) {
  const [profileImg, setProfileImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState([]);
  const [isErrorDesc, setIsErrorDesc] = React.useState(false);
  const [errorTextDesc] = useState(
    'Bitte geben Sie eine kurze Beschriebung ein.'
  );

  const useStyles = makeStyles(theme => ({
    paper: {
      flexGrow: 1,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      textAlign: 'center',
      boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)'
    }
  }));

  const classes = useStyles();

  const allowedFiletypes = [
    'image/jpeg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/png',
    'image/png',
    'image/jpeg',
    'application/pdf',
    'application/mspowerpoint',
    'application/msword',
    'application/msexcel'
  ];

  const onFileChange = e => {
    const validFiletype = allowedFiletypes.includes(e.target.files[0].type);
    validFiletype ? setProfileImg(e.target.files[0]) : null;
  };

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;

    if (props.authenticated) {
      axios
        .all([
          axios.get(
            `${props.url}/api/company/files?companyId=${props.companyId}`
          )
        ])
        .then(
          axios.spread(firstResponse => {
            if (!isUnloaded) {
              setFiles(firstResponse.data.files);
              setLoading(false);
            }
          })
        );
    } //

    return () => {
      isUnloaded = true;
    };
  }, []);

  const streamFile = (fileId, fileName) => {
    if (props.authenticated) {
      axios({
        url: `${props.url}/api/company/file?fileId=${fileId}`,
        method: 'GET',
        responseType: 'blob' // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  const deleteFile = fileId => {
    if (props.authenticated) {
      axios
        .delete(`${props.url}/api/company/files`, { data: { fileId: fileId } })
        .then(data => {
          const filesNew = files.filter(f => {
            return f._id !== fileId;
          });

          setFiles(filesNew);

          props.notification({
            type: 'success',
            title: 'Datei gelöscht.',
            message: 'Die Datei wurde gelöscht.'
          });
        })
        .catch(err => {
          props.notification({
            type: 'error',
            title: 'Datei nicht gelöscht.',
            message: 'Die Datei konnte nicht gelöscht werden.'
          });
        });
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (profileImg !== null) {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', profileImg);
      axios
        .post(
          `${props.url}/api/company/files?companyId=${props.companyId}&fileDescription=${description}&purpose=present_company`,
          formData,
          {}
        )
        .then(res => {
          setLoading(false);
          setFiles(res.data.files);
          setProfileImg(null);
          props.notification({
            type: 'success',
            title: 'Upload erfolgreich.',
            message: 'Die Datei ist ab sofort publiziert.'
          });
        });
    } else {
      props.notification({
        type: 'warning',
        title: 'Keine Datei ausgewählt',
        message: 'Sie müssen eine gültige Datei auswählen.'
      });
    }
  };

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress color="inherit" size={24} />
        </Box>
      ) : (
        <>
          <Box m={2} pt={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box pt={0} pb={1}>
                  <Typography component="div" align="left" variant="h6">
                    Bestehende Dateien
                  </Typography>
                </Box>
              </Grid>
              <Grid container item xs={12}>
                {files.map(f => {
                  return (
                    <Grid container key={f._id} item xs={12} md={6} lg={3}>
                      <Paper className={classes.paper}>
                        <Grid item xs={12}>
                          <Box m={1}>
                            <Typography
                              align="left"
                              variant="h5"
                              component="span"
                            >
                              {f.fileName.substr(
                                f.fileName.split('_', 2).join('_').length + 1,
                                f.fileName.length
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            align="left"
                            variant="body1"
                            component="div"
                          >
                            Beschreibung: {f.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            align="left"
                            variant="body1"
                            component="div"
                          >
                            Publiziert:{' '}
                            {date.format(
                              new Date(f.publishedAt),
                              'DD.MM.YYYY, HH:mm'
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} key="32">
                          <Tooltip title={'Download'}>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                streamFile(
                                  f._id,
                                  f.fileName.substr(
                                    f.fileName.split('_', 2).join('_').length +
                                      1,
                                    f.fileName.length
                                  )
                                );
                              }}
                            >
                              <GetAppIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={'Löschen'}>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                deleteFile(f._id);
                              }}
                            >
                              <DeleteForeverIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box pt={4} pb={2}>
                  <Typography align="left" variant="h6" component="div">
                    Neue Datei hochladen
                  </Typography>
                </Box>
              </Grid>

              <form onSubmit={onSubmit}>
                <Grid item xs={12}>
                  <input
                    type="file"
                    style={{ fontSize: '11pt' }}
                    onChange={onFileChange}
                    accept="image/png,image/jpeg,application/pdf,application/mspowerpoint,application/msword,application/msexcel"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={isErrorDesc}
                    helperText={errorTextDesc}
                    label={'Beschreibung'}
                    value={description}
                    onChange={e => {
                      setIsErrorDesc(
                        !e.target.value.match(new RegExp(/^[A-Za-z0-9 .,]{3,}/))
                      );
                      setDescription(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box m={2} display="flex" flexDirection="column">
                    <IconButton color="primary" type="submit">
                      <CloudUploadIcon
                        color="primary"
                        style={{ fontSize: '3em' }}
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </form>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilesUploadComponent);
