import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import VerifiedIconComponent from './Fragments/VerifiedIconComponent';

export default function ApplicationItem(props) {
  const [customer] = useState(props.customer);

  return (
    <Grid key="1" container alignItems="center" spacing={1}>
      <Grid item key="de">
        <Typography component="span" align="left" variant="body1">
          {customer.name}
        </Typography>
      </Grid>
      <Grid item key="de2">
        <VerifiedIconComponent verified={true} />
      </Grid>
    </Grid>
  );
}
