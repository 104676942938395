import GeneralDataEditComponent from '../GeneralEditFieldComponents/GeneralDataEditComponent';
import React from 'react';
export default function CorebankingFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Kernbankensystem"
      type="select"
      choices={props.applicationsList.map(a => {
        return { label: a.name, value: a.id };
      })}
      company={props.company}
      area="bankMetadata"
      field="coresystem"
    />
  );
}
