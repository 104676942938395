import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import { Paper } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingContainer from '../../../commonComponents/LoadingContainer';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  },
  paper: {}
}));

function AttachUserToCompanyWidget(props) {
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedAdminstate, setSelectedAdminstate] = useState('');

  const [selectedCompanyForList, setSelectedCompanyForList] = useState('');
  const [selectedUserForList, setSelectedUserForList] = useState('');
  const [rolesListOfSelectedUser, setRolesListOfSelectedUser] = useState([]);
  const [usersListOfSelectedCompany, setUsersListOfSelectedCompany] = useState(
    []
  );

  const classes = useStyles();
  let isUnloaded = false;

  useEffect(() => {
    setLoading(true);

    if (props.authenticated) {
      setLoading(false);
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  useEffect(() => {
    if (isUnloaded !== true) {
      if (selectedUserForList !== '') updateRolesListForSelectedUser();
    }
    return () => {
      isUnloaded = true;
    };
  }, [selectedUserForList]);

  useEffect(() => {
    if (isUnloaded !== true) {
      if (selectedCompanyForList !== '') updateUsersListForSelectedCompany();
    }
    return () => {
      isUnloaded = true;
    };
  }, [selectedCompanyForList]);

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;

    switch (field) {
      case 'companySelect':
        setSelectedCompany(newValue);
        break;
      case 'userSelect':
        setSelectedUser(newValue);
        break;
      case 'userSelect2':
        setSelectedUserForList(newValue);
        break;
      case 'adminStateSelect':
        setSelectedAdminstate(newValue);
        break;
      case 'companySelect2':
        setSelectedCompanyForList(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const removeAssociaton = (company, user) => {
    axios
      .delete(`${props.url}/api/user/companies`, {
        data: { userId: user, companyId: company }
      })
      .then(data => {
        if (isUnloaded !== true) {
          props.refetchCallback();
          updateRolesListForSelectedUser();
          updateUsersListForSelectedCompany();
          props.notification({
            type: 'success',
            title: 'Erfolg!',
            message: `Der Verbindung wurde entfernt.`
          });
        }
      })
      .catch(e => {
        props.notification({
          type: 'error',
          title: 'Fehler!',
          message:
            'Die Verbindung konnte nicht entfernt werden, Grund: ' +
            e.response.data.detail
        });
      });
  };

  const updateRolesListForSelectedUser = () => {
    if (selectedUserForList !== '') {
      axios
        .get(`${props.url}/api/user`, {
          params: { userId: selectedUserForList }
        })
        .then(data => {
          if (isUnloaded !== true) {
            setRolesListOfSelectedUser(data.data.user.associatedCompanies);
          }
        })
        .catch(e => {
          // console.log(e);
        });
    }
  };

  const updateUsersListForSelectedCompany = () => {
    if (selectedCompanyForList !== '') {
      axios
        .get(`${props.url}/api/company`, {
          params: { companyId: selectedCompanyForList }
        })
        .then(data => {
          if (isUnloaded !== true) {
            setUsersListOfSelectedCompany(
              data.data.data.company.associatedUsers
            );
          }
        })
        .catch(e => {
          // console.log(e);
        });
    }
  };

  const clearFields = () => {
    setSelectedCompany('');
    setSelectedUser('');
    setSelectedAdminstate('');
  };

  const getCompanyNameForId = id => {
    const comp = props.companies.find(c => {
      return c.id === id;
    });

    return comp.name;
  };

  const attachUser = event => {
    if (
      selectedUser !== '' &&
      selectedCompany !== '' &&
      selectedAdminstate !== ''
    ) {
      axios
        .post(`${props.url}/api/company/attachedUsers`, {
          userId: selectedUser,
          companyId: selectedCompany,
          asAdmin: selectedAdminstate
        })
        .then(data => {
          clearFields();
          props.refetchCallback();
          setSelectedUserForList('');
          setSelectedCompanyForList('');
          setSelectedUserForList(selectedUser);
          setSelectedCompanyForList(selectedCompany);
          /*  updateRolesListForSelectedUser();
          updateUsersListForSelectedCompany();
 */
          props.notification({
            type: 'success',
            title: 'Erfolg!',
            message: `Der User wurde mit dem Unternehmen assoziiert bzw. die Rolle aktualisiert.`
          });
        })
        .catch(e => {
          props.notification({
            type: 'error',
            title: 'Fehler!',
            message:
              'Die Verbindung zwischen User und Unternehmen konnte nicht hergestellt werden, Grund: ' +
              e.response.data.detail
          });
        });
    } else {
      props.notification({
        type: 'error',
        title: 'Fehler!',
        message: 'Bitte wälen Sie alle notwendigen Daten aus.'
      });
    }
  };

  return (
    <Paper>
      <Box m={2} className={classes.adminWidgetContainer}>
        <Grid container spacing={2}>
          <AdminWidgetHeading text="Nutzer zuordnen" />
        </Grid>
        <LoadingContainer loading={loading}>
          <Paper
            component={Grid}
            container
            item
            className={classes.paper}
            xs={12}
          >
            <Grid item xs={12}>
              <Box m={2}>
                <Typography align="left" variant="h5">
                  Rollen vergeben oder ändern
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box m={2}>
                <FormControl fullWidth>
                  <InputLabel id="label_company">Unternehmen</InputLabel>
                  <Select
                    labelId="label_company"
                    id="ui_label_company"
                    fullWidth
                    name="companySelect"
                    value={selectedCompany}
                    onChange={handleChange}
                    label="Unternehmen"
                  >
                    {props.companies.map((c, i) => {
                      return (
                        <MenuItem key={c.id} value={c.id}>
                          {c.name +
                            ' (' +
                            c.companytype.charAt(0).toUpperCase() +
                            c.companytype.slice(1) +
                            ')'}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box m={2}>
                <FormControl fullWidth>
                  <InputLabel id="us1">Nutzer</InputLabel>
                  <Select
                    labelId="us1"
                    name="userSelect"
                    value={selectedUser}
                    id="3bdh"
                    onChange={handleChange}
                    label="Nutzer"
                  >
                    {props.users.map((c, i) => {
                      return (
                        <MenuItem key={c._id} value={c._id}>
                          {c.firstName +
                            ' ' +
                            c.lastName +
                            ' (' +
                            c.email +
                            ')'}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box m={2}>
                <FormControl fullWidth>
                  <InputLabel id="s1">Nutzerrolle</InputLabel>
                  <Select
                    labelId="s1"
                    fullWidth
                    name="adminStateSelect"
                    value={selectedAdminstate}
                    id="3bdfdh"
                    onChange={handleChange}
                    label="Nutzerrolle"
                  >
                    <MenuItem key={1} value={true}>
                      Admin
                    </MenuItem>
                    <MenuItem key={2} value={false}>
                      Normaler User
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} align={'left'}>
              <Box mb={2} ml={2}>
                <Button variant="outlined" onClick={attachUser}>
                  Ausführen
                </Button>
              </Box>
            </Grid>
          </Paper>

          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper>
                <Box m={2} p={2}>
                  <Box mt={2} mb={2}>
                    <Typography align="left" variant="h5">
                      Rollen entfernen (User)
                    </Typography>
                  </Box>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="label_nutzer_s1">Nutzer</InputLabel>
                      <Select
                        labelId="label_nutzers_s1"
                        fullWidth
                        name="userSelect2"
                        value={selectedUserForList}
                        id="3bdh"
                        onChange={handleChange}
                        label="Nutzer"
                      >
                        {props.users.map((c, i) => {
                          return (
                            <MenuItem key={c._id} value={c._id}>
                              {c.firstName +
                                ' ' +
                                c.lastName +
                                ' (' +
                                c.email +
                                ')'}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <List dense={true}>
                      {rolesListOfSelectedUser.map(r => {
                        return (
                          <ListItem
                            key={r.company + r.isAdmin}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  removeAssociaton(
                                    r.company,
                                    selectedUserForList
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={getCompanyNameForId(r.company)}
                              secondary={
                                'Admin: ' + (r.isAdmin === true ? 'ja' : 'nein')
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper>
                <Box m={2} p={2}>
                  <Box mt={2} mb={2}>
                    <Typography align="left" variant="h5">
                      Rollen entfernen (Unternehmen)
                    </Typography>
                  </Box>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="label_company_s1">Unternehmen</InputLabel>
                      <Select
                        labelId="label_company_s1"
                        fullWidth
                        name="companySelect2"
                        value={selectedCompanyForList}
                        id="3bdh"
                        onChange={handleChange}
                        label="Unternehmen"
                      >
                        {props.companies.map((c, i) => {
                          return (
                            <MenuItem key={c.id} value={c.id}>
                              {c.name + ' ' + ' (' + c.companytype + ')'}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <List dense={true}>
                      {usersListOfSelectedCompany.map(u => {
                        const datarow = u.associatedCompanies
                          ? u.associatedCompanies.find(ac => {
                              return ac.company === selectedCompanyForList;
                            })
                          : null;
                        const isAdmin =
                          datarow !== undefined
                            ? datarow.isAdmin === true
                              ? 'ja'
                              : 'nein'
                            : null;
                        return (
                          <ListItem
                            key={u._id}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  removeAssociaton(
                                    selectedCompanyForList,
                                    u._id
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <FolderIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                u.firstName +
                                ' ' +
                                u.lastName +
                                '(' +
                                u.email +
                                ')'
                              }
                              secondary={'Admin: ' + isAdmin}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </LoadingContainer>
      </Box>
    </Paper>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachUserToCompanyWidget);
