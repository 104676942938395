import React, { useState, useEffect } from 'react';
import styles from './deepdive.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import MetadataItem from './MetadataItem';
import LocationItem from './LocationItem';
import ApplicationItem from './ApplicationItem';
import Paper from '@material-ui/core/Paper';
import NoDataBoxComponent from './Fragments/NoDataBoxComponent';
import NoDataIconComponent from './Fragments/NoDataIconComponent';
import CustomerItem from './CustomerItem';
function SoftwarevendorDeepdive(props) {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({
    generalMetadata: {},
    bankMetadata: {}
  });
  // eslint-disable-next-line no-unused-vars
  const [companyMetadata, setCompanyMetadata] = useState({
    prohibitedFields: [],
    prohibitedFieldsCount: []
  });

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    let isUnloaded = false;

    if (props.authenticated) {
      setLoading(true);
      axios
        .get(`${props.url}/api/company?companyId=${props.company.id}`)
        .then(response => {
          if (!isUnloaded) {
            setCompany(response.data.data.company);
            setCompanyMetadata(response.data.data.metadata);
            setLoading(false);
          }
        });
    }

    return () => {
      isUnloaded = true;
    };
  }, [props]);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" component="span" variant="h4">
                  {company.name} (Softwarehersteller)
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" component="span" variant="h5">
                  Generelle Informationen
                </Typography>
              </Box>
            </Grid>
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'employeecount'}
              clearname="Anzahl Mitarbeiter"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'ftecount'}
              clearname="Anzahl Vollzeitstellen"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'foundation'}
              clearname="Gründungsjahr"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'hrentry'}
              clearname="HR-Eintrag"
              contenttype="link"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'languages'}
              clearname="Sprachen"
              contenttype="array"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'legaform'}
              clearname="Rechtsform"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'website'}
              clearname="Website"
              contenttype="link"
            />

            {/*  <MetadataItem company={company} area={'providerMetadata'} field={'bankingLicense'} clearname='Banklizenz' contenttype='boolean' />
            <MetadataItem company={company} area={'providerMetadata'} field={'coresystem'} clearname='Kernbankensystem' contenttype='text' />
            <MetadataItem company={company} area={'providerMetadata'} field={'customerCountCH'} clearname='Anzahl Kunden Schweiz' contenttype='text' />
            <MetadataItem company={company} area={'providerMetadata'} field={'customerCountNonCH'} clearname='Anzahl Kunden Non-CH' contenttype='text' />
            <MetadataItem company={company} area={'providerMetadata'} field={'sourcingSince'} clearname='Sourcinganbieter seit' contenttype='text' /> */}
          </Grid>

          <Grid container spacing={2} key="3">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" component="span" variant="h5">
                  Standorte
                </Typography>
              </Box>
            </Grid>
            {company.locations !== undefined &&
            company.locations !== null &&
            company.locations.length !== 0 ? (
              company.locations.map(l => {
                return <LocationItem key={l.location._id} location={l} />;
              })
            ) : (
              <NoDataBoxComponent />
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Kunden
                </Typography>
              </Box>
            </Grid>

            <MetadataItem
              company={company}
              area={'softwarevendorMetadata'}
              field={'customerCountCH'}
              clearname="Anzahl Kunden Schweiz"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'softwarevendorMetadata'}
              field={'customerCountNonCH'}
              clearname="Anzahl Kunden Nicht-Schweiz"
              contenttype="text"
            />

            <Grid item xs={12} md={6} lg={3}>
              <Paper className={classes.paper}>
                <Grid key="1" container alignItems="center" spacing={1}>
                  <Grid item key="de">
                    <Typography component="span" align="left" variant="h6">
                      Kundenliste
                    </Typography>
                  </Grid>
                </Grid>
                <Grid key="2" container alignItems="center" spacing={1}>
                  {company.customers !== undefined &&
                  company.customers !== null &&
                  company.customers.length !== 0 ? (
                    company.customers.map(customer => {
                      return (
                        <CustomerItem
                          key={customer}
                          customer={{ name: customer }}
                        />
                      );
                    })
                  ) : (
                    <NoDataIconComponent />
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Applikations-Portfolio
                </Typography>
              </Box>
            </Grid>
            {company.applicationPortfolio !== undefined &&
            company.applicationPortfolio !== null &&
            company.applicationPortfolio.length !== 0 ? (
              company.applicationPortfolio.map(app => {
                return <ApplicationItem key={app._id} application={app} />;
              })
            ) : (
              <NoDataBoxComponent />
            )}
          </Grid>

          {/*  {console.log(company)} */}
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoftwarevendorDeepdive);
