import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import LoadingContainer from '../../../commonComponents/LoadingContainer';

import Registration from '../../../modelComponents/user/Registration/Registration';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function CreateUserWidget(props) {
  const [loading, setLoading] = useState(false);
  const [companyname, setCompanyname] = useState('');

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;
    if (props.authenticated) {
      setLoading(false);
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  return (
    <>
      <Paper>
        <Box m={2} className={classes.adminWidgetContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminWidgetHeading text="Nutzer erstellen" />
            </Grid>
            <LoadingContainer loading={loading}>
              <Registration refetchCallback={props.refetchCallback} />
            </LoadingContainer>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserWidget);
