import React, { Component } from 'react';
import styles from './activationHash.module.scss';
import { connect } from 'react-redux';
import { setUser } from '../../../../redux/actions/connectionActions';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

class Activation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hash: this.props.match.params.hash,
      loading: true
    };
  }

  activate = async () => {
    const { hash } = this.state;
    const userdata = {
      hash
    };
    this.setState({
      loading: true
    });
    const response = await axios.post(
      `${this.props.url}/api/auth/activation`,
      userdata
    );
    this.setState({
      loading: false
    });
    if (response.data.success === true) {
      this.props.notification({
        type: 'success',
        title: 'Konto aktiviert!',
        message: 'Sie können sich jetzt einloggen!'
      });
      this.props.setUser(response.data.user);
      this.props.history.push('/registration/finish');
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Aktivierung fehlgeschlagen!',
        message: 'Haben Sie Ihr Konto bereits aktiviert?'
      });
      this.props.history.push('/');
    }
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.activate();
    }, 1);
  };

  render = () => {
    const { loading } = this.state;

    return (
      <div className={styles.activationHash}>
        {loading ? (
          <div>
            <h2>Activating account...</h2>
            <CircularProgress className={styles.loader} />
          </div>
        ) : null}
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    user: state.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
