import GeneralDataEditComponent from '../GeneralEditFieldComponents/GeneralDataEditComponent';
import React from 'react';
export default function BanktypeFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Banktyp"
      type="select"
      choices={[
        { label: '-', value: '-' },
        { label: 'Kantonalbank', value: 'Kantonalbank' },
        { label: 'Grossbank', value: 'Grossbank' },
        {
          label: 'Regionalbank/Sparkassen',
          value: 'Regionalbank/Sparkassen'
        },
        { label: 'Raiffeisenbank', value: 'Raiffeisenbank' },
        { label: 'Börsenbank', value: 'Börsenbank' },
        { label: 'Andere Bank', value: 'Andere Bank' },
        { label: 'Privatbankier', value: 'Privatbankier' },
        {
          label: 'Ausländisch beherrschte Bank',
          value: 'Ausländisch beherrschte Bank'
        },
        {
          label: 'Filialen ausländischer Bank',
          value: 'Filialen ausländischer Bank'
        }
      ]}
      company={props.company}
      area="bankMetadata"
      field="banktype"
    />
  );
}
