import React, { useState, useEffect } from 'react';
import styles from './companyAdministration.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileUpload from '../../../commonComponents/FileUpload/FileUpload';
import axios from 'axios';
import LocationEditComponent from './EditComponents/LocationEditComponent';
import BankinglicenseFieldComponentProvider from './EditComponents/FieldComponents/ProviderSpecificEditFieldComponents/BankinglicenseFieldComponent';
import SourcingSinceFieldComponent from './EditComponents/FieldComponents/ProviderSpecificEditFieldComponents/SourcingSinceFieldComponent';
import LanguageFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/LanguageFieldComponent';
import CorebankingFieldComponentProvider from './EditComponents/FieldComponents/ProviderSpecificEditFieldComponents/CorebankingFieldComponent';
import WebsiteUrlFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/WebsiteUrlFieldComponent';
import JurisdictionalFormFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/JurisdictionalFormFieldComponent';
import EmployeeCountFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/EmployeeCountFieldComponent';
import FteCountFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/FteCountFieldComponent';
import FoundationYearFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/FoundationYearFieldComponent';
import HeaderText from '../../../commonComponents/HeaderText';
import { Card } from '@material-ui/core';
import CustomerCountCHFieldComponent from './EditComponents/FieldComponents/ProviderSpecificEditFieldComponents/CustomerCountCHFieldComponent';
import CustomerCountNonCHFieldComponent from './EditComponents/FieldComponents/ProviderSpecificEditFieldComponents/CustomerCountNonCHFieldComponent';
import ServicePortfolioEditComponent from './EditComponents/FieldComponents/ProviderSpecificEditFieldComponents/ServicePortfolioEditComponent';

function BankDeepdive(props) {
  const [loading, setLoading] = useState(true);
  const [company] = useState(props.company);
  const [companiesList, setCompaniesList] = useState([]);
  const [applicationsList, setApplicationsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;

    if (props.authenticated) {
      axios
        .all([
          axios.get(`${props.url}/api/company/list`),
          axios.get(`${props.url}/api/application/list`)
        ])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            if (!isUnloaded) {
              setCompaniesList(firstResponse.data.companies);
              setApplicationsList(secondResponse.data.applications);
              setLoading(false);
            }
          })
        );
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <div>
          <Box mt={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={3} mt={1}>
                  <HeaderText text={`${company.name} (Provider)`} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid container spacing={3} key="2">
                  <Grid item xs={12}>
                    <Typography component="div" align="left" variant="h5">
                      Generelle Informationen
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <EmployeeCountFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FteCountFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FoundationYearFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <JurisdictionalFormFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <WebsiteUrlFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LanguageFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <BankinglicenseFieldComponentProvider company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CorebankingFieldComponentProvider
                      company={company}
                      applicationsList={applicationsList}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SourcingSinceFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomerCountCHFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomerCountNonCHFieldComponent company={company} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>

          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid container spacing={2} key="3">
                  <Grid item xs={12}>
                    <Typography component="div" align="left" variant="h5">
                      Standorte
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <LocationEditComponent company={company} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid item xs={12}>
                  <Typography component="div" align="left" variant="h5">
                    Vertrieb
                  </Typography>
                </Grid>
                <Grid container spacing={3} key="23">
                  <Grid item xs={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="sales_marketing"
                      clearname="Marketing"
                      area="Kundenbeziehungen"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid item xs={12}>
                  <Typography component="div" align="left" variant="h5">
                    Abwicklung
                  </Typography>
                </Grid>
                <Grid container spacing={3} key="23">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_zv_correspondent"
                      clearname="Korrespondenzbanken"
                      area="Zahlungsverkehr"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="24">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_zv_processing"
                      clearname="Zahlungsverkehrs - Abwicklung"
                      area="Zahlungsverkehr"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="25">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_ws_brokerage"
                      clearname="Brokerage"
                      area="Anlegen"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="26">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_ws_custody"
                      clearname="Custody"
                      area="Anlegen"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="27">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_ws_processing"
                      clearname="Wertschriften - Abwicklung"
                      area="Anlegen"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="28">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_ws_research"
                      clearname="Research"
                      area="Anlegen"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="29">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_ws_valordata"
                      clearname="Valorendaten"
                      area="Anlegen"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="30">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_kr_corporatefinancing"
                      clearname="Unternehmenskredit - Abwicklung"
                      area="Finanzieren"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="31">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="banking_kr_mortgageprocessing"
                      clearname="Finanzieren - Hypothekenabwicklung"
                      area="Finanzieren"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid item xs={12}>
                  <Typography component="div" align="left" variant="h5">
                    Support Bankfach
                  </Typography>
                </Grid>
                <Grid container spacing={3} key="23">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_depotmanagement"
                      clearname="Depotfürung"
                      area="Anlegen"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid item xs={12}>
                  <Typography component="div" align="left" variant="h5">
                    Support Nicht-Bankfach
                  </Typography>
                </Grid>
                <Grid container spacing={3} key="23">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_documentmgmt"
                      clearname="Dokumentenmanagement"
                      area="Übergreifend"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="24">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_compliance"
                      clearname="Compliance"
                      area="Übergreifend"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="25">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_hr"
                      clearname="HR"
                      area="Übergreifend"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="26">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_inputscanning"
                      clearname="Input / Scanning"
                      area="Übergreifend"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="27">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_outputprint"
                      clearname="Output / Print"
                      area="Übergreifend"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="28">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_rewe"
                      clearname="Rechnungswesen"
                      area="Übergreifend"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="29">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="support_kd_management"
                      clearname="Kundendaten-Management"
                      area="Übergreifend"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid item xs={12}>
                  <Typography component="div" align="left" variant="h5">
                    IT
                  </Typography>
                </Grid>
                <Grid container spacing={3} key="23">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="it_application_management"
                      clearname="Applikations-Management"
                      area="IT"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="24">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="it_application_operation"
                      clearname="Applikationsbetrieb"
                      area="IT"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="25">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="it_netzwerktel"
                      clearname="Netzwerk/Telefonie"
                      area="IT"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="26">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="it_rechenzentrum"
                      clearname="Rechenzentrum"
                      area="IT"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} key="27">
                  <Grid item xs={12} md={12} lg={12}>
                    <ServicePortfolioEditComponent
                      company={company}
                      businessFunction="it_workplace"
                      clearname="Workplace"
                      area="IT"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          {/* 
        
            sales_marketing            
            banking_zv_correspondent
            banking_zv_processing
            banking_ws_brokerage
            banking_ws_custody
            banking_ws_processing
            banking_ws_research
            banking_ws_valordata
            banking_kr_corporatefinancing
            banking_kr_mortgageprocessing
            support_depotmanagement
            support_documentmgmt
            support_compliance
            support_hr
            support_inputscanning
            support_outputprint
            support_rewe
            support_kd_management
            it_application_management
            it_application_operation
            it_netzwerktel
            it_rechenzentrum
            it_workplace
          */}
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDeepdive);
