import React from 'react';
import styles from './navigation.module.scss';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import LaunchIcon from '@material-ui/icons/Launch';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { shadows } from '@material-ui/system';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    margin: 5,
    height: '98%',
    boxShadow: '0 8px 10px -8px rgba(0,0,0,0.3)'
  },
  icon: {
    '& svg': {
      fontSize: 50
    },

    backgroundColor: '#f5f7fa',
    '&:hover': {
      backgroundColor: 'lightgray'
    }
  },
  iconInDev: {
    '& svg': {
      fontSize: 50
    },

    backgroundColor: '#f5f7fa',
    '&:hover': {
      backgroundColor: 'lightgray'
    }
  },
  devicon: {
    '& svg': {
      color: '#f0f3f4'
    }
  },
  actions: {},
  devtext: {
    color: '#a3a9ab'
  },
  titledev: {
    color: '#a3a9ab'
  },
  titleauth: {
    color: '#000000'
  },
  titlenoauth: {
    color: '#a3a9ab'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: '#115293'
  },
  avatarInDev: {
    backgroundColor: 'lightgray'
  }
}));

const PermissionedTile = ({
  isInDev,
  authenticated,
  permissions,
  requiredPermission,
  needsAuth,
  text,
  to,
  icon,
  description,
  availableFrom,
  ...rest
}) => {
  const classes = useStyles();

  return isInDev ? (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarInDev}>
            {text.charAt(0)}
          </Avatar>
        }
        title={
          <Typography
            variant="body1"
            component="span"
            className={classes.titledev}
          >
            {text}
          </Typography>
        }
      />
      <Grid container justifyContent="center">
        <IconButton className={classes.iconInDev} aria-label="open" href={'#'}>
          {icon}
        </IconButton>
      </Grid>
      <CardContent>
        <Typography variant="body1" color="textSecondary" component="span">
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        {/*  <IconButton className={classes.devicon} aria-label="open">
          <LaunchIcon />
        </IconButton> */}
        <Typography
          variant="subtitle2"
          component="span"
          className={classes.devtext}
        >
          Geplanter Go-Live: {availableFrom}
        </Typography>
      </CardActions>
    </Card>
  ) : authenticated ? (
    rest.onlyloggedout ? null : permissions.includes(requiredPermission) ||
      requiredPermission === '' ? (
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {text.charAt(0)}
            </Avatar>
          }
          title={
            <Typography
              variant="body1"
              component="span"
              className={classes.titleauth}
            >
              {text}
            </Typography>
          }
        />

        <Grid container justifyContent="center">
          <IconButton className={classes.icon} aria-label="open" href={to}>
            {icon}
          </IconButton>
        </Grid>
        <CardContent>
          <Typography variant="body1" color="textSecondary" component="span">
            {description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {/*  <IconButton aria-label="open" href={to}>
            <LaunchIcon />
          </IconButton> */}
          {/* <Typography variant="subtitle2" component="span">Öffnen</Typography> */}
        </CardActions>
      </Card>
    ) : requiredPermission === 'permission-view-indivioadmin-page' ? (
      <></> // indivio admins only
    ) : (
      <>
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {text.charAt(0)}
              </Avatar>
            }
            title={
              <Typography
                variant="body1"
                component="span"
                className={classes.titlenoauth}
              >
                {text}
              </Typography>
            }
          />
          <Grid container justifyContent="center">
            <IconButton className={classes.icon} aria-label="open">
              {icon}
            </IconButton>
          </Grid>
          <CardContent>
            <Typography variant="body1" component="span" color="textSecondary">
              {description}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions} disableSpacing>
            <IconButton className={classes.devicon} aria-label="open">
              <LaunchIcon />
            </IconButton>
            <Typography variant="subtitle2" component="span">
              Für diese Funktion ist ein höheres Abomodell erforderlich.
            </Typography>
          </CardActions>
        </Card>
      </>
    )
  ) : needsAuth ? null : (
    <>tbd, user is logged out but may see it anyways</>
  );
};

const mapStateToProps = state => {
  return {
    authenticated: state.authenticated,
    permissions: state.permissions
  };
};

export default connect(mapStateToProps)(PermissionedTile);
