import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

function GeneralDataEditComponent(props) {
  const itemName = props.itemName;
  const company = props.company;
  const validationRegex = props.validationRegex;
  const validRangeErrorMessage = props.validRangeErrorMessage;

  const initialValue =
    company[props.area] !== undefined &&
    company[props.area][props.field] !== undefined
      ? company[props.area][props.field]
      : '';
  const [value] = React.useState(initialValue);
  const [tempVal, setTempVal] = React.useState(initialValue);
  const [oldValue, setOldValue] = React.useState(initialValue);
  const [errorText, setErrorText] = React.useState(null);
  const [isError, setIsError] = React.useState(false);

  const updateValue = value => {
    axios.defaults.withCredentials = true;

    if (props.authenticated) {
      axios
        .put(`${props.url}/api/company/singleField`, {
          area: props.area,
          field: props.field,
          value: value,
          companyId: company._id
        })
        .then(response => {
          if (
            response !== undefined &&
            props.area !== null &&
            props.area !== undefined &&
            response.data.company !== null &&
            response.data.company !== undefined
          ) {
            if (props.area === null) {
              setTempVal(response.data.company[props.field]);
              setOldValue(response.data.company[props.field]);
            } else {
              setTempVal(response.data.company[props.area][props.field]);
              setOldValue(response.data.company[props.area][props.field]);
            }
            props.notification({
              type: 'success',
              title: 'Gespeichert.',
              message: 'Die Daten wurden erfolgreich gespeichert.'
            });
          } else {
            setTempVal(oldValue);
            props.notification({
              type: 'danger',
              title: 'Ungültige Eingabe.',
              message: 'Das Feld wurde auf den Ausgangswert zurückgesetzt.'
            });
          }
        });
    }
  };

  const handleChange = event => {
    if (tempVal !== oldValue && isError === false) {
      updateValue(event.target.value);
    }
  };

  const validate = event => {
    if (event.target.value.match(validationRegex)) {
      setErrorText(null);
      setIsError(false);
    } else {
      setErrorText(validRangeErrorMessage);
      setIsError(true);
    }
  };

  const handleBoolChange = event => {
    updateValue(event.target.checked);
    setTempVal(event.target.checked);
  };

  const useStyles = makeStyles(theme => ({}));

  const classes = useStyles();

  return (
    <Grid container spacing={3} key="2">
      <Grid item xs={12}>
        {props.type === 'link' ? (
          <TextField
            fullWidth
            label={itemName}
            type="text"
            value={value}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly: false
            }}
            variant="outlined"
            onBlur={handleChange}
          />
        ) : props.type === 'select' ? (
          <TextField
            fullWidth
            select
            label={itemName}
            value={tempVal}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly: false
            }}
            onChange={e => {
              setTempVal(e.target.value);
            }}
            onBlur={handleChange}
          >
            {props.choices.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : props.type === 'bool' ? (
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Switch
                  checked={tempVal}
                  onChange={e => {
                    handleBoolChange(e);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Banklizenz"
            />
          </FormControl>
        ) : props.type === 'multi' ? (
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="demo-mutiple-name-label">{itemName}</InputLabel>
            <Select
              id="1"
              multiple
              value={tempVal}
              onChange={e => {
                setTempVal(e.target.value);
              }}
              onBlur={handleChange}
            >
              {props.choices.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Halten Sie die Strg-Taste gedrückt und wählen alle zutreffenden
              Sprachen an/ab.
            </FormHelperText>
          </FormControl>
        ) : props.type === 'text' ? (
          <TextField
            fullWidth
            error={isError}
            helperText={errorText}
            id="6365"
            label={itemName}
            type="text"
            value={tempVal}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly: false
            }}
            variant="outlined"
            onChange={e => {
              validate(e);
              setTempVal(e.target.value);
            }}
            onBlur={handleChange}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralDataEditComponent);
