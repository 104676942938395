import GeneralDataEditComponent from '../GeneralEditFieldComponents/GeneralDataEditComponent';
import React from 'react';
export default function CustomerCountCHFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Anzahl Kunden in der Schweiz"
      type="text"
      company={props.company}
      area="providerMetadata"
      field="customerCountCH"
      validationRegex={new RegExp(/^\d+$/)}
      validRangeErrorMessage="Bitte nur Ganzzahlen eingeben."
    />
  );
}
