import React, { useState, useEffect } from 'react';
import styles from './chartExplorer.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import BankingmapChart from './Chart';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({}));

function ChartExplorer(props) {
  const [chartsList, setChartsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeChartId, setActiveChartId] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    let isUnloaded = false;
    axios.defaults.withCredentials = true;
    /* axios.interceptors.response.use(undefined, error => {
      if (error.response.status === 401) {
        props.notification({
          type: "danger",
          title: "Fehler.",
          message: "Fehler beim Datenabruf."
        });
        return Promise.reject(error);
      }
    }); */

    if (props.authenticated) {
      setLoading(true);
      axios.get(`${props.url}/api/chart/all`).then(response => {
        if (isUnloaded === false) {
          setChartsList(response.data.charts);
          setActiveChartId('607e85739b7fd50e29a85a83');
          setLoading(false);
        }
      });
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  const openChart = (row, e) => {
    setActiveChartId(row.id);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'chartType',
      headerName: 'Typ',
      width: 70,
      renderCell: params => (
        <>
          <Tooltip title={params.row.description}>
            {params.value === 'google_stacked_bar_chart' ? (
              <EqualizerIcon color="primary" />
            ) : params.value === 'google_donut_chart' ? (
              <DonutSmallIcon color="secondary" />
            ) : (
              <ShowChartIcon color="secondary" />
            )}
          </Tooltip>
        </>
      )
    },
    { field: 'categoryOuter', headerName: 'Kategorie', width: 150 },
    { field: 'categoryInner', headerName: 'Bereich', width: 150 },
    {
      field: 'title',
      headerName: 'Titel',
      width: 300,
      renderCell: params => (
        <Tooltip title={params.row.title}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      )
    },
    {
      field: 'userHasPermission',
      headerName: ' ',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 60,
      renderCell: params =>
        params.value === true ? (
          <Tooltip title="Zugriff erlaubt">
            <CheckCircleIcon color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title="Zugriff nicht gestattet. Sie benötigen ein höheres Abomodell.">
            <NotInterestedIcon color="error" />
          </Tooltip>
        )
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 60,

      renderCell: params => (
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            disabled={params.row.userHasPermission === false}
            aria-label="open_chart"
            component="span"
            onClick={() => {
              openChart(params.row);
            }}
          >
            <PlayCircleFilled />
          </IconButton>
        </label>
      )
    }
  ];

  const rows = chartsList.map(c => {
    return {
      chartType: c.chartType,
      id: c.id,
      categoryOuter: c.categoryOuter,
      categoryInner: c.categoryInner,
      title: c.title,
      description: c.description,
      userHasPermission: c.userHasPermission
    };
  });

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <Grid container bgcolor="background.paper">
          <Grid
            item
            xs={12}
            md={6}
            style={{ marginTop: '5px', minHeight: '90vh' }}
          >
            <DataGrid
              rows={rows}
              style={{ fontSize: 14 }}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
              onRowClick={params => {
                return params.row.userHasPermission === true
                  ? openChart(params.row)
                  : null;
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ marginTop: '5px' }}>
            <Box border={1} borderRadius={3}>
              <BankingmapChart chartId={activeChartId} />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartExplorer);
