import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import LocationItem from '../../../../pageComponents/Deepdive/LocationItem';
import NoDataBoxComponent from '../../../../pageComponents/Deepdive/Fragments/NoDataBoxComponent';
import Grid from '@material-ui/core/Grid';
import AddLocationPopoverDialogue from './AddLocationPopoverDialogue';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { IconButton } from '@material-ui/core';

function LocationEditComponent(props) {
  const [company, setCompany] = useState(props.company);

  const removeLocationById = locationId => {
    if (props.authenticated) {
      axios
        .delete(`${props.url}/api/company/locations`, {
          data: {
            companyId: company._id,
            locationId: locationId
          }
        })
        .then(response => {
          if (response.data.company !== undefined) {
            setCompany(response.data.company);
            props.notification({
              type: 'success',
              title: 'Gelöscht.',
              message: 'Der Standort wurde erfolgreich gelöscht.'
            });
          }
        })
        .catch(error => {
          if (error.response) {
            const code = error.response.data.detail.specificCode;
            let title = 'Fehler';
            let message = '';

            switch (code) {
              case 'ERR_AT_LEAST_ONE_HEADOFFICE':
                message = 'Es wird mindestens 1 Hauptsitz benötigt.';
                break;
              case 'ERR_AT_LEAST_ONE_LOCATION':
                message = 'Es wird mindestens 1 Standort benötigt.';
                break;
              case 'ERR_LOCATION_NOT_FOUND':
                message = 'Standort nicht gefunden.';
                break;
              default:
                message = 'Unbekannter Fehler.';
                break;
            }

            props.notification({
              type: 'error',
              title: title,
              message: message
            });
          }
        });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} key="32">
        <AddLocationPopoverDialogue
          callback={company => setCompany(company)}
          company={company}
          key="dialogue"
        />
      </Grid>

      {company.locations !== undefined &&
      company.locations !== null &&
      company.locations.length !== 0 ? (
        company.locations.map(l => {
          return (
            <Grid
              item
              container
              xs={12}
              md={6}
              lg={3}
              key={l.location._id}
              alignItems="center"
            >
              <Grid item xs={12} key="32">
                <LocationItem key={l.location._id} location={l} />
                <IconButton
                  color="primary"
                  onClick={() => removeLocationById(l.location._id)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <NoDataBoxComponent />
      )}
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationEditComponent);
