import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import React from 'react';
import ApartmentIcon from '@material-ui/icons/Apartment';

export default function IsMainLocationComponent({ isMain }) {
  const theme = useTheme();
  return (
    <Box mr={1}>
      {isMain === true ? (
        <Tooltip title="Hauptsitz" key="tt4">
          <Grid container alignItems="center" spacing={1} key="2ret">
            <Grid item key="21">
              <ApartmentIcon style={{ color: theme.palette.info.light }} />
            </Grid>
          </Grid>
        </Tooltip>
      ) : (
        <Tooltip title="Nebensitz" key="tt3">
          <Grid container alignItems="center" spacing={1} key="2ret">
            <Grid item key="21">
              <ApartmentIcon />
            </Grid>
          </Grid>
        </Tooltip>
      )}
    </Box>
  );
}
