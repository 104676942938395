import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  },
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

function AddApplicationPopoverDialogue(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [bank] = useState(props.bank);
  const [businessFunction] = useState(props.businessFunction);

  const [name, setName] = useState(null);
  const [since, setSince] = useState(null);
  const [remark, setRemark] = useState(null);

  const addProposedApplication = event => {
    if (props.authenticated) {
      axios
        .post(`${props.url}/api/company/businessFunctions/applications`, {
          entryId: null,
          bankId: bank._id,
          businessFunctionName: businessFunction,
          applicationId: null,
          proposedApplication: name,
          since: since,
          remark: remark
        })
        .then(response => {
          if (response.data.company !== undefined)
            props.callback(response.data.company);
          props.notification({
            type: 'success',
            title: 'Applikation angefügt.',
            message:
              'Die Daten wurden erfolgreich gespeichert und sind nun im Review.'
          });
        });
    }

    setAnchorEl(null);
  };

  const openPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container spacing={2} key="3">
      <Grid item xs={1} key="32">
        <IconButton color="primary" onClick={openPopover}>
          <AddCircleIcon />
        </IconButton>
      </Grid>
      <Grid container item xs={3}>
        <Grid item xs={1}>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            PaperProps={{
              style: { width: '30%' }
            }}
          >
            <Box m={2} pt={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography align="left" variant="h4" component="span">
                    Neue Applikation
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.typography} component="span">
                    Bitte geben Sie die Daten ein. Sie können frei schreiben,
                    wir werden die korrekte Zuordnung sicherstellen.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="new_app_name"
                  label="Applikation"
                  defaultValue=""
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  id="new_app_since"
                  label="seit"
                  defaultValue=""
                  onChange={e => {
                    setSince(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="new_app_remark"
                  label="veröffentlichte Bemerkung"
                  defaultValue=""
                  onChange={e => {
                    setRemark(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box m={2} pt={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={addProposedApplication}
                  >
                    Erstellen
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddApplicationPopoverDialogue);
