import GeneralDataEditComponent from '../GeneralEditFieldComponents/GeneralDataEditComponent';
import React from 'react';
export default function CommunityFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Verbund"
      type="select"
      choices={[
        { label: '-', value: '-' },
        { label: 'keiner', value: 'keiner' },
        { label: 'Säntis', value: 'Säntis' },
        { label: 'Clientis', value: 'Clientis' },
        { label: 'Esprit', value: 'Esprit' }
      ]}
      company={props.company}
      area="bankMetadata"
      field="community"
    />
  );
}
