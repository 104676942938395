import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import { Paper } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LoadingContainer from '../../../commonComponents/LoadingContainer';
/* import ListItemText from '@mui/material/ListItemText';
import List from '@material-ui/core/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import { ListItem } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel'; */

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  },
  select: { fontSize: 11 }
}));

function UpdateCompanyDataWidget(props) {
  const [companies, setCompanies] = useState(props.companies);

  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');

  //Basisdaten für alle Company-Typen
  const [name, setName] = useState('');
  const [visible, setVisible] = useState('');
  const [subscriptionLevel, setSubscriptionLevel] = useState('');
  const [companytype, setCompanytype] = useState('');

  // ab hier generalMetadata für alle Company-Typen
  const [employeecount, setEmployeecount] = useState('');
  const [foundation, setFoundation] = useState('');
  const [ftecount, setFtecount] = useState('');
  const [hrentry, setHrentry] = useState('');
  const [languages, setLanguages] = useState([]);
  const [legalform, setLegalform] = useState('');
  const [website, setWebsite] = useState('');

  // ab hier bankMetadata (nur für Banken)
  const [bank_bankingLicense, setBank_bankingLicense] = useState('');
  const [bank_community, setBank_community] = useState('');
  const [bank_banktype, setBank_banktype] = useState('');
  const [bank_coresystem, setBank_coresystem] = useState('');

  //ab hier providerMetadata (nur für Provider)
  const [provider_servicePortfolio, setProvider_servicePortfolio] = useState(
    ''
  );
  const [provider_bankingLicense, setProvider_bankingLicense] = useState('');
  const [provider_coresystem, setProvider_coresystem] = useState('');
  const [provider_customerCountCH, setProvider_customerCountCH] = useState('');
  const [
    provider_customerCountNonCH,
    setProvider_customerCountNonCH
  ] = useState('');
  const [provider_sourcingSince, setProvider_sourcingSince] = useState('');

  //ab hier softwarevendorMetadata (nur für SW-Vendor)
  const [swvendor_customerCountCH, setSwvendor_customerCountCH] = useState('');
  const [
    swvendor_customerCountNonCH,
    setSwvendor_customerCountNonCH
  ] = useState('');

  useEffect(() => {
    let isUnloaded = false;
    setCompanies(props.companies);
    return () => {
      isUnloaded = true;
    };
  }, [props.companies]);

  const classes = useStyles();
  let isUnloaded = false;

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;

    switch (field) {
      case 'selectedCompany':
        setSelectedCompany(newValue);
        getCompanyData(newValue);
        break;
      case 'name':
        updateBasicData(field, null, newValue);
        break;
      case 'visible':
        updateBasicData(field, null, newValue);
        break;
      case 'subscriptionLevel':
        updateBasicData(field, null, newValue);
        break;
      case 'companytype':
        //nicht änderbar
        break;
      case 'employeecount':
        updateBasicData(field, 'generalMetadata', newValue);
        break;
      case 'foundation':
        updateBasicData(field, 'generalMetadata', newValue);
        break;
      case 'ftecount':
        updateBasicData(field, 'generalMetadata', newValue);
        break;
      case 'hrentry':
        updateBasicData(field, 'generalMetadata', newValue);
        break;
      case 'languages':
        updateBasicData(field, 'generalMetadata', newValue.split(','));
        break;
      case 'legalform':
        updateBasicData(field, 'generalMetadata', newValue);
        break;
      case 'website':
        updateBasicData(field, 'generalMetadata', newValue);
        break;
      case 'bank_bankingLicense':
        updateBasicData('bankingLicense', 'bankMetadata', newValue);
        break;
      case 'bank_community':
        updateBasicData('community', 'bankMetadata', newValue);
        break;
      case 'bank_banktype':
        updateBasicData('banktype', 'bankMetadata', newValue);
        break;
      case 'bank_coresystem':
        updateBasicData('coresystem', 'bankMetadata', newValue);
        break;
      case 'provider_servicePortfolio':
        //tbd
        break;
      case 'provider_bankingLicense':
        updateBasicData('bankingLicense', 'providerMetadata', newValue);
        break;
      case 'provider_coresystem':
        updateBasicData('coresystem', 'providerMetadata', newValue);
        break;
      case 'provider_customerCountCH':
        updateBasicData('customerCountCH', 'providerMetadata', newValue);
        break;
      case 'provider_customerCountNonCH':
        updateBasicData('customerCountNonCH', 'providerMetadata', newValue);
        break;
      case 'provider_sourcingSince':
        updateBasicData('sourcingSince', 'providerMetadata', newValue);
        break;
      case 'swvendor_customerCountCH':
        updateBasicData('customerCountCH', 'softwarevendorMetadata', newValue);
        break;
      case 'swvendor_customerCountNonCH':
        updateBasicData(
          'customerCountNonCH',
          'softwarevendorMetadata',
          newValue
        );
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const updateBasicData = (fieldName, fieldCategory, fieldValue) => {
    if (props.authenticated && fieldName !== '' && selectedCompany !== '') {
      const companyId = selectedCompany;
      /* console.log(
        companyId + '' + fieldCategory + ' ' + fieldName + ' ' + fieldValue
      ); */

      axios
        .put(`${props.url}/api/company/singleField`, {
          companyId: companyId,
          area: fieldCategory,
          field: fieldName,
          value: fieldValue
        })
        .then(data => {
          if (isUnloaded !== true) {
            //console.log(data);
            props.refetchCallback();
            //callback(fieldValue);
            props.notification({
              type: 'success',
              title: 'Erfolg!',
              message: `Die Daten wurden angepasst.`
            });
          }
        })
        .catch(e => {
          if (isUnloaded !== true) {
            getCompanyData(companyId);
            props.notification({
              type: 'error',
              title: 'Fehler!',
              message: 'Die Daten konnten nicht angepasst werden, Grund: ' + e
            });
          }
        });
    }
  };

  const getCompanyData = cid => {
    if (props.authenticated) {
      setLoading(true);
      axios
        .get(`${props.url}/api/company?companyId=${cid}`)
        .then(data => {
          if (isUnloaded === false) {
            const c = data.data.data.company;
            //console.log(c);
            const companyType =
              c.companytype !== undefined ? c.companytype : '';
            c.name !== undefined ? setName(c.name) : setName('');
            c.visible !== undefined ? setVisible(c.visible) : setVisible('');
            c.subscriptionLevel !== undefined
              ? setSubscriptionLevel(c.subscriptionLevel)
              : setSubscriptionLevel('');
            c.companytype !== undefined
              ? setCompanytype(c.companytype)
              : setCompanytype('');

            if (c.generalMetadata) {
              c.generalMetadata.employeecount !== undefined
                ? setEmployeecount(c.generalMetadata.employeecount)
                : setEmployeecount('');
              c.generalMetadata.foundation !== undefined
                ? setFoundation(c.generalMetadata.foundation)
                : setFoundation('');
              c.generalMetadata.ftecount !== undefined
                ? setFtecount(c.generalMetadata.ftecount)
                : setFtecount('');
              c.generalMetadata.hrentry !== undefined
                ? setHrentry(c.generalMetadata.hrentry)
                : setHrentry('');
              c.generalMetadata.languages !== undefined
                ? setLanguages(c.generalMetadata.languages)
                : setLanguages([]);
              c.generalMetadata.legalform !== undefined
                ? setLegalform(c.generalMetadata.legalform)
                : setLegalform('');
              c.generalMetadata.website !== undefined
                ? setWebsite(c.generalMetadata.website)
                : setWebsite('');
            } else {
              setEmployeecount('');
              setFoundation('');
              setFtecount('');
              setHrentry('');
              setLanguages([]);
              setLegalform('');
              setWebsite('');
            }

            if (companyType === 'bank' && c.bankMetadata) {
              c.bankMetadata.bankingLicense !== undefined
                ? setBank_bankingLicense(c.bankMetadata.bankingLicense)
                : setBank_bankingLicense('');
              c.bankMetadata.community !== undefined
                ? setBank_community(c.bankMetadata.community)
                : setBank_community('');
              c.bankMetadata.banktype !== undefined
                ? setBank_banktype(c.bankMetadata.banktype)
                : setBank_banktype('');
              c.bankMetadata.coresystem !== undefined
                ? setBank_coresystem(c.bankMetadata.coresystem)
                : setBank_coresystem('');
            } else {
              setBank_bankingLicense('');
              setBank_community('');
              setBank_banktype('');
              setBank_coresystem('');
            }

            if (companyType === 'provider') {
              c.servicePortfolio !== undefined
                ? setProvider_servicePortfolio(c.servicePortfolio)
                : setProvider_servicePortfolio('');
            } else {
              setProvider_servicePortfolio('');
            }

            if (companyType === 'provider' && c.providerMetadata) {
              c.providerMetadata.bankingLicense !== undefined
                ? setProvider_bankingLicense(c.providerMetadata.bankingLicense)
                : setProvider_bankingLicense('');
              c.providerMetadata.coresystem !== undefined
                ? setProvider_coresystem(c.providerMetadata.coresystem)
                : setProvider_coresystem('');
              c.providerMetadata.customerCountCH !== undefined
                ? setProvider_customerCountCH(
                    c.providerMetadata.customerCountCH
                  )
                : setProvider_customerCountCH('');
              c.providerMetadata.customerCountNonCH !== undefined
                ? setProvider_customerCountNonCH(
                    c.providerMetadata.customerCountNonCH
                  )
                : setProvider_customerCountNonCH('');
              c.providerMetadata.sourcingSince !== undefined
                ? setProvider_sourcingSince(c.providerMetadata.sourcingSince)
                : setProvider_sourcingSince('');
            } else {
              setProvider_bankingLicense('');
              setProvider_coresystem('');
              setProvider_customerCountCH('');
              setProvider_customerCountNonCH('');
              setProvider_sourcingSince('');
            }

            if (companyType === 'softwarevendor' && c.softwarevendorMetadata) {
              c.softwarevendorMetadata.customerCountCH !== undefined
                ? setSwvendor_customerCountCH(
                    c.softwarevendorMetadata.customerCountCH
                  )
                : setSwvendor_customerCountCH('');
              c.softwarevendorMetadata.customerCountNonCH !== undefined
                ? setSwvendor_customerCountNonCH(
                    c.softwarevendorMetadata.customerCountNonCH
                  )
                : setSwvendor_customerCountNonCH('');
            } else {
              setSwvendor_customerCountCH('');
              setSwvendor_customerCountNonCH('');
            }

            // console.log(data);
            setLoading(false);
          }
        })
        .catch(e => {
          if (isUnloaded === false) {
            props.notification({
              type: 'error',
              title: 'Fehler!',
              message: 'Daten konnten nicht abgerufen werden, Grund: ' + e
            });
          }
        });
    }
  };

  const clearFields = () => {};

  return (
    <>
      <Paper>
        <Box m={2} className={classes.adminWidgetContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminWidgetHeading text="Unternehmensdaten aktualisieren" />
            </Grid>
            <LoadingContainer loading={loading}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="label_selectedCompany">
                    Unternehmen
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="label_selectedCompany"
                    name="selectedCompany"
                    value={selectedCompany}
                    id="3bh"
                    onChange={handleChange}
                    label="Unternehmen"
                  >
                    {companies.map((c, i) => {
                      return (
                        <MenuItem key={c.id} value={c.id}>
                          {c.name + ' (' + c.companytype + ')'}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {selectedCompany !== '' ? (
                <>
                  <Grid item xs={12}>
                    <Box mt={2} mb={2}>
                      <Typography align="left" variant="h5">
                        Basisdaten (alle Unternehmenstypen)
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      required
                      error={name.match(new RegExp(/^.*.{3,}$/)) ? false : true}
                      label="Name des Unternehmens"
                      type="text"
                      name="name"
                      value={name}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={handleChange}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="visible">Sichtbarkeit</InputLabel>
                      <Select
                        labelId="visible"
                        name="visible"
                        value={visible}
                        id="ui_visible"
                        label="Sichtabrkeit"
                        onChange={e => {
                          handleChange(e);
                          setVisible(e.target.value);
                        }}
                      >
                        <MenuItem key={'1'} value={true}>
                          ja
                        </MenuItem>
                        <MenuItem key={'2'} value={false}>
                          nein
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="label_subscriptionLevel">
                        Subscription
                      </InputLabel>
                      <Select
                        labelId="label_subscriptionLevel"
                        name="subscriptionLevel"
                        value={subscriptionLevel}
                        id="ui_subscriptionLevel"
                        label="Subscription"
                        onChange={e => {
                          handleChange(e);
                          setSubscriptionLevel(e.target.value);
                        }}
                      >
                        <MenuItem key={'1'} value={'subscription_basic'}>
                          Basic
                        </MenuItem>
                        <MenuItem key={'2'} value={'subscription_analytic'}>
                          Analytic
                        </MenuItem>
                        <MenuItem key={'3'} value={'subscription_full'}>
                          Full
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      disabled
                      label="Unternehmenstyp"
                      type="text"
                      name="companytype"
                      value={companytype}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                    />
                    {/* <FormControl fullWidth>
                  <InputLabel id="label_companytype">
                    Unternehmenstyp
                  </InputLabel>
                  <Select
                    labelId="label_companytype"
                    disabled
                    name="companytype"
                    value={companytype}
                    id="ui_companytype"
                    onChange={handleChange}
                  >
                    <MenuItem key={'1'} value={'bank'}>
                      Bank
                    </MenuItem>
                    <MenuItem key={'2'} value={'provider'}>
                      Provider
                    </MenuItem>
                    <MenuItem key={'3'} value={'softwarevendor'}>
                      Softwarehersteller
                    </MenuItem>
                  </Select>
                </FormControl> */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      error={name.match(new RegExp(/.*/)) ? false : true}
                      label="Anzahl Mitarbeiter"
                      type="number"
                      name="employeecount"
                      value={employeecount}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={handleChange}
                      onChange={e => {
                        setEmployeecount(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      error={foundation.match(new RegExp(/.*/)) ? false : true}
                      label="Gründungsjahr"
                      type="number"
                      name="foundation"
                      value={foundation}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={handleChange}
                      onChange={e => {
                        setFoundation(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      error={ftecount.match(new RegExp(/.*/)) ? false : true}
                      label="Anzahl FTE"
                      type="number"
                      name="ftecount"
                      value={ftecount}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={handleChange}
                      onChange={e => {
                        setFtecount(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      error={hrentry.match(new RegExp(/.*/)) ? false : true}
                      label="HR-Eintrag"
                      type="text"
                      name="hrentry"
                      value={hrentry}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={handleChange}
                      onChange={e => {
                        setHrentry(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Sprachen"
                      type="text"
                      name="languages"
                      value={languages.join(',')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={handleChange}
                      onChange={e => {
                        setLanguages(e.target.value.split(','));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="label_legalform">Rechtsform</InputLabel>
                      <Select
                        labelId="label_legalform"
                        name="legalform"
                        value={legalform}
                        id="ui_legalform"
                        onChange={e => {
                          setLegalform(e.target.value);
                          handleChange(e);
                        }}
                        label="Rechtsform"
                      >
                        <MenuItem key={'1'} value={'GmbH'}>
                          GmbH
                        </MenuItem>
                        <MenuItem key={'2'} value={'KG'}>
                          KG
                        </MenuItem>
                        <MenuItem key={'3'} value={'AG'}>
                          AG
                        </MenuItem>
                        <MenuItem key={'4'} value={'Genossenschaft'}>
                          Genossenschaft
                        </MenuItem>
                        <MenuItem key={'5'} value={''}>
                          -
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      error={website.match(new RegExp(/.*/)) ? false : true}
                      label="Website"
                      type="text"
                      name="website"
                      value={website}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={handleChange}
                      onChange={e => {
                        setWebsite(e.target.value);
                      }}
                    />
                  </Grid>

                  {companytype === 'bank' ? (
                    <>
                      <Grid item xs={12}>
                        <Box mt={2} mb={2}>
                          <Typography align="left" variant="h5">
                            Basisdaten (nur Banken)
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                          <InputLabel id="label_bank_bankingLicense">
                            Banklizenz{' '}
                          </InputLabel>
                          <Select
                            labelId="label_bank_bankingLicense"
                            name="bank_bankingLicense"
                            value={bank_bankingLicense}
                            id="ui_bank_bankingLicense"
                            label="Banklizenz"
                            onChange={e => {
                              handleChange(e);
                              setBank_bankingLicense(e.target.value);
                            }}
                          >
                            <MenuItem key={'1'} value={true}>
                              ja
                            </MenuItem>
                            <MenuItem key={'2'} value={false}>
                              nein
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                          <InputLabel id="label_bank_community">
                            Community
                          </InputLabel>
                          <Select
                            labelId="label_bank_community"
                            name="bank_community"
                            value={bank_community}
                            id="ui_bank_community"
                            label="Community"
                            onChange={e => {
                              handleChange(e);
                              setBank_community(e.target.value);
                            }}
                          >
                            <MenuItem key={'1'} value={'keine'}>
                              keine
                            </MenuItem>
                            <MenuItem key={'2'} value={'Säntis'}>
                              Säntis
                            </MenuItem>
                            <MenuItem key={'3'} value={'Clientis'}>
                              Clientis
                            </MenuItem>
                            <MenuItem key={'4'} value={'Esprit'}>
                              Esprit
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                          <InputLabel id="label_bank_banktype">
                            Banktyp
                          </InputLabel>
                          <Select
                            labelId="label_bank_banktype"
                            name="bank_banktype"
                            value={bank_banktype}
                            id="ui_bank_banktype"
                            label="Banktyp"
                            onChange={e => {
                              handleChange(e);
                              setBank_banktype(e.target.value);
                            }}
                          >
                            <MenuItem key={'1'} value={'Kantonalbank'}>
                              Kantonalbank
                            </MenuItem>
                            <MenuItem key={'2'} value={'Grossbank'}>
                              Grossbank
                            </MenuItem>
                            <MenuItem
                              key={'3'}
                              value={'Regionalbank/Sparkassen'}
                            >
                              Regionalbank/Sparkassen
                            </MenuItem>
                            <MenuItem key={'4'} value={'Raiffeisenbank'}>
                              Raiffeisenbank
                            </MenuItem>
                            <MenuItem key={'5'} value={'Börsenbank'}>
                              Börsenbank
                            </MenuItem>
                            <MenuItem key={'6'} value={'Andere Bank'}>
                              Andere Bank
                            </MenuItem>
                            <MenuItem key={'7'} value={'Privatbankier'}>
                              Privatbankier
                            </MenuItem>
                            <MenuItem
                              key={'8'}
                              value={'Ausländisch beherrschte Bank'}
                            >
                              Ausländisch beherrschte Bank
                            </MenuItem>
                            <MenuItem
                              key={'9'}
                              value={'Filialen ausländischer Bank'}
                            >
                              Filialen ausländischer Bank
                            </MenuItem>
                            <MenuItem key={'10'} value={'Wertpapierhaus'}>
                              Wertpapierhaus
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                          <InputLabel id="label_bank_coresystem">
                            Kernbankensystem
                          </InputLabel>
                          <Select
                            labelId="label_bank_coresystem"
                            fullWidth
                            name="bank_coresystem"
                            value={bank_coresystem}
                            id="ui_bank_coresystem"
                            label="Kernbankensystem"
                            onChange={e => {
                              handleChange(e);
                              setBank_coresystem(e.target.value);
                            }}
                          >
                            {props.apps.map((a, i) => {
                              return (
                                <MenuItem key={a.id} value={a.id}>
                                  {a.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}
                  {companytype === 'provider' ? (
                    <>
                      <Grid item xs={12}>
                        <Box mt={2} mb={2}>
                          <Typography align="left" variant="h5">
                            Basisdaten (nur Provider)
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        tbd serviceportfolio{provider_servicePortfolio}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="label_provider_bankingLicense">
                            Banklizenz
                          </InputLabel>
                          <Select
                            labelId="label_provider_bankingLicense"
                            name="provider_bankingLicense"
                            value={provider_bankingLicense}
                            id="ui_provider_bankingLicense"
                            label="Banklizenz"
                            onChange={e => {
                              handleChange(e);
                              setProvider_bankingLicense(e.target.value);
                            }}
                          >
                            <MenuItem key={'1'} value={true}>
                              ja
                            </MenuItem>
                            <MenuItem key={'2'} value={false}>
                              nein
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="label_provider_coresystem">
                            Kernbankensystem
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="label_provider_coresystem"
                            name="provider_coresystem"
                            value={provider_coresystem}
                            id="ui_provider_coresystem"
                            label="Kernbankensystem"
                            onChange={e => {
                              handleChange(e);
                              setProvider_coresystem(e.target.value);
                            }}
                          >
                            {props.apps.map((a, i) => {
                              return (
                                <MenuItem key={a.id} value={a.id}>
                                  {a.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Anzahl Kunden Schweiz"
                          type="number"
                          name="provider_customerCountCH"
                          value={provider_customerCountCH}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          onBlur={handleChange}
                          onChange={e => {
                            setProvider_customerCountCH(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Anzahl Kunden Nicht-Schweiz"
                          type="number"
                          name="provider_customerCountNonCH"
                          value={provider_customerCountNonCH}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          onBlur={handleChange}
                          onChange={e => {
                            setProvider_customerCountNonCH(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          error={
                            provider_sourcingSince.match(new RegExp(/.*/))
                              ? false
                              : true
                          }
                          label="Sourcing seit"
                          type="text"
                          name="provider_sourcingSince"
                          value={provider_sourcingSince}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          onBlur={handleChange}
                          onChange={e => {
                            setProvider_sourcingSince(e.target.value);
                          }}
                        />
                      </Grid>
                    </>
                  ) : null}
                  {companytype === 'softwarevendor' ? (
                    <>
                      <Grid item xs={12}>
                        <Box mt={2} mb={2}>
                          <Typography align="left" variant="h5">
                            Basisdaten (nur Softwarehersteller)
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Anzahl Kunden Schweiz"
                          type="number"
                          name="swvendor_customerCountCH"
                          value={swvendor_customerCountCH}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          onBlur={handleChange}
                          onChange={e => {
                            setSwvendor_customerCountCH(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Anzahl Kunden Nicht-Schweiz"
                          type="number"
                          name="swvendor_customerCountNonCH"
                          value={swvendor_customerCountNonCH}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          onBlur={handleChange}
                          onChange={e => {
                            setSwvendor_customerCountNonCH(e.target.value);
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : null}
              <Box m={1} />
            </LoadingContainer>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCompanyDataWidget);
