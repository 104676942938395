import GeneralDataEditComponent from '../GeneralEditFieldComponents/GeneralDataEditComponent';
import React from 'react';
export default function SourcingSinceFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Gründungsjahr"
      type="text"
      company={props.company}
      area="providerMetadata"
      field="sourcingSince"
      //eslint-disable-next-line
      validationRegex={new RegExp(/^(19|20)\d{2}$/)}
      validRangeErrorMessage="Bitte geben Sie eine vierstellige Jahreszahl ein, z.B. 2021"
    />
  );
}
