import React, { Component } from 'react';
import styles from './analytics.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Typography, CircularProgress } from '@material-ui/core';

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  render = () => {
    const { loading } = this.state;
    return (
      <div className={styles.tbd}>
        {loading ? (
          <Typography variant="h5" component="span" gutterBottom>
            Analytics TBD
            <CircularProgress
              className={styles.circle}
              color="inherit"
              size={24}
            />
          </Typography>
        ) : null}
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
