import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
//import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
//import { useTheme } from '@material-ui/core/styles';
import VerifiedIconComponent from './Fragments/VerifiedIconComponent';
import NoDataIconComponent from './Fragments/NoDataIconComponent';

export default function MetadataItem(props) {
  const [clearname] = useState(props.clearname);
  const [company] = useState(props.company);
  const [area] = useState(props.area);
  const [field] = useState(props.field);
  const [contenttype] = useState(props.contenttype);
  const [isSet, setIsSet] = useState(true);
  const [finalComponent, setFinalComponent] = useState(null);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      minHeight: '100%'
    }
  }));

  useEffect(() => {
    const val =
      company === undefined ||
      company[area] === undefined ||
      company[area][field] === undefined ||
      company[area][field] === null ||
      company[area][field].length === 0
        ? undefined
        : company[area][field];

    if (contenttype === 'link')
      setFinalComponent(
        val === undefined ? (
          <NoDataIconComponent />
        ) : (
          <a href={val} rel="noreferrer" target="_blank">
            {val}
          </a>
        )
      );
    else if (contenttype === 'array')
      setFinalComponent(
        val === undefined || val.length === 0 || val === null ? (
          <NoDataIconComponent />
        ) : (
          val.join(', ')
        )
      );
    else if (contenttype === 'boolean')
      setFinalComponent(
        val === undefined ? (
          <NoDataIconComponent />
        ) : val === true || val === 'true' ? (
          <CheckCircleIcon color="primary" />
        ) : val === false || val === 'false' ? (
          <CancelIcon color="primary" />
        ) : (
          <NoDataIconComponent />
        )
      );
    else setFinalComponent(val);
    setIsSet(val !== undefined);
  }, []);

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Paper className={classes.paper}>
        <Box key={1}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography align="left" component="span" variant="h6">
                {clearname}
              </Typography>
            </Grid>
            <Grid>
              <VerifiedIconComponent verified={isSet} />
            </Grid>
          </Grid>
        </Box>
        <Box key={234} margin={1}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {isSet === false ? (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item key="1">
                    <NoDataIconComponent />
                  </Grid>
                  <Grid item key="2">
                    <Typography align="left" component="span" variant="body1">
                      Für dieses Feld liegen uns noch keine Daten vor.
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                finalComponent
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
}
