import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import React from 'react';
import NoDataIconComponent from './NoDataIconComponent';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export default function RemarkComponent({ remark }) {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary
    }
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Paper className={classes.paper}>
        <Box margin={1} border={0} borderRadius={5} key={'k'}>
          <Grid container alignItems="center" spacing={1} key="2ret">
            <Grid item key="21">
              <NoDataIconComponent />
            </Grid>
            <Grid item key="22">
              <Typography align="left" component="span" variant="body1">
                Für dieses Feld liegen uns noch keine Daten vor.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
}
