import { TimeFromNow } from '../../../../../helper/timeFromNow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
//import { useTheme } from "@material-ui/core/styles";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import BusinessIcon from '@material-ui/icons/Business';
import DeliveredByComponent from '../../../../pageComponents/Deepdive/Fragments/DeliveredByComponent';
import LastUpdatedComponent from '../../../../pageComponents/Deepdive/Fragments/LastUpdatedComponent';
import RemarkComponent from '../../../../pageComponents/Deepdive/Fragments/RemarkComponent';
import SinceComponent from '../../../../pageComponents/Deepdive/Fragments/SinceComponent';
import { useTheme } from '@material-ui/core/styles';
import AddProviderPopoverDialogue from './AddProviderPopoverDialogue';
import AddApplicationPopoverDialogue from './AddApplicationPopoverDialogue';
import Badge from '@material-ui/core/Badge';
import axios from 'axios';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function BusinessFunctionEditComponent(props) {
  const [company, setCompany] = useState(props.company);
  const [businessFunction] = useState(props.businessFunction);
  const [clearname] = useState(props.clearname);
  const [area] = useState(props.area);
  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    paper: {
      flexGrow: 1,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      textAlign: 'center'
    }
  }));

  const classes = useStyles();

  const removeSourcingEntryByEntryId = entryId => {
    if (props.authenticated) {
      axios
        .delete(
          `${props.url}/api/company/businessFunctions/sourcingProviders`,
          {
            data: {
              entryId: entryId,
              companyId: company._id,
              sourcingProviderId: null,
              businessFunctionName: businessFunction
            }
          }
        )
        .then(response => {
          if (response.data.company !== undefined)
            setCompany(response.data.company);
          throwRemoveNotification();
        });
    }
  };

  const removeSourcingEntryByProviderId = providerId => {
    if (props.authenticated) {
      axios
        .delete(
          `${props.url}/api/company/businessFunctions/sourcingProviders`,
          {
            data: {
              entryId: null,
              companyId: company._id,
              sourcingProviderId: providerId,
              businessFunctionName: businessFunction
            }
          }
        )
        .then(response => {
          if (response.data.company !== undefined)
            setCompany(response.data.company);
          throwRemoveNotification();
        });
    }
  };

  const removeAppEntryByEntryId = entryId => {
    if (props.authenticated) {
      axios
        .delete(`${props.url}/api/company/businessFunctions/applications`, {
          data: {
            entryId: entryId,
            companyId: company._id,
            applicationId: null,
            businessFunctionName: businessFunction
          }
        })
        .then(response => {
          if (response.data.company !== undefined)
            setCompany(response.data.company);
          throwRemoveNotification();
        });
    }
  };

  const removeAppEntryByAppId = applicationId => {
    if (props.authenticated) {
      axios
        .delete(`${props.url}/api/company/businessFunctions/applications`, {
          data: {
            entryId: null,
            companyId: company._id,
            applicationId: applicationId,
            businessFunctionName: businessFunction
          }
        })
        .then(response => {
          if (response.data.company !== undefined)
            setCompany(response.data.company);
          throwRemoveNotification();
        });
    }
  };

  const throwRemoveNotification = () => {
    props.notification({
      type: 'success',
      title: 'Gelöscht.',
      message: 'Die Verbindung wurde erfolgreich gelöscht.'
    });
  };

  return (
    <Grid container item xs={12} md={12} lg={12}>
      <Box p={1} width={1}>
        <Paper className={classes.paper}>
          <Grid item xs={12} key="1">
            <Typography
              align="left"
              variant="h6"
              key="functionArea"
              component="div"
            >
              {area} -{' '}
            </Typography>
            <Typography
              align="left"
              variant="h6"
              key="functionName"
              component="div"
            >
              {clearname}
            </Typography>
          </Grid>

          <Grid container item xs={9} key="2">
            <Grid item xs={12} key="1">
              <Typography align="left" variant="body1" component="div">
                Sourcingprovider
              </Typography>
            </Grid>
            <Grid item xs={12} key="2">
              <AddProviderPopoverDialogue
                callback={bank => setCompany(bank)}
                bank={company}
                businessFunction={businessFunction}
              />
            </Grid>
          </Grid>

          {company.businessFunctions !== undefined &&
          company.businessFunctions !== null &&
          company.businessFunctions !== [] &&
          company.businessFunctions[businessFunction] !== undefined &&
          company.businessFunctions[businessFunction] !== null &&
          company.businessFunctions[businessFunction] !== [] ? ( //&&
            //company.businessFunctions[businessFunction].sourcingProviders
            //.length !== 0
            company.businessFunctions[businessFunction].sourcingProviders.map(
              obj => {
                return obj.state === 'clean' ? (
                  <Box
                    margin={1}
                    border={1}
                    borderColor="grey.100"
                    borderRadius={5}
                    key={obj._id}
                    style={{ boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)' }}
                  >
                    <Box p={1} key={obj._id}>
                      <Grid container align="left" item xs={12} key="2">
                        <Grid container item key="1" alignItems="center">
                          <Grid container align="left" item xs={9} key="2">
                            <Grid item xs={2} key="1">
                              <BusinessIcon />
                            </Grid>
                            <Grid item xs={9} key="2">
                              <Typography
                                align="left"
                                variant="h6"
                                component="span"
                              >
                                {
                                  props.allCompanies.find(c => {
                                    return c.id === obj.provider;
                                  }).name
                                }
                              </Typography>
                            </Grid>
                            <Grid xs={1} item key="3">
                              <Badge
                                badgeContent={'Livewert'}
                                overlap="rectangular"
                                color={'primary'}
                              ></Badge>
                            </Grid>
                          </Grid>

                          <Grid item xs={3} align="right" key="4">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                removeSourcingEntryByProviderId(obj.provider)
                              }
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid container item key="2" alignItems="center">
                          <Grid item key="1">
                            <SinceComponent since={obj.since} />
                          </Grid>
                          <Grid item key="2">
                            <LastUpdatedComponent
                              lastUpdated={TimeFromNow(
                                obj.metadata.liveValueSetOn
                              )}
                            />
                          </Grid>
                          <Grid item key="3">
                            <DeliveredByComponent
                              by={
                                obj.metadata.liveValueDeliveredBy === 'indivio'
                                  ? 'Community'
                                  : 'Unternehmen'
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container item key="3" alignItems="center">
                          <Grid item xs={12} key="1">
                            <RemarkComponent remark={obj.remark} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    margin={1}
                    border={1}
                    borderColor="grey.100"
                    borderRadius={5}
                    key={obj._id}
                    style={{ boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)' }}
                  >
                    <Box p={1} key={obj._id}>
                      <Grid container item xs={12} key="2">
                        <Grid container item key="1" alignItems="center">
                          <Grid container align="left" item xs={9} key="2">
                            <Grid item xs={2} key="1">
                              <BusinessIcon />
                            </Grid>
                            <Grid item xs={9} key="11">
                              <Typography
                                align="left"
                                variant="h6"
                                component="span"
                              >
                                {obj.proposedProvider}
                              </Typography>
                            </Grid>
                            <Grid item xs={1} key="12">
                              <Badge
                                badgeContent={'Review'}
                                overlap="rectangular"
                                color={'secondary'}
                              />
                            </Grid>
                          </Grid>
                          <Grid item align="right" xs={3} key="32">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                removeSourcingEntryByEntryId(obj._id)
                              }
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          spacing={1}
                          key="hiu2"
                        >
                          <Grid item key="21">
                            <SinceComponent since={obj.since} />
                          </Grid>
                          <Grid item key="23">
                            <LastUpdatedComponent
                              lastUpdated={TimeFromNow(
                                obj.metadata.proposedValueSetOn
                              )}
                            />
                          </Grid>
                          <Grid item key="25">
                            <DeliveredByComponent
                              by={
                                obj.metadata.liveValueDeliveredBy === 'indivio'
                                  ? 'Community'
                                  : 'Unternehmen'
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          alignItems="center"
                          spacing={1}
                          key="30"
                        >
                          <Grid item xs={12} key="31">
                            <RemarkComponent remark={obj.remark} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                );
              }
            )
          ) : (
            <Box
              m={1}
              p={1}
              border={1}
              borderColor="grey.100"
              borderRadius={5}
              key="kk"
              style={{ boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)' }}
            >
              <Grid
                container
                alignItems="center"
                spacing={1}
                key="noProviderData"
              >
                <Grid item key="icon">
                  <HighlightOffIcon
                    style={{ color: theme.palette.info.main }}
                  />
                </Grid>
                <Grid item key="text">
                  <Typography
                    align="left"
                    variant="body1"
                    key="1"
                    component="span"
                  >
                    Sie haben noch keine Providerdaten erfasst.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          <Typography align="left" variant="body1" component="div">
            Applikationen
            <AddApplicationPopoverDialogue
              callback={bank => setCompany(bank)}
              bank={company}
              businessFunction={businessFunction}
            />
            {/*  <AddCircleIcon style={{ color: theme.palette.success.main }} /> */}
          </Typography>
          {company.businessFunctions !== undefined &&
          company.businessFunctions !== null &&
          company.businessFunctions !== [] &&
          company.businessFunctions[businessFunction] !== undefined &&
          company.businessFunctions[businessFunction] !== null &&
          company.businessFunctions[businessFunction] !== [] ? (
            //&& company.businessFunctions[businessFunction].usedApplications.length !== 0
            company.businessFunctions[businessFunction].usedApplications.map(
              obj => {
                return obj.state === 'clean' ? (
                  <Box
                    margin={1}
                    border={1}
                    borderColor="grey.100"
                    borderRadius={5}
                    key={obj._id}
                    style={{ boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)' }}
                  >
                    <Box p={1} key={obj._id}>
                      <Grid container align="left" item xs={12} key="2">
                        <Grid container item key="1" alignItems="center">
                          <Grid container align="left" item xs={9} key="2">
                            <Grid item xs={2} key="1">
                              <DesktopMacIcon />
                            </Grid>
                            <Grid item xs={8} key="2">
                              <Typography
                                align="left"
                                variant="h6"
                                component="span"
                              >
                                {
                                  props.allApplications.find(c => {
                                    return c.id === obj.application;
                                  }).name
                                }
                              </Typography>
                            </Grid>
                            <Grid xs={2} item key="3">
                              <Badge
                                badgeContent={'Livewert'}
                                overlap="rectangular"
                                color={'primary'}
                              ></Badge>
                            </Grid>
                          </Grid>

                          <Grid item xs={3} align="right" key="4">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                removeAppEntryByAppId(obj.application)
                              }
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid container item key="2" alignItems="center">
                          <Grid item key="1">
                            <SinceComponent since={obj.since} />
                          </Grid>
                          <Grid item key="23">
                            <LastUpdatedComponent
                              lastUpdated={TimeFromNow(
                                obj.metadata.liveValueSetOn
                              )}
                            />
                          </Grid>
                          <Grid item key="25">
                            <DeliveredByComponent
                              by={
                                obj.metadata.liveValueDeliveredBy === 'indivio'
                                  ? 'Community'
                                  : 'Unternehmen'
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          spacing={1}
                          key="30"
                        >
                          <Grid item key="31">
                            <RemarkComponent remark={obj.remark} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    margin={1}
                    border={1}
                    borderColor="grey.100"
                    borderRadius={5}
                    key={obj._id}
                    style={{ boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)' }}
                  >
                    <Box p={1} key={obj._id}>
                      <Grid container align="left" item xs={12} key="2">
                        <Grid container item key="1" alignItems="center">
                          <Grid container align="left" item xs={9} key="2">
                            <Grid item xs={2} key="1">
                              <DesktopMacIcon />
                            </Grid>
                            <Grid item xs={8} key="2">
                              <Typography
                                align="left"
                                variant="h6"
                                component="span"
                              >
                                {obj.proposedApplication}
                              </Typography>
                            </Grid>
                            <Grid xs={2} item key="3">
                              <Badge
                                badgeContent={'Review'}
                                overlap="rectangular"
                                color={'secondary'}
                              ></Badge>
                            </Grid>
                          </Grid>
                          <Grid item xs={3} align="right" key="4">
                            <IconButton
                              color="primary"
                              onClick={() => removeAppEntryByEntryId(obj._id)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Grid>
                        </Grid>

                        <Grid container item key="2" alignItems="center">
                          <Grid item key="1">
                            <SinceComponent since={obj.since} />
                          </Grid>
                          <Grid item key="23">
                            <LastUpdatedComponent
                              lastUpdated={TimeFromNow(
                                obj.metadata.proposedValueSetOn
                              )}
                            />
                          </Grid>
                          <Grid item key="25">
                            <DeliveredByComponent
                              by={
                                obj.metadata.liveValueDeliveredBy === 'indivio'
                                  ? 'Community'
                                  : 'Unternehmen'
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container item key="3" alignItems="center">
                          <Grid item xs={12} key="1">
                            <RemarkComponent remark={obj.remark} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                );
              }
            )
          ) : (
            <Box
              m={1}
              p={1}
              border={1}
              borderColor="grey.100"
              borderRadius={5}
              key="mm"
              style={{ boxShadow: '0 8px 20px -8px rgba(0,0,0,0.3)' }}
            >
              <Grid container alignItems="center" spacing={1} key="noAppData">
                <Grid item key="icon">
                  <HighlightOffIcon
                    style={{ color: theme.palette.info.main }}
                  />
                </Grid>
                <Grid item key="text">
                  <Typography
                    align="left"
                    variant="body1"
                    key="1"
                    component="span"
                  >
                    Sie haben noch keine Applikationen erfasst.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessFunctionEditComponent);
