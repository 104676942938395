import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LanguageIcon from '@material-ui/icons/Language';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { ListItemButton } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px',
    width: '20vw',
    height: '78vh',
    minWidth: '450px',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      //SbackgroundColor: 'purple',
      height: '30vh',
      minWidth: '250px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.spacing(1),
      // backgroundColor: 'blue',
      height: '78vh'
    }
  },
  outer: {
    width: '100%',
    height: '100%',
    margin: '10px',
    border: `0px solid ${theme.palette.primary.main}`
  },
  inline: {
    display: 'inline'
  },
  title: { width: '10px', wordWrap: 'break-word' }
}));

function FilterResultsWidget(props) {
  const classes = useStyles();
  const [banks, setBanks] = useState([]);
  const [providers, setProviders] = useState([]);
  const [softwarevendors, setSoftwarevendors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBanks(props.places.banks);
    setProviders(props.places.providers);
    setSoftwarevendors(props.places.softwarevendors);
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 1000);
  }, [props.places]);

  return (
    <>
      <div className={classes.outer}>
        <Card className={classes.root}>
          <Typography variant="h5" component="span" gutterBottom>
            Gefilterte Unternehmen
          </Typography>
          {loading ? (
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress color="inherit" size={24} />
            </Box>
          ) : (
            <>
              <CardContent
                style={{
                  marginTop: '20px',
                  width: '100%',
                  height: '70vh',
                  overflow: 'scroll'
                }}
              >
                <div>
                  {banks.length > 0 ? (
                    <>
                      <Typography
                        color="textSecondary"
                        component="span"
                        gutterBottom
                      >
                        Banken
                      </Typography>
                      <List className={classes.list}>
                        {banks.map(company => (
                          <ListItemButton
                            onClick={() =>
                              props.onItemClick({
                                companyId: company.companyId
                              })
                            }
                            key={company.companyId}
                            alignItems="flex-start"
                          >
                            <ListItemText
                              className={classes.title}
                              primary={company.name}
                            />
                            <ListItemSecondaryAction>
                              {company.website ? (
                                <Tooltip id="" title="Website öffnen">
                                  <IconButton
                                    edge="end"
                                    aria-label="showOnMap"
                                    onClick={() =>
                                      window.open(company.website, '_blank')
                                    }
                                  >
                                    <LanguageIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              <Tooltip id="" title="Deepdive anzeigen">
                                <IconButton
                                  onClick={() =>
                                    window.open(
                                      '/deepdive?companyId=' +
                                        company.companyId,
                                      '_blank'
                                    )
                                  }
                                  edge="end"
                                  aria-label="showDeepdive"
                                >
                                  <ZoomInIcon />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        ))}
                      </List>
                    </>
                  ) : null}
                  {providers.length > 0 ? (
                    <>
                      <Typography
                        color="textSecondary"
                        component="span"
                        gutterBottom
                      >
                        Provider
                      </Typography>
                      <List className={classes.list}>
                        {providers.map(company => (
                          <ListItemButton
                            key={company.companyId}
                            onClick={() =>
                              props.onItemClick({
                                companyId: company.companyId
                              })
                            }
                            alignItems="flex-start"
                          >
                            <ListItemText primary={company.name} />
                            <ListItemSecondaryAction>
                              {company.website ? (
                                <Tooltip id="" title="Website öffnen">
                                  <IconButton
                                    edge="end"
                                    aria-label="showOnMap"
                                    onClick={() =>
                                      window.open(company.website, '_blank')
                                    }
                                  >
                                    <LanguageIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              <Tooltip id="" title="Deepdive anzeigen">
                                <IconButton
                                  onClick={() =>
                                    window.open(
                                      '/deepdive?companyId=' +
                                        company.companyId,
                                      '_blank'
                                    )
                                  }
                                  edge="end"
                                  aria-label="showDeepdive"
                                >
                                  <ZoomInIcon />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        ))}
                      </List>
                    </>
                  ) : null}
                  {softwarevendors.length > 0 ? (
                    <>
                      <Typography
                        color="textSecondary"
                        component="span"
                        gutterBottom
                      >
                        Softwarehersteller
                      </Typography>
                      <List className={classes.list}>
                        {softwarevendors.map(company => (
                          <ListItemButton
                            key={company.companyId}
                            onClick={() =>
                              props.onItemClick({
                                companyId: company.companyId
                              })
                            }
                            alignItems="flex-start"
                          >
                            <ListItemText primary={company.name} />
                            <ListItemSecondaryAction>
                              {company.website ? (
                                <Tooltip id="" title="Website öffnen">
                                  <IconButton
                                    edge="end"
                                    aria-label="showOnMap"
                                    onClick={() =>
                                      window.open(company.website, '_blank')
                                    }
                                  >
                                    <LanguageIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              <Tooltip id="" title="Deepdive anzeigen">
                                <IconButton
                                  onClick={() =>
                                    window.open(
                                      '/deepdive?companyId=' +
                                        company.companyId,
                                      '_blank'
                                    )
                                  }
                                  edge="end"
                                  aria-label="showDeepdive"
                                >
                                  <ZoomInIcon />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        ))}
                      </List>
                    </>
                  ) : null}
                </div>
              </CardContent>
            </>
          )}
        </Card>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterResultsWidget);
