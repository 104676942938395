import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts';
import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  typography: {
    alignItems: 'center'
  },
  circle: {
    marginLeft: '30px'
  }
}));

function BankingmapChart(props) {
  const classes = useStyles();
  const chartId = props.chartId;
  const [chartData, setChartData] = useState({ meta: {}, currentData: {} });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    /* axios.interceptors.response.use(undefined, (error) => {
   if (error.response.status === 401) {
    props.notification({ type: 'danger', title: 'Fehler.', message: 'Fehler beim Datenabruf.', })
    return Promise.reject(error)
   }
  }) */
    setLoading(true);
    if (props.authenticated && chartId !== undefined && chartId !== null) {
      axios.get(`${props.url}/api/chart?chartId=${chartId}`).then(response => {
        setChartData(response.data.chart);
        setLoading(false);
        //console.log(response.data.chart);
      });
    }
  }, [chartId]);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={classes.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <Box sx={{ height: '90vH' }}>
          <Box display="flex" flexDirection="row" p={1} m={0}>
            <Box p={0}>
              <Typography
                variant="h5"
                component="span"
                gutterBottom
                className={classes.typography}
              >
                {chartData.meta.title}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" p={1} m={1}>
            {chartData.meta.chartType === 'google_stacked_bar_chart' ? (
              <Chart
                width={'100%'}
                height={'70vh'}
                loader={<div>Lädt...</div>}
                chartType="ColumnChart"
                data={chartData.currentData}
                options={{
                  //is3D: true,
                  isStacked: true,
                  backgroundColor: { fill: 'transparent' },
                  legend: {
                    position: 'bottom',
                    alignment: 'center',
                    textStyle: {
                      /*  color: 'black', */
                      fontSize: 14
                    }
                  },
                  //hAxis: {title: "ss", direction: 1},
                  vAxis: { title: 'Anzahl', direction: 1 },

                  tooltip: {
                    showColorCode: true
                  },
                  chartArea: {
                    left: 100,
                    top: 40,
                    width: '100%',
                    height: '80%'
                  }
                }}
                graph_id="StackedBarChart"
                legend_toggle
              />
            ) : chartData.meta.chartType === 'google_donut_chart' ? (
              <Chart
                width={'100%'}
                height={'70vh'}
                loader={<div>Lädt...</div>}
                chartType="PieChart"
                data={chartData.currentData}
                options={{
                  is3D: true,
                  //pieHole: 0.5,
                  legend: {
                    position: 'bottom',
                    alignment: 'center',
                    textStyle: {
                      /*                   color: 'black',
                       */ fontSize: 14
                    }
                  },
                  backgroundColor: { fill: 'transparent' },
                  tooltip: {
                    showColorCode: true
                  },
                  chartArea: {
                    left: 0,
                    top: 40,
                    width: '100%',
                    height: '80%'
                  }
                }}
                graph_id="PieChart"
                legend_toggle
              />
            ) : null}
          </Box>

          <Box display="flex" flexDirection="row" p={1} m={0}>
            <Box p={0} component="div" sx={{ overflowY: 'scroll' }}>
              <Typography variant="body1" component="span" gutterBottom>
                {chartData.meta.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankingmapChart);
