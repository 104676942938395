import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles(theme => ({}));

export default function LoadingContainer(props) {
  const classes = useStyles();

  return props.loading ? (
    <Grid container item xs={12}>
      <Box m={1} style={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    </Grid>
  ) : (
    <>{props.children}</>
  );
}
