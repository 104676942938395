import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import MapContainer from './GoogleMapComponent';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return <MapContainer />;
  };
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
