import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
//import { useTheme } from '@material-ui/core/styles';
import VerifiedIconComponent from './Fragments/VerifiedIconComponent';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export default function ApplicationItem(props) {
  const [app] = useState(props.application);

  //const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary
    }
  }));

  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Paper className={classes.paper}>
        <Box key={'1'}>
          <Grid key="1" container alignItems="center" spacing={1}>
            <Grid item key="de">
              <Typography component="span" align="left" variant="h6">
                {app.name}
              </Typography>
            </Grid>
            <Grid item key="13">
              <Tooltip key="tt3" title="Deepdive öffnen">
                <Link target="_blank" to={`/deepdive?applicationId=${app._id}`}>
                  <OpenInNewIcon color="primary" />
                </Link>
              </Tooltip>
            </Grid>
            <Grid item key="de2">
              <VerifiedIconComponent verified={true} />
            </Grid>
          </Grid>
        </Box>

        <Grid item key="de">
          <Typography align="left" component="span" variant="body1">
            {app.description !== undefined ? (
              app.description
            ) : (
              <Typography component="span" align="left" variant="body1">
                Keine Beschreibung hinterlegt.
              </Typography>
            )}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
}
