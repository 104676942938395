import GeneralDataEditComponent from './GeneralDataEditComponent';
import React from 'react';
export default function FteCountFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Anzahl FTE (gerundet auf ganze Zahl)"
      type="text"
      company={props.company}
      area="generalMetadata"
      field="ftecount"
      validationRegex={new RegExp(/^\d+$/)}
      validRangeErrorMessage="Bitte nur Ganzzahlen eingeben."
    />
  );
}
