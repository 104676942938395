import React, { useState, useEffect } from 'react';
import styles from './deepdive.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import AppProviderItem from './AppProviderItem';
import MetadataItem from './MetadataItem';
import LocationItem from './LocationItem';
import NoDataBoxComponent from './Fragments/NoDataBoxComponent';

function BankDeepdive(props) {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({
    generalMetadata: {},
    bankMetadata: {},
    locations: []
  });
  const [companyMetadata, setCompanyMetadata] = useState({
    prohibitedFields: [],
    prohibitedFieldsCount: []
  });

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    let isUnloaded = false;

    axios.defaults.withCredentials = true;

    if (props.authenticated) {
      setLoading(true);
      axios
        .get(`${props.url}/api/company?companyId=${props.company.id}`)
        .then(response => {
          if (!isUnloaded) {
            setCompany(response.data.data.company);
            setCompanyMetadata(response.data.data.metadata);
            setLoading(false);
          }
        });
    }

    return () => {
      isUnloaded = true;
    };
  }, [props]);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={2} key="1">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h4" component="span">
                  {company.name} (Bank)
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            key="2"
            alignItems="stretch"
            direction="row"
          >
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Generelle Informationen
                </Typography>
              </Box>
            </Grid>
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'employeecount'}
              clearname="Anzahl Mitarbeiter"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'ftecount'}
              clearname="Anzahl Vollzeitstellen"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'foundation'}
              clearname="Gründungsjahr"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'hrentry'}
              clearname="HR-Eintrag"
              contenttype="link"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'languages'}
              clearname="Sprachen"
              contenttype="array"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'legaform'}
              clearname="Rechtsform"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'generalMetadata'}
              field={'website'}
              clearname="Website"
              contenttype="link"
            />
            <MetadataItem
              company={company}
              area={'bankMetadata'}
              field={'bankingLicense'}
              clearname="Banklizenz"
              contenttype="boolean"
            />
            <MetadataItem
              company={company}
              area={'bankMetadata'}
              field={'community'}
              clearname="Verbund"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'bankMetadata'}
              field={'banktype'}
              clearname="Banktyp"
              contenttype="text"
            />
            <MetadataItem
              company={company}
              area={'bankMetadata'}
              field={'coresystem'}
              clearname="Kernbankensystem"
              contenttype="text"
            />
          </Grid>
          <Grid container spacing={2} key="3">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Standorte
                </Typography>
              </Box>
            </Grid>
            {company.locations !== undefined &&
            company.locations !== null &&
            company.locations.length !== 0 ? (
              company.locations.map(l => {
                return <LocationItem key={l.location._id} location={l} />;
              })
            ) : (
              <NoDataBoxComponent />
            )}
          </Grid>

          <Grid container spacing={2} key="4">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Vertrieb
                </Typography>
              </Box>
            </Grid>
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="sales_crm"
              clearname="CRM"
              contenttype="text"
              area="Kundenbeziehungen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="sales_marketing"
              clearname="Marketing"
              area="Kundenbeziehungen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
          </Grid>
          <Grid container spacing={2} key="5">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Beratung
                </Typography>
              </Box>
            </Grid>
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_ws_advisory"
              clearname="Anlageberatung"
              area="Anlegen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_kr_advisory"
              clearname="Finanzierungsberatung"
              area="Finanzieren"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_vs_advisory"
              clearname="Vorsorgeberatung"
              area="Vorsorgen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
          </Grid>
          <Grid container spacing={2} key="6">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Abwicklung
                </Typography>
              </Box>
            </Grid>
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_zv_processing"
              clearname="Zahlungsverkehrs-Abwicklung"
              area="Zahlungsverkehr"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_ws_processing"
              clearname="Wertschriften-Abwicklung"
              area="Anlegen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_kr_corporatefinancing"
              clearname="Unternehmenskredit-Abwicklung"
              area="Finanzieren"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_kr_mortgageprocessing"
              clearname="Hypothekenabwicklung"
              area="Finanzieren"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
          </Grid>
          <Grid container spacing={2} key="7">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Support Bankfach
                </Typography>
              </Box>
            </Grid>
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_zv_correspondent"
              clearname="Korrespondenzbanken"
              area="Zahlungsverkehr"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_ws_research"
              clearname="Research"
              area="Anlegen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_ws_brokerage"
              clearname="Brokerage"
              area="Anlegen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_ws_custody"
              clearname="Custody"
              area="Anlegen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="banking_ws_valordata"
              clearname="Valorendaten"
              area="Anlegen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_depotmanagement"
              clearname="Depotfürung"
              area="Anlegen"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_accountmanagement"
              clearname="Kontoführung"
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_customermanagement"
              clearname="Kundenführung"
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_compliance"
              clearname="Compliance"
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
          </Grid>
          <Grid container spacing={2} key="8">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Support Nicht-Bankfach
                </Typography>
              </Box>
            </Grid>
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_archiving"
              clearname="Archiv"
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_documentmgmt"
              clearname=""
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_hr"
              clearname=""
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_inputscanning"
              clearname=""
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_kd_management"
              clearname=""
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_outputprint"
              clearname=""
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="support_rewe"
              clearname=""
              area="Übergreifend"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
          </Grid>
          <Grid container spacing={2} key="9">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  IT
                </Typography>
              </Box>
            </Grid>
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="it_application_management"
              clearname="Applikations-Management"
              area="IT"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="it_application_operation"
              clearname="Applikationsbetrieb"
              area="IT"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="it_netzwerktel"
              clearname="Netzwerk/Telefonie"
              area="IT"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="it_rechenzentrum"
              clearname="Rechenzentrum"
              area="IT"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
            <AppProviderItem
              meta={companyMetadata}
              company={company}
              businessFunction="it_workplace"
              clearname="Workplace"
              area="IT"
              allCompanies={props.allCompanies}
              allApplications={props.allApplications}
            />
          </Grid>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDeepdive);
