import React, { useState, useEffect } from 'react';
import styles from './companyAdministration.module.scss';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileUpload from '../../../commonComponents/FileUpload/FileUpload';
import axios from 'axios';
import BusinessFunctionEditComponent from './EditComponents/BusinessFunctionEditComponent';
import LocationEditComponent from './EditComponents/LocationEditComponent';
import BankinglicenseFieldComponent from './EditComponents/FieldComponents/BankSpecificEditFieldComponents/BankinglicenseFieldComponent';
import LanguageFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/LanguageFieldComponent';
import CorebankingFieldComponent from './EditComponents/FieldComponents/BankSpecificEditFieldComponents/CorebankingFieldComponent';
import BanktypeFieldComponent from './EditComponents/FieldComponents/BankSpecificEditFieldComponents/BanktypeFieldComponent';
import CommunityFieldComponent from './EditComponents/FieldComponents/BankSpecificEditFieldComponents/CommunityFieldComponent';
import WebsiteUrlFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/WebsiteUrlFieldComponent';
import JurisdictionalFormFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/JurisdictionalFormFieldComponent';
import EmployeeCountFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/EmployeeCountFieldComponent';
import FteCountFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/FteCountFieldComponent';
import FoundationYearFieldComponent from './EditComponents/FieldComponents/GeneralEditFieldComponents/FoundationYearFieldComponent';
import HeaderText from '../../../commonComponents/HeaderText';
import { Card } from '@material-ui/core';

function BankDeepdive(props) {
  const [loading, setLoading] = useState(true);
  const [company] = useState(props.company);
  const [companiesList, setCompaniesList] = useState([]);
  const [applicationsList, setApplicationsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;

    if (props.authenticated) {
      axios
        .all([
          axios.get(`${props.url}/api/company/list`),
          axios.get(`${props.url}/api/application/list`)
        ])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            if (!isUnloaded) {
              setCompaniesList(firstResponse.data.companies);
              setApplicationsList(secondResponse.data.applications);
              setLoading(false);
            }
          })
        );
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <div>
          <Box mt={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={3} mt={1}>
                  <HeaderText text={`${company.name} (Bank)`} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid container spacing={3} key="2">
                  <Grid item xs={12}>
                    <Typography component="div" align="left" variant="h5">
                      Generelle Informationen
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <EmployeeCountFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FteCountFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FoundationYearFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <JurisdictionalFormFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <WebsiteUrlFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CommunityFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <BanktypeFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CorebankingFieldComponent
                      company={company}
                      applicationsList={applicationsList}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LanguageFieldComponent company={company} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <BankinglicenseFieldComponent company={company} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid container spacing={2} key="3">
                  <Grid item xs={12}>
                    <Typography component="div" align="left" variant="h5">
                      Standorte
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <LocationEditComponent company={company} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>

          <Grid container spacing={2} key="4">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="div">
                  Vertrieb
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="sales_crm"
                clearname="CRM"
                area="Kundenbeziehungen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="sales_marketing"
                clearname="Marketing"
                area="Kundenbeziehungen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} key="5">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="div">
                  Beratung{' '}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_ws_advisory"
                clearname="Anlageberatung"
                area="Anlegen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_kr_advisory"
                clearname="Finanzierungsberatung"
                area="Finanzieren"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_vs_advisory"
                clearname="Vorsorgeberatung"
                area="Vorsorgen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} key="6">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="div">
                  Abwicklung
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_zv_processing"
                clearname="Zahlungsverkehrs - Abwicklung"
                area="Zahlungsverkehr"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_ws_processing"
                clearname="Wertschriften - Abwicklung"
                area="Anlegen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_kr_corporatefinancing"
                clearname="Unternehmenskredit - Abwicklung"
                area="Finanzieren"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_kr_mortgageprocessing"
                clearname="Hypothekenabwicklung"
                area="Finanzieren"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} key="7">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="div">
                  Support Bankfach
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_zv_correspondent"
                clearname="Korrespondenzbanken"
                area="Zahlungsverkehr"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_ws_research"
                clearname="Research"
                area="Anlegen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_ws_brokerage"
                clearname="Brokerage"
                area="Anlegen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_ws_custody"
                clearname="Custody"
                area="Anlegen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="banking_ws_valordata"
                clearname="Valorendaten"
                area="Anlegen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_depotmanagement"
                clearname="Depotfürung"
                area="Anlegen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_accountmanagement"
                clearname="Kontoführung"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_customermanagement"
                clearname="Kundenführung"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_compliance"
                clearname="Compliance"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} key="8">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="div">
                  Support Nicht-Bankfach
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_archiving"
                clearname="Archiv"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_documentmgmt"
                clearname="Dokumentenmanagement"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_hr"
                clearname="HR"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_inputscanning"
                clearname="Input / Scanning"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_kd_management"
                clearname="Kundendaten-Management"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_outputprint"
                clearname="Output / Print"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="support_rewe"
                clearname="Rechnungswesen"
                area="Übergreifend"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} key="9">
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="div">
                  IT{' '}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="it_application_management"
                clearname="Applikations-Management"
                area="Kundenbeziehungen"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="it_application_operation"
                clearname="Applikationsbetrieb"
                area="IT"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="it_netzwerktel"
                clearname="Netzwerk/Telefonie"
                area="IT"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="it_rechenzentrum"
                clearname="Rechenzentrum"
                area="IT"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <BusinessFunctionEditComponent
                company={company}
                businessFunction="it_workplace"
                clearname="Workplace"
                area="IT"
                allCompanies={companiesList}
                allApplications={applicationsList}
              />
            </Grid>
          </Grid>
          <Box m={1}>
            <Card>
              <Box p={2}>
                <Grid container spacing={2} key="10">
                  <Grid item xs={12}>
                    <Typography align="left" variant="h5" component="div">
                      Dateien
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      <Typography align="left" variant="body1" component="div">
                        Hier können Sie der Bankingmap-Community Dateien zur
                        Verfügung stellen. Dies können zum Beispiel
                        Produktpräsentationen, Unternehmensvorstellungen oder
                        Preislisten sein. Folgende Dateitypen sind zulässig:
                        JPEG, PNG, PDF, PPTX, XLSX und DOCX.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUpload companyId={company._id}></FileUpload>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDeepdive);
