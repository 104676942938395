import React, { useState, useEffect } from 'react';
import styles from './logout.module.scss';
import { connect } from 'react-redux';
import {
  setAuth,
  setUser,
  setPermissions
} from '../../../../redux/actions/connectionActions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography, CircularProgress } from '@material-ui/core';
import axios from 'axios';

function Logout(props) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const logout = async e => {
    const response = await axios.post(`${props.url}/api/auth/logout`);

    if (response.data.success) {
      props.setAuth(false);
      props.setUser(null);
      props.setPermissions([]);
      navigate('/');
    } else {
      setLoading(true);
      props.notification({
        type: 'danger',
        title: 'Logout failed!',
        message: 'Try again!'
      });
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className={styles.logout}>
      {loading ? (
        <Typography variant="h5" component="span" gutterBottom>
          Signing out
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Typography>
      ) : null}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAuth: authenticated => {
      dispatch(setAuth(authenticated));
    },
    setUser: user => {
      dispatch(setUser(user));
    },
    setPermissions: permissions => {
      dispatch(setPermissions(permissions));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
