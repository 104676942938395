import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
  welcome: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, 40%, ${theme.palette.primary.light} 60%)`,
    color: 'white',
    height: '55px',
    marginTop: '20px'
  }
}));

function AdminWidgetHeading(props) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card className={classes.welcome}>
        <CardContent>
          <Box m={0}>
            <Typography component="div" align={'left'} variant="h5">
              {props.text}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminWidgetHeading);
