import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import React from 'react';

export default function SinceComponent({ since }) {
  return (
    <Box mr={1}>
      <Tooltip title="Im Einsatz seit" key="tt5">
        <Grid container alignItems="center" spacing={1} key="gthr2">
          <Grid item key="21">
            <QueryBuilderIcon color="primary" />
          </Grid>
          <Grid item key="22">
            {since}
          </Grid>
        </Grid>
      </Tooltip>
    </Box>
  );
}
