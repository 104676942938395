import React, { useState, useEffect } from 'react';
import styles from './deepdive.module.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import AppMetadataItem from './AppMetadataItem';
import ApplicationFunctionItem from './ApplicationFunctionItem';

function ApplicationDeepdive(props) {
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState({
    generalMetadata: {},
    bankMetadata: {}
  });

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 30
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    let isUnloaded = false;

    if (props.authenticated) {
      setLoading(true);
      axios
        .get(
          `${props.url}/api/application?applicationId=${props.application.id}`
        )
        .then(response => {
          if (!isUnloaded) {
            setApplication(response.data.application);
            setLoading(false);
          }
        });
    }

    return () => {
      isUnloaded = true;
    };
  }, [props]);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            className={styles.circle}
            color="inherit"
            size={24}
          />
        </Box>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h4" component="span">
                  {application.name} (Applikation)
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={4}>
                <Typography align="left" variant="h5" component="span">
                  Generelle Informationen
                </Typography>
              </Box>
            </Grid>
            <AppMetadataItem
              app={props.application}
              field="name"
              clearname="Name"
            />
            <AppMetadataItem
              app={props.application}
              field="description"
              clearname="Beschreibung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'banking_corebanking'}
              area="System"
              clearname="Kernbankensystem"
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Vertrieb
                </Typography>
              </Box>
            </Grid>
            <ApplicationFunctionItem
              application={application}
              function={'sales_crm'}
              area="Kundenbeziehungen"
              clearname="CRM"
            />
            <ApplicationFunctionItem
              application={application}
              function={'sales_marketing'}
              area="Kundenbeziehungen"
              clearname="Marketing"
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Beratung
                </Typography>
              </Box>
            </Grid>
            <ApplicationFunctionItem
              application={application}
              function={'banking_ws_advisory'}
              area="Anlegen"
              clearname="Anlageberatung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'banking_kr_advisory'}
              area="Finanzieren"
              clearname="Finanzierungsberatung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'banking_vs_advisory'}
              area="Vorsorgen"
              clearname="Vorsorgeberatung"
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Abwicklung
                </Typography>
              </Box>
            </Grid>
            <ApplicationFunctionItem
              application={application}
              function={'banking_zv_processing'}
              area="Zahlungsverkehr"
              clearname="Zahlungsverkehrs-Abwicklung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'banking_ws_processing'}
              area="Anlegen"
              clearname="Wertschriften-Abwicklung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'banking_kr_corporatefinancing'}
              area="Finanzieren"
              clearname="Unternehmenskredit-Abwicklung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'banking_kr_mortgageprocessing'}
              area="Finanzieren"
              clearname="Hypothekenabwicklung"
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Support Bankfach
                </Typography>
              </Box>
            </Grid>
            <ApplicationFunctionItem
              application={application}
              function={'support_depotmanagement'}
              area="Anlegen"
              clearname="Depotmanagement"
            />
            <ApplicationFunctionItem
              application={application}
              function={'support_accountmanagement'}
              area="Übergreifend"
              clearname="Kontoführung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'support_compliance'}
              area="Übergreifend"
              clearname="Compliance"
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={3}>
                <Typography align="left" variant="h5" component="span">
                  Support Nicht-Bankfach
                </Typography>
              </Box>
            </Grid>
            <ApplicationFunctionItem
              application={application}
              function={'support_documentmgmt'}
              area="Übergreifend"
              clearname="Dokumentenmanagement"
            />
            <ApplicationFunctionItem
              application={application}
              function={'support_inputscanning'}
              area="Übergreifend"
              clearname="Input/Scanning"
            />
            <ApplicationFunctionItem
              application={application}
              function={'support_customermanagement'}
              area="Übergreifend"
              clearname="Kundendatenverwaltung"
            />
            <ApplicationFunctionItem
              application={application}
              function={'support_outputprint'}
              area="Übergreifend"
              clearname="Output/Druck"
            />
            <ApplicationFunctionItem
              application={application}
              function={'support_rewe'}
              area="Übergreifend"
              clearname="Rechnungswesen"
            />
            <ApplicationFunctionItem
              application={application}
              function={'support_archiving'}
              area="Übergreifend"
              clearname="Archivierung"
            />
          </Grid>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDeepdive);
