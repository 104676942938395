import GeneralDataEditComponent from './GeneralDataEditComponent';
import React from 'react';
export default function EmployeeCountFieldComponent(props) {
  return (
    <GeneralDataEditComponent
      itemName="Anzahl Mitarbeiter"
      type="text"
      company={props.company}
      area="generalMetadata"
      field="employeecount"
      validationRegex={new RegExp(/^\d+$/)}
      validRangeErrorMessage="Bitte nur Ganzzahlen eingeben."
    />
  );
}
