import React, { Component } from 'react';
import styles from './profileModify.module.scss';
import { connect } from 'react-redux';
import { setUser } from '../../../../redux/actions/connectionActions';
import {
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios';

class Profile extends Component {
  constructor(props) {
    super(props);

    let { id, username, firstName, lastName, email } = this.props.user;
    this.state = {
      id,
      username,
      firstName,
      lastName,
      email,
      loading: false
    };
  }

  saveProfile = async () => {
    const { id, username, firstName, lastName, email } = this.state;
    const userdata = {
      id,
      username,
      firstName,
      lastName,
      email
    };
    this.setState({
      loading: true
    });
    const response = await axios.post(
      `${this.props.url}/api/user/profileupdate`,
      userdata
    );
    this.setState({
      loading: false
    });
    if (response.data.success) {
      this.props.setUser(response.data.user);
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile saved!'
      });
      this.props.history.push('/profile');
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Save failed!',
        message: 'Try again later!'
      });
    }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <div className={styles.profileModify}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper>
            <Typography className={styles.title} component="span" variant="h5">
              Modify profile
            </Typography>
            <ValidatorForm
              ref={'form'}
              onSubmit={this.saveProfile}
              // onError={ errors => console.log(errors) }
              className={styles.content}
            >
              <TextValidator
                label="Username"
                onChange={this.handleChange}
                name={'username'}
                value={this.state.username || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <TextValidator
                label="First name"
                onChange={this.handleChange}
                name="firstName"
                value={this.state.firstName || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <TextValidator
                label="Last name"
                onChange={this.handleChange}
                name="lastName"
                value={this.state.lastName || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              {loading ? (
                <Button
                  className={this.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  <CircularProgress color="primary" size={24} />
                </Button>
              ) : (
                <Button
                  type="submit"
                  className={this.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              )}
            </ValidatorForm>
          </Paper>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
