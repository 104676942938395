import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import AdminWidgetHeading from '../../../commonComponents/AdminWidgetHeading';
import { Paper } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LoadingContainer from '../../../commonComponents/LoadingContainer';

const useStyles = makeStyles(theme => ({
  circle: {
    marginLeft: '30px'
  },
  adminWidgetContainer: {
    //minHeight: '95vH'
  }
}));

function CreateApplicationWidget(props) {
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState('');
  const [appName, setAppName] = useState('');
  const [description, setDescription] = useState('');

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    let isUnloaded = false;
    if (props.authenticated) {
      setLoading(false);
    }

    return () => {
      isUnloaded = true;
    };
  }, []);

  const handleChange = event => {
    const field = event.target.name;
    const newValue = event.target.value;

    switch (field) {
      case 'vendor':
        setVendor(newValue);
        break;
      case 'appname':
        setAppName(newValue);
        break;
      case 'description':
        setDescription(newValue);
        break;
      default:
        console.log('unknown field');
        break;
    }
  };

  const clearFields = () => {
    setVendor('');
    setAppName('');
    setDescription('');
  };

  const createApplication = event => {
    axios
      .post(`${props.url}/api/application`, {
        name: appName,
        vendorId: vendor,
        description: description
      })
      .then(data => {
        clearFields();
        props.refetchCallback();
        props.notification({
          type: 'success',
          title: 'Erfolg!',
          message: `Die Applikation ${data.data.application.name} wurde angelegt.`
        });
      })
      .catch(e => {
        props.notification({
          type: 'error',
          title: 'Fehler!',
          message:
            'Die Applikation konnte nicht angelegt werden, Grund: ' +
            e.response.data.detail
        });
      });
  };

  return (
    <>
      <Paper>
        <Box m={2} className={classes.adminWidgetContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminWidgetHeading text="Applikation erstellen" />
            </Grid>
            <LoadingContainer loading={loading}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  required
                  error={appName.match(new RegExp(/^.*.{3,}$/)) ? false : true}
                  label="Name der Applikation"
                  type="text"
                  name="appname"
                  value={appName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="s1">Hersteller</InputLabel>
                  <Select
                    labelId="s1"
                    fullWidth
                    name="vendor"
                    value={vendor}
                    id="3bdfdh"
                    onChange={handleChange}
                    label="Hersteller"
                  >
                    {props.companies.map(c => {
                      if (c.companytype === 'softwarevendor') {
                        return (
                          <MenuItem key={c.id} value={c.id}>
                            {c.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  required
                  error={
                    description.match(new RegExp(/^.*.{3,}$/)) ? false : true
                  }
                  label="Kurzbeschrieb"
                  type="text"
                  name="description"
                  value={description}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    readOnly: false
                  }}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} align={'left'}>
                <Button variant="outlined" onClick={createApplication}>
                  Applikation erstellen
                </Button>
              </Grid>
            </LoadingContainer>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateApplicationWidget);
